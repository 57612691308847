// src/components/recentNews.js

import React from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/swiper-bundle.min.css';


// SwiperCore.use([Autoplay]);

const RecentNews = () => {
    const slides = [
        {
            img: "https://www.asio.ae/public/uploads/158079737715552490692.jpg",
            title: "Investment",
        },
        {
            img: "https://www.asio.ae/public/uploads/15465160233.png",
            title: "Sharjah Business",
        },
        {
            img: "https://www.asio.ae/public/uploads/15807973961554905868Modern_Infrastructure.jpg",
            title: "Expand",
        },
        {
            img: "https://www.asio.ae/public/uploads/158079759315465155474.jpg",
            title: "Sharjah Business",
        },
        {
            img: "https://www.asio.ae/public/uploads/158079737715552490692.jpg",
            title: "Sharjah Business",
        },
        {
            img: "https://www.asio.ae/public/uploads/15465160233.png",
            title: "Sharjah Business",
        },
        {
            img: "https://www.asio.ae/public/uploads/158079737715552490692.jpg",
            title: "Investment",
        },
        {
            img: "https://www.asio.ae/public/uploads/15465160233.png",
            title: "Sharjah Business",
        },
    ];

    return (
        <section className="category-area3 bg-smoke space" data-bg-src="/assets/img/bg/line-pattern3.png">
            <div className="container th-container">
                <div className="title-area text-center">
                    <h2 className="sec-title">Recent News</h2>
                </div>
                <div className="slider-area">
                    <Swiper
                        slidesPerView={1} // Default to 1 slide per view
                        spaceBetween={24} // Space between slides
                        loop={true}
                        speed={1000}
                        watchSlidesProgress={true}
                        breakpoints={{
                            0: { slidesPerView: 1 }, 
                            576: { slidesPerView: 1 }, 
                            768: { slidesPerView: 2 }, 
                            992: { slidesPerView: 2 }, 
                            1200: { slidesPerView: 3 }
                        }}
                        pagination={{
                            clickable: true, 
                        }}
                        autoplay={{
                            delay: 3000, // 3-second autoplay delay
                            disableOnInteraction: false, // Do not stop autoplay on interaction
                        }}
                        modules={[Pagination, Autoplay]} 
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className="category-card single2">
                                    <div className="box-img global-img">
                                        <img src={slide.img} style={{ height: '274px' }} alt="Image" />
                                    </div>
                                    <h3 className="box-title"><a href="#">{slide.title}</a></h3>
                                    <a className="line-btn" href="#">See more</a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default RecentNews;

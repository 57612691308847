import React from "react";
import { useEffect } from 'react';
import $ from 'jquery';
import Slider from "./slider";
import RecentNews from "./recentNews";
import Testimonials from "./Testimonials";
function NewHome(){
  useEffect(() => {
    // Background image setting
    if ($("[data-bg-src]").length > 0) {
      $("[data-bg-src]").each(function () {
        const src = $(this).attr("data-bg-src");
        $(this).css("background-image", "url(" + src + ")");
        $(this).removeAttr("data-bg-src").addClass("background-image");
      });
    }

    // Scroll To Top
    if ($('.scroll-top').length > 0) {
      const scrollTopbtn = document.querySelector('.scroll-top');
      const progressPath = document.querySelector('.scroll-top path');
      const pathLength = progressPath.getTotalLength();
      
      progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
      progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

      const updateProgress = () => {
        const scroll = $(window).scrollTop();
        const height = $(document).height() - $(window).height();
        const progress = pathLength - (scroll * pathLength / height);
        progressPath.style.strokeDashoffset = progress;
      };
      
      updateProgress();
      $(window).scroll(updateProgress);

      const offset = 50;
      const duration = 750;

      $(window).on('scroll', function () {
        if ($(this).scrollTop() > offset) {
          $(scrollTopbtn).addClass('show');
        } else {
          $(scrollTopbtn).removeClass('show');
        }
      });

      $(scrollTopbtn).on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, duration);
        return false;
      });
    }
  }, []);
      return (
        <div>
          <div className="magic-cursor relative z-10">
            <div className="cursor" />
            <div className="cursor-follower" />
          </div>
          {/*==============================
       Preloader
    ==============================*/}
          <div id="preloader" className="preloader " style={{display: 'none'}}>
            <button className="th-btn preloaderCls">Cancel Preloader </button>
            <div className="preloader-inner">
              <img src="/assets/img/logo3.svg" alt="" />
            </div>
            <div id="loader" className="th-preloader">
              <div className="animation-preloader">
                <div className="txt-loading">
                  <span preloader-text="T" className="characters">T </span>
                  <span preloader-text="O" className="characters">O </span>
                  <span preloader-text="U" className="characters">U </span>
                  <span preloader-text="R" className="characters">R </span>
                  <span preloader-text="M" className="characters">M </span>
                </div>
              </div>
            </div>
          </div> {/*==============================
      Sidemenu
  ============================== */}
          
          {/*==============================
  Hero Area
  ==============================*/}
          {/*==============================
  Hero Area
  ==============================*/}
          <div className="hero-3" id="hero">
            <div className="swiper hero-slider-3" id="heroSlide3">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero-inner">
                    <video autoPlay loop muted>
                      <source src="/assets/img/hero/video3.mp4" type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="hero-style3">
                        <h1 className="hero-title" data-ani="slideinleft" data-ani-delay="0.2s">
                          A New Industrial Reality
                        </h1>
                        <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.4s">A Taking Palce in sharjah
                        </p>
                        <div className="btn-group" data-ani="slideinup" data-ani-delay="0.6s">
                          <a href="/" className="th-btn style2 th-icon">Explore Tours</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-down">
              <a href="#destination-sec" className="scroll-wrap"><span><img src="/assets/img/icon/down-arrow.svg" alt="" /></span> Scroll
                Down</a>
            </div>
          </div>
          {/*======== / Hero Section ========*/}{/*==============================
  Destination Area  
  ==============================*/}
  <Slider></Slider>
          {/* <section className="position-relative overflow-hidden space" id="destination-sec" data-bg-src="/assets/img/bg/line-pattern3.png">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <div className="title-area">
                    <span className="sub-title">Take A Look</span>
                    <h2 className="sec-title">ASIO</h2>
                  </div>
                </div>
                <div className="col-lg-5">
                  <h2 className="destination-title" style={{display: 'none'}}><span className="counter-number">850</span>+&nbsp;Destinations</h2>
                  <p className="sec-text mb-30">ASIO offers world class standard infrastructure including unique firefighting and storm water networks, paved footpaths and visitor car parking.</p>
                </div>
                <br />
              </div>
              <div className="slider-area ">
                <div className="swiper th-slider has-shadow" data-slider-options="{&quot;breakpoints&quot;:{&quot;0&quot;:{&quot;slidesPerView&quot;:1},&quot;576&quot;:{&quot;slidesPerView&quot;:&quot;1&quot;},&quot;768&quot;:{&quot;slidesPerView&quot;:&quot;2&quot;},&quot;992&quot;:{&quot;slidesPerView&quot;:&quot;2&quot;},&quot;1200&quot;:{&quot;slidesPerView&quot;:&quot;3&quot;},&quot;1300&quot;:{&quot;slidesPerView&quot;:&quot;4&quot;}}}">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/up loads/15807967721554906022Warehouses.jpg" style={{height: '179px'}} alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Sharjah, UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807965771554905868Modern_Infrastructure.jpg" style={{height: '179px'}} alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Sharjah,UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807968561555247316inside_warehouss.jpg" alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Sharjah,UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807968981555249431Quality_finishing.jpg" alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Sharjah,UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807969361567493059SAJAA_7.jpg" alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Dubai, UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="destination-item th-ani">
                        <div className="destination-item_img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807969741567493320SAJAA_20.jpg" alt="image" />
                        </div>
                        <div className="destination-content">
                          <h3 className="box-title"><a href>Dubai, UAE</a></h3>
                          <p className="destination-text">25 Listing</p>
                          <a href className="th-btn style4 th-icon">Book Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="destination-btn text-center mt-60">
                <a href className="th-btn style3 th-icon">View Full Gallery</a>
              </div>
            </div>
          </section> */}
          {/*==============================
  Category Area  
  ==============================*/}
            
            <RecentNews></RecentNews>
          {/* <section className="category-area3 bg-smoke space" data-bg-src="/assets/img/bg/line-pattern3.png">
            <div className="container th-container">
              <div className="title-area text-center">
                <h2 className="sec-title">Recent News</h2>
              </div>
              <div className="slider-area">
                <div className="swiper th-slider has-shadow category-slider3" id="categorySlider3" data-slider-options="{&quot;breakpoints&quot;:{&quot;0&quot;:{&quot;slidesPerView&quot;:1},&quot;576&quot;:{&quot;slidesPerView&quot;:&quot;1&quot;},&quot;768&quot;:{&quot;slidesPerView&quot;:&quot;2&quot;},&quot;992&quot;:{&quot;slidesPerView&quot;:&quot;3&quot;},&quot;1200&quot;:{&quot;slidesPerView&quot;:&quot;3&quot;},&quot;1400&quot;:{&quot;slidesPerView&quot;:&quot;5&quot;}}}">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079737715552490692.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Investment</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/15465160233.png" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807973961554905868Modern_Infrastructure.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Expand</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079759315465155474.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079737715552490692.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/15465160233.png" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/15807973961554905868Modern_Infrastructure.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079759315465155474.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079759315465155474.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="category-card single2">
                        <div className="box-img global-img">
                          <img src="https://www.asio.ae/public/uploads/158079759315465155474.jpg" style={{height: '274px'}} alt="Image" />
                        </div>
                        <h3 className="box-title"><a href>Sharjah Business</a></h3>
                        <a className="line-btn" href>See more</a>
                      </div>
                    </div>
                  </div>
                  <div className="slider-pagination" />
                </div>
              </div>
            </div>
          </section>  */}
          {/*==============================
  About Area  
  ==============================*/}
          <div className="about-area position-relative overflow-hidden space" id="about-sec">
            <div className="container">
              <div className="row">
                <div className="col-xl-7">
                  <div className="img-box3">
                    <div className="img1">
                      <img src="https://www.asio.ae/public/uploads/15465160233.png" style={{width: '422px', height: '498px'}} alt="About" />
                    </div>
                    <div className="img2">
                      <img src="https://www.asio.ae/public/uploads/15807967721554906022Warehouses.jpg" style={{width: '422px', height: '498px'}} alt="About" />
                    </div>
                    <div className="img3 movingX">
                      <img src="https://www.asio.ae/public/uploads/15807965771554905868Modern_Infrastructure.jpg" style={{width: '424px', height: '320px'}} alt="About" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="ps-xl-4">
                    <div className="title-area mb-20 pe-xxl-5 me-xxl-5">
                      <span className="sub-title style1 pb-20">Let’s Go Together</span>
                      <h2 className="sec-title mb-20 pe-xl-5 me-xl-5 heading" style={{fontSize: '26px'}}>Al Saja'a Industrial Oasis</h2>
                    </div>
                    <p className="sec-text mb-30">
                      Al Saja’a Industrial Oasis (ASIO), a project by Sharjah Asset Management Holding, the investment arm of the Government of Sharjah, is one of the groundbreaking and ambitious initiatives that follow the development plans of the Government of Sharjah. Strategically located on Emirates Road (E611), in close proximity to Sharjah International Airport and Al Hamriya Port, ASIO stretches over an area of 14 million square feet and comprises 353 plots for light and medium industrial, mixed use, some retail and a large accommodation plot. ASIO offers world class standard infrastructure including unique firefighting and stormwater networks, paved footpaths and visitor car parking. Plots in ASIO are available for sale to UAE Nationals, GCC and Arab residents as freehold investments.
                    </p>
                    <div className="mt-35"><a href="about.html" className="th-btn style3 th-icon">Learn More</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-mockup movingX d-none d-xxl-block" data-top="4%" data-left="2%">
              <img src="assets/img/shape/shape_2_1.png" alt="shape" />
            </div>
            <div className="shape-mockup jump d-none d-xxl-block" data-top="28%" data-right="5%">
              <img src="assets/img/shape/shape_2_2.png" alt="shape" />
            </div>
            <div className="shape-mockup spin d-none d-xxl-block" data-bottom="18%" data-left="2%">
              <img src="assets/img/shape/shape_2_3.png" alt="shape" />
            </div>
            <div className="shape-mockup movixgX d-none d-xxl-block" data-bottom="18%" data-right="2%">
              <img src="assets/img/shape/shape_2_4.png" alt="shape" />
            </div>
            <div className="shape-mockup movingCar d-none d-xxl-block" data-bottom="0%" data-right="2%">
              <img src="assets/img/shape/car_1.png" alt="shape" />
            </div>
            <div className="shape-mockup d-none d-xxl-block" data-bottom="0%" data-right="0%">
              <img src="assets/img/shape/tree_1.png" alt="shape" />
            </div>
          </div>{/*==============================
  Service Area  
  ==============================*/}
          <div className="elements-sec bg-white overflow-hidden">
            <div className="container-fluid">
              <div className="tags-container relative" />
            </div>
          </div>{/*==============================
  Contact Area  
  ==============================*/}
           <div className="bg-top-center overflow-hidden" data-bg-src={`/assets/img/bg/contact_bg_1.jpg`}>
            <div className="container">
              <div className="row gy-4 justify-content-between align-items-center">
                <div className="col-lg-5">
                  <div className="pt-80 p-lg-0">
                    <div className="title-area pe-xl-5">
                      <span className="sub-title text-white">Get in touch</span>
                      <h2 className="sec-title text-white">Say hello to us</h2>
                      <p className="contact-text text-white">We’love to hear from you. Our friendly team is always here to chat</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-form-area">
                    <form action="mail.php" method="POST" className="contact-form2 ajax-contact">
                      <div className="row">
                        <div className="form-group col-12">
                          <input type="text" className="form-control" name="name" id="name3" placeholder="First Name" />
                          <img src="assets/img/icon/user.svg" alt="" />
                        </div>
                        <div className="form-group col-12">
                          <input type="email" className="form-control" name="email3" id="email3" placeholder="Your Mail" />
                          <img src="assets/img/icon/mail.svg" alt="" />
                        </div>
                        <div className="form-group col-12">
                          <select name="subject" id="subject" className="form-select nice-select">
                            <option value="Select Tour Type" defaultValue disabled>Select Tour Type</option>
                            <option value="Africa Adventure">Africa Adventure</option>
                            <option value="Africa Wild">Africa Wild</option>
                            <option value="Asia">Asia</option>
                            <option value="Scandinavia">Scandinavia</option>
                            <option value="Western Europe">Western Europe</option>
                          </select>
                        </div>
                        <div className="form-group col-12">
                          <textarea name="message" id="message" cols={30} rows={3} className="form-control" placeholder="Your Message" defaultValue={""} />
                          <img src="assets/img/icon/chat.svg" alt="" />
                        </div>
                      </div>
                      <p className="form-messages mb-0 mt-3" />
                    </form>
                    <div className="form-btn-wrapp">
                      <div className="form-btn">
                        <button className="th-btn white-btn">Send Message <img src="assets/img/icon/plane3.svg" alt="" /></button>
                      </div>
                      <div className="contact-info">
                        <p className="contact-info_link"><a href="tel:+0123456789">+012 345 6789</a></p>
                        <div className="contact-info_icon">
                          <a href="tel:+0123456789"><img src="assets/img/icon/call.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/*==============================
  Testimonial Area  
  ==============================*/}
  <Testimonials></Testimonials>
          {/* <section className="testi-area3 bg-bottom-center overflow-hidden space" id="testi-sec" data-bg-src="/assets/img/bg/map.png">
            <div className="container">
              <div className="title-area text-center">
                <span className="sub-title">Testimonials</span>
                <h2 className="sec-title">Our Clients Feedback</h2>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="swiper th-slider testiSlide3" id="testiSlide3" data-slider-options="{&quot;effect&quot;:&quot;slide&quot;,&quot;loop&quot;:false,&quot;thumbs&quot;:{&quot;swiper&quot;:&quot;.testi-grid-thumb&quot;}}">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_1.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">“A home that perfectly blends sustainability with luxury until discovered Ecoland Residence. The moment I stepped community, I knew it was where I wanted to live.”</p>
                            <h6 className="testi-grid_name box-title">Andrew Simon</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_2.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">“The home boasts sleek, contemporary architecture with clean lines and expansive windows, allowing natural light to flood the interiors It incorporates passive design principles”</p>
                            <h6 className="testi-grid_name box-title">Maria Doe</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_3.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">“Solar panels adorn the roof, harnessing renewable energy to power the home and even feed excess electricity back into the grid. High-performance insulation and triple-glazed”</p>
                            <h6 className="testi-grid_name box-title">Angelina Rose</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_4.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">A sophisticated rainwater harvesting system collects and filters rainwater for irrigation and non-potable uses, reducing reliance on municipal water sources. Greywater systems</p>
                            <h6 className="testi-grid_name box-title">Michel Carlos</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_5.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">Throughout the interior, eco-friendly materials like reclaimed wood, bamboo flooring, and recycled glass countertops create a luxurious yet sustainable ambiance.</p>
                            <h6 className="testi-grid_name box-title">Michel Smith</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-grid">
                          <div className="testi-grid_author">
                            <img src="assets/img/testimonial/testi_3_6.png" alt="Avater" />
                          </div>
                          <div className="testi-grid_content">
                            <p className="testi-grid_text">“A home that perfectly blends sustainability with luxury until discovered Ecoland Residence. The moment I stepped community, I knew it was where I wanted to live.”</p>
                            <h6 className="testi-grid_name box-title">Jesmen</h6>
                            <span className="testi-grid_desig">Traveller</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-pagination" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper th-slider testi-grid-thumb" data-slider-options="{&quot;effect&quot;:&quot;slide&quot;,&quot;slidesPerView&quot;:&quot;6&quot;,&quot;spaceBetween&quot;:7,&quot;loop&quot;:false}">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_1.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_2.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_3.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_4.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_5.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_3_6.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-mockup movingX d-none d-xl-block" data-top="20%" data-left="5%">
              <img className="gmovingX" src="assets/img/shape/shape_7.png" alt="shape" />
            </div>
            <div className="shape-mockup spin d-none d-xl-block" data-bottom="12%" data-right="5%">
              <img src="assets/img/shape/shape_2_5.png" alt="shape" />
            </div>
            <div className="shape-mockup jump d-none d-xl-block" data-bottom="15%" data-left="5%">
              <img src="assets/img/shape/shape_2_2.png" alt="shape" />
            </div>
          </section> */}
          {/*==============================
      Footer Area
  ==============================*/}
          <footer className="footer-wrapper bg-title footer-layout2">
            <div className="widget-area">
              <div className="container">
                <div className="newsletter-area">
                  <div className="newsletter-top">
                    <div className="row gy-4 align-items-center">
                      <div className="col-lg-5">
                        <h2 className="newsletter-title text-white text-capitalize mb-0">get updated the latest
                          newsletter</h2>
                      </div>
                      <div className="col-lg-7">
                        <form className="newsletter-form style2">
                          <input className="form-control " type="email" placeholder="Enter Email" required />
                          <button type="submit" className="th-btn style1">Subscribe Now <img src="assets/img/icon/plane2.svg" alt="" /></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-md-6 col-xl-3">
                    <div className="widget footer-widget">
                      <div className="th-widget-about">
                        <div className="about-logo">
                          <a href="home-travel.html"><img src="https://www.ei-city.net/Images/logo.svg" width={200} height={56} alt="Tourm" /></a>
                        </div>
                        <p className="about-text">
                          Al Saja’a Industrial Oasis (ASIO), a project by Sharjah Asset Management,  the investment arm of the Government of Sharjah.</p>
                        <div className="th-social">
                          <a href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a>
                          <a href="https://www.twitter.com/"><i className="fab fa-twitter" /></a>
                          <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" /></a>
                          <a href="https://www.whatsapp.com/"><i className="fab fa-whatsapp" /></a>
                          <a href="https://instagram.com/"><i className="fab fa-instagram" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-auto">
                    <div className="widget widget_nav_menu footer-widget">
                      <h3 className="widget_title">Quick Links</h3>
                      <div className="menu-all-pages-container">
                        <ul className="menu">
                          <li><a href="index.html">Home</a></li>
                          <li><a href="about.html">About us</a></li>
                          <li><a href="service.html">Our Service</a></li>
                          <li><a href="contact.html">Terms of Service</a></li>
                          <li><a href="contact.html">Tour Booking Now</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-auto">
                    <div className="widget footer-widget">
                      <h3 className="widget_title">Get In Touch</h3>
                      <div className="th-widget-contact">
                        <div className="info-box_text">
                          <div className="icon">
                            <img src="assets/img/icon/phone.svg" alt="img" />
                          </div>
                          <div className="details">
                            <p><a href="tel:+01234567890" className="info-box_link">+800 800 88</a></p>
                            <p><a href="tel:+09876543210" className="info-box_link">+800 800 88</a></p>
                          </div>
                        </div>
                        <div className="info-box_text">
                          <div className="icon">
                            <img src="assets/img/icon/envelope.svg" alt="img" />
                          </div>
                          <div className="details">
                            <p><a href="mailto:mailinfo00@tourm.com" className="info-box_link"> sales@sam.ae</a></p>
                            <p><a href="mailto:support24@tourm.com" className="info-box_link"> info@sam.ae</a></p>
                          </div>
                        </div>
                        <div className="info-box_text">
                          <div className="icon"><img src="assets/img/icon/location-dot.svg" alt="img" /></div>
                          <div className="details">
                            <p>Sharjah PO Box 4648 Sharjah-UAE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-auto">
                    <div className="widget footer-widget">
                      <h3 className="widget_title">Instagram Post</h3>
                      <div className="sidebar-gallery">
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15807967721554906022Warehouses.jpg" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15807965771554905868Modern_Infrastructure.jpg" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15465160233.png" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15807968561555247316inside_warehouss.jpg" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15807968981555249431Quality_finishing.jpg" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                        <div className="gallery-thumb">
                          <img src="https://www.asio.ae/public/uploads/15807969361567493059SAJAA_7.jpg" style={{width: '96px', height: '96px'}} alt="Gallery Image" />
                          <a target="_blank" href="https://www.instagram.com/" className="gallery-btn"><i className="fab fa-instagram" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-wrap">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-6">
                    <p className="copyright-text">Copyright 2024 <a href="home-travel.html">asio</a>. All Rights Reserved.</p>
                  </div>
                  <div className="col-md-6 text-end d-none d-md-block">
                    <div className="footer-card">
                      <span className="title">We Accept</span>
                      <img src="assets/img/shape/cards.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-mockup movingX d-none d-xxl-block" data-top="24%" data-left="5%">
              <img src="assets/img/shape/shape_8.png" alt="shape" />
            </div>
          </footer>
          {/*********************************
              Code End  Here 
      ******************************** */}
          {/* Scroll To Top */}
          <div className="scroll-top">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{transition: 'stroke-dashoffset 10ms linear 0s', strokeDasharray: '307.919, 307.919', strokeDashoffset: '307.919'}}>
              </path>
            </svg>
          </div>
          {/*==============================
  modal Area  
  ==============================*/}
          <div id="login-form" className="popup-login-register mfp-hide">
            <ul className="nav" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-menu" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Login</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-menu active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Register</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <h3 className="box-title mb-30">Sign in to your account</h3>
                <div className="th-login-form">
                  <form action="mail.php" method="POST" className="login-form ajax-contact">
                    <div className="row">
                      <div className="form-group col-12">
                        <label>Username or email</label>
                        <input type="text" className="form-control" name="email" id="email" required="required" />
                      </div>
                      <div className="form-group col-12">
                        <label>Password</label>
                        <input type="password" className="form-control" name="pasword" id="pasword" required="required" />
                      </div>
                      <div className="form-btn mb-20 col-12">
                        <button className="th-btn btn-fw th-radius2 ">Send Message</button>
                      </div>
                    </div>
                    <div id="forgot_url">
                      <a href="my-account.html">Forgot password?</a>
                    </div>
                    <p className="form-messages mb-0 mt-3" />
                  </form>
                </div>
              </div>
              <div className="tab-pane fade active show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <h3 className="th-form-title mb-30">Sign in to your account</h3>
                <form action="mail.php" method="POST" className="login-form ajax-contact">
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Username*</label>
                      <input type="text" className="form-control" name="usename" id="usename" required="required" />
                    </div>
                    <div className="form-group col-12">
                      <label>First name*</label>
                      <input type="text" className="form-control" name="firstname" id="firstname" required="required" />
                    </div>
                    <div className="form-group col-12">
                      <label>Last name*</label>
                      <input type="text" className="form-control" name="lastname" id="lastname" required="required" />
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="new_email">Your email*</label>
                      <input type="text" className="form-control" name="new_email" id="new_email" required="required" />
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="new_email_confirm">Confirm email*</label>
                      <input type="text" className="form-control" name="new_email_confirm" id="new_email_confirm" required="required" />
                    </div>
                    <div className="statement">
                      <span className="register-notes">A password will be emailed to you.</span>
                    </div>
                    <div className="form-btn mt-20 col-12">
                      <button className="th-btn btn-fw th-radius2 ">Sign up</button>
                    </div>
                  </div>
                  <p className="form-messages mb-0 mt-3" />
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }


export default NewHome;
import React from 'react';
import { Autoplay, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/swiper-bundle.min.css'; // Import Swiper CSS
import './css/testimonials.css';

const Testimonials = () => {
    const testimonials = [
        {
            img: "assets/img/testimonial/testi_3_1.png",
            name: "Andrew Simon",
            designation: "Traveller",
            feedback: "“A home that perfectly blends sustainability with luxury until discovered Ecoland Residence. The moment I stepped community, I knew it was where I wanted to live.”"
        },
        {
            img: "assets/img/testimonial/testi_3_2.png",
            name: "Maria Doe",
            designation: "Traveller",
            feedback: "“The home boasts sleek, contemporary architecture with clean lines and expansive windows, allowing natural light to flood the interiors It incorporates passive design principles.”"
        },
        {
            img: "assets/img/testimonial/testi_3_3.png",
            name: "Angelina Rose",
            designation: "Traveller",
            feedback: "“Solar panels adorn the roof, harnessing renewable energy to power the home and even feed excess electricity back into the grid. High-performance insulation and triple-glazed.”"
        },
        {
            img: "assets/img/testimonial/testi_3_4.png",
            name: "Michel Carlos",
            designation: "Traveller",
            feedback: "“A sophisticated rainwater harvesting system collects and filters rainwater for irrigation and non-potable uses, reducing reliance on municipal water sources. Greywater systems.”"
        },
        {
            img: "assets/img/testimonial/testi_3_5.png",
            name: "Michel Smith",
            designation: "Traveller",
            feedback: "“Throughout the interior, eco-friendly materials like reclaimed wood, bamboo flooring, and recycled glass countertops create a luxurious yet sustainable ambiance.”"
        },
        {
            img: "assets/img/testimonial/testi_3_6.png",
            name: "Jesmen",
            designation: "Traveller",
            feedback: "“A home that perfectly blends sustainability with luxury until discovered Ecoland Residence. The moment I stepped community, I knew it was where I wanted to live.”"
        }
    ];

    return (
        <section className="testi-area3 bg-bottom-center overflow-hidden space" id="testi-sec" data-bg-src="/assets/img/bg/map.png">
            <div className="container">
                <div className="title-area text-center">
                    <span className="sub-title">Testimonials</span>
                    <h2 className="sec-title">Our Clients Feedback</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination, Thumbs]}
                        >
                            {testimonials.map((testimonial, index) => (
                                <SwiperSlide key={index}>
                                    <div className="testi-grid">
                                        <div className="testi-grid_author">
                                            <img src={testimonial.img} alt={testimonial.name} />
                                        </div>
                                        <div className="testi-grid_content">
                                            <p className="testi-grid_text">{testimonial.feedback}</p>
                                            <h6 className="testi-grid_name box-title">{testimonial.name}</h6>
                                            <span className="testi-grid_desig">{testimonial.designation}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* Thumbnails section */}
            {/* <div className="swiper th-slider testi-grid-thumb" data-slider-options="{&quot;effect&quot;:&quot;slide&quot;,&quot;slidesPerView&quot;:&quot;6&quot;,&quot;spaceBetween&quot;:7,&quot;loop&quot;:false}">
                <Swiper
                    spaceBetween={7}
                    slidesPerView={6}
                    watchSlidesProgress
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div className="box-img">
                                <img src={testimonial.img} alt={`thumb-${testimonial.name}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div> */}
        </section>
    );
};

export default Testimonials;

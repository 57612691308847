import React, { useEffect } from 'react';
import $ from 'jquery';
import 'magnific-popup';
import './css/menuBar.css';
import { Outlet } from "react-router-dom";
// import logo from "../assets/logo.svg";

function Menu() {
  useEffect(() => {
    // Mobile Menu Toggle
    const menu = $(".th-menu-wrapper");

    function menuToggle() {
      menu.toggleClass("th-body-visible");

      // Collapse submenu when menu is hidden or shown
      const subMenu = ".th-submenu";
      $(subMenu).each(function () {
        if ($(this).hasClass("th-open")) {
          $(this).removeClass("th-open");
          $(this).css("display", "none");
          $(this).parent().removeClass("th-active");
        }
      });
    }

    // Set up submenu toggle behavior
    menu.find(".menu-item-has-children").each(function () {
      const submenu = $(this).find("ul");
      submenu.addClass("th-submenu");
      submenu.css("display", "none");
      $(this).addClass("th-item-has-children");

      // Toggle submenu
      $(this).children("a").on("click", function (e) {
        e.preventDefault();
        const $element = $(this).parent();
        const submenu = $element.children("ul");
        $element.toggleClass("th-active");
        submenu.slideToggle(400);
        submenu.toggleClass("th-open");
      });
    });

    // Toggle menu on button click
    $(".th-menu-toggle").on("click", function () {
      menuToggle();
    });

    // Hide menu when clicked outside
    menu.on("click", function (e) {
      e.stopPropagation();
      menuToggle();
    });

    // Prevent menu hide when clicking inside menu
    menu.find("div").on("click", function (e) {
      e.stopPropagation();
    });

    return () => {
      // Cleanup to prevent memory leaks
      $(".th-menu-toggle").off("click");
      menu.off("click");
      menu.find("a").off("click");
    };
  }, []);
  return (
    <>
      <div className="sidemenu-wrapper sidemenu-info ">
        <div className="sidemenu-content">
          <button className="closeButton sideMenuCls"><i className="far fa-times" /></button>
          <div className="widget  ">
            <div className="th-widget-about">
              <div className="about-logo">
                <a href="home-travel.html"><img src="assets/img/logo2.svg" alt="Tourm" /></a>
              </div>
              <p className="about-text">Rapidiously myocardinate cross-platform intellectual capital model. Appropriately create interactive infrastructures</p>
              <div className="th-social">
                <a href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a>
                <a href="https://www.twitter.com/"><i className="fab fa-twitter" /></a>
                <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" /></a>
                <a href="https://www.whatsapp.com/"><i className="fab fa-whatsapp" /></a>
              </div>
            </div>
          </div>
          <div className="widget  ">
            <h3 className="widget_title">Recent Posts</h3>
            <div className="recent-post-wrap">
              <div className="recent-post">
                <div className="media-img">
                  <a href="blog-details.html"><img src="assets/img/blog/recent-post-1-1.jpg" alt="Blog Image" /></a>
                </div>
                <div className="media-body">
                  <div className="recent-post-meta">
                    <a href="blog.html"><i className="far fa-calendar" />24 Jun , 2024</a>
                  </div>
                  <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Where Vision Meets Concrete
                    Reality</a></h4>
                </div>
              </div>
              <div className="recent-post">
                <div className="media-img">
                  <a href="blog-details.html"><img src="assets/img/blog/recent-post-1-2.jpg" alt="Blog Image" /></a>
                </div>
                <div className="media-body">
                  <div className="recent-post-meta">
                    <a href="blog.html"><i className="far fa-calendar" />22 Jun , 2024</a>
                  </div>
                  <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Raising the Bar in Construction.</a></h4>
                </div>
              </div>
            </div>
          </div>
          <div className="widget  ">
            <h3 className="widget_title">Get In Touch</h3>
            <div className="th-widget-contact">
              <div className="info-box_text">
                <div className="icon">
                  <img src="assets/img/icon/phone.svg" alt="img" />
                </div>
                <div className="details">
                  <p><a href="tel:+01234567890" className="info-box_link">+01 234 567 890</a></p>
                  <p><a href="tel:+09876543210" className="info-box_link">+09 876 543 210</a></p>
                </div>
              </div>
              <div className="info-box_text">
                <div className="icon">
                  <img src="assets/img/icon/envelope.svg" alt="img" />
                </div>
                <div className="details">
                  <p><a href="mailto:mailinfo00@tourm.com" className="info-box_link">mailinfo00@tourm.com</a></p>
                  <p><a href="mailto:support24@tourm.com" className="info-box_link">support24@tourm.com</a></p>
                </div>
              </div>
              <div className="info-box_text">
                <div className="icon"><img src="assets/img/icon/location-dot.svg" alt="img" /></div>
                <div className="details">
                  <p>789 Inner Lane, Holy park, California, USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popup-search-box">
        <button className="searchClose"><i className="fal fa-times" /></button>
        <form action="#">
          <input type="text" placeholder="What are you looking for?" />
          <button type="submit"><i className="fal fa-search" /></button>
        </form>
      </div>
      {/* Mobile Menu */}
      <div className="th-menu-wrapper onepage-nav">
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle"><i className="fal fa-times" /></button>
          <div className="mobile-logo" style={{ backgroundColor: 'burlywood' }}>
            <a href="home-travel.html">
              <img src="https://www.ei-city.net/Images/logo.svg" width={200} height={56} alt="Tourm" />
            </a>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li><a className="active" href="/">Home</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Project Overview</a></li>
              <li><a href="#">The Procedure</a></li>
              <li><a href="/sitePlan">Site Plan</a></li>
              <li><a href="#">Location</a></li>
              <li><a href="contact.html">Contact us</a></li>
            </ul>

          </div>
        </div>
      </div>
      <header className="th-header header-layout3 header-absolute">
        <div className="sticky-wrapper">
          {/* Main Menu Area */}
          <div className="menu-area">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <nav className="main-menu d-none d-xl-block">
                    <ul>
                      <li><a className="active" href="\">Home</a></li>
                      <li><a href="#">About Us</a></li>
                      <li><a href="#">Project Overview</a></li>
                    </ul>
                  </nav>
                </div>
                <div className="col-auto">
                  <div className="header-logo">
                    <a href="\">
                      <img src="https://www.ei-city.net/Images/logo.svg" width={200} height={56} alt="Tourm" />
                    </a>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-xl-block">
                    <ul>
                      <li><a href="#">The Procedure</a></li>
                      <li><a href="/sitePlan">Site Plan</a></li>
                      <li><a href="#">Location</a></li>
                      <li><a href="contact.html">Contact us</a></li>
                    </ul>
                  </nav>
                  <button type="button" className="th-menu-toggle d-block d-xl-none"><i className="far fa-bars" /></button>
                </div>
              </div>
            </div>
            <div className="header-right-button">
              <a href="#" className="simple-btn sideMenuToggler"><img src="assets/img/icon/menu.svg" alt="" /></a>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default Menu

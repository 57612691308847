import React, { useState } from "react";
import './css/sitePlan.css';
import axios from "axios";
const SitePlan = () => {
    const [data, setData] = useState(null); // State to store fetched data
    const [filters, setFilters] = useState({
        ownership: [],
        types: [],
    });
    const [clickedPlot, setClickedPlot] = useState(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);

    // Handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [name]: prevFilters[name].includes(value)
                    ? prevFilters[name].filter(v => v !== value) // Remove if already selected
                    : [...prevFilters[name], value] // Add if not selected
            };
    
            // Check if any filter has been selected, and enable the submit button
            const isAnyFilterSelected = updatedFilters.ownership.length > 0 || updatedFilters.types.length > 0;
            setSubmitDisabled(!isAnyFilterSelected); // Disable button if no filters are selected
            console.log('Submit button enabled:', isAnyFilterSelected); // Log to check button state
    
            return updatedFilters;
        });
    };
    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
    
        const typesParam = filters.types.join(',');
        const ownershipParam = filters.ownership.join(',');
    
        // Log the final URL for debugging
        const apiUrl = `https://cites.dmaksolutions.com/api/plot?types=${encodeURIComponent(typesParam)}&ownership=${encodeURIComponent(ownershipParam)}`;
        console.log('Request URL:', apiUrl);
    
        fetch(apiUrl)
            .then((response) => {
                console.log('Response:', response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON response
            })
            .then((filteredData) => {
                console.log('Filtered data:', filteredData); // Log the filtered data for debugging
                setData(filteredData); // Store filtered data
                highlightPaths(filteredData); // Highlight SVG paths based on filters
            })
            .catch((error) => console.error('Error fetching filtered data:', error));
    };

    // Function to highlight paths based on returned data
    const highlightPaths = (filteredData) => {
        // console.log('Filtered data', filteredData);
        if (!Array.isArray(filteredData)) {
            console.error('Expected an array but got:', filteredData);
            return; // Exit the function if not an array
        }
        const svgPaths = document.querySelectorAll('svg path, svg polyline'); // Adjust selector based on your SVG structure
        svgPaths.forEach(path => {
            path.style.fill = ""; // Reset previous fill color
        });
        console.log('Filtered data to highlight:', filteredData);

        filteredData.forEach((path) => {
            const pathElement = document.getElementById(path);
            console.log('path:', pathElement);
            if (pathElement) {
                pathElement.style.fill = "yellow"; // Example: change path color
            }
        });
    };

    // const highlightPath = (pathId) => {
    //     const svgPaths = document.querySelectorAll('svg path'); // Select all paths
    //     svgPaths.forEach((path) => {
    //         path.style.fill = ''; // Reset previous fill color
    //     });

    //     const clickedPath = document.getElementById(pathId);
    //     if (clickedPath) {
            
    //         clickedPath.style.fill = 'yellow'; // Highlight the clicked path
    //     }
    // };
    const handleClick = (event) => {
        const pathId = event.target.id; // Get the ID of the clicked path
        console.log('Clicked path ID:', pathId);
    
        // Fetch data from the Node.js backend using the path's ID
        axios.get(`https://cites.dmaksolutions.com/api/plot?id=${pathId}`)
            .then((response) => {
                console.log('Fetched data:', response.data);
                setData(response.data); // Set the fetched data to state
                setClickedPlot(true); // Set clicked state to true
                // highlightPath(pathId); // Highlight the clicked path
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    // Function to highlight the clicked path
    
    return (
        <div>
            <div className="svgImage">
                <svg id="demoSVG" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} viewBox="0 0 1884.9 561.95">
                    <title>svg_map_latest</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <path id="id_10935" data-name="id 10935" className="plot_block cls-1" d="M116.5,277.1l38,2.6s12.8,3.4,18.3,8.2,11.9,14.7,12.2,22.3a121,121,0,0,1-.8,16.6L137.7,325l-1.1-3.2-1.2-3.8-1-2.1-.2-1.9-.6-1.2-1-2.7-2-4.8-3.4-8.6-1.5-3.2-1.8-3.5-3.2-5.6-1-3.1-1.8-1.8-1.1-1.3" transform="translate(5.92 -69)"></path>
                        <path id="id_10948" data-name="id 10948" className="plot_block cls-1" d="M117.8,513.2l16.3.1.1,35.2,34.6-.4,6-5.1.9-30.4" transform="translate(5.92 -69)"></path>
                        <polyline id="id_10947" data-name="id 10947" className="plot_block cls-1" points="139.92 480 99.92 480 102.72 475.7 108.32 468.4 114.62 459.6 119.82 451.5 123.12 445.9 124.22 444 139.92 444"></polyline>
                        <polygon id="id_10944" data-name="id 10944" className="plot_block cls-1" points="83.22 391.6 137.72 415.6 130.12 432.8 123.72 444.5 119.52 451.6 117.72 454.4 68.52 417.5 71.42 413.1 76.42 405.5 80.72 397.2 83.22 391.6"></polygon>
                        <path id="id_10942" data-name="id 10942" className="plot_block cls-1" d="M78.2,460.7s-.3-.5-.2-.6,4.5-11.9,4.5-11.9l2.5-8.6,1.7-7.2,2.3.3,55.2,11.4V446s-2.8,11.9-3.3,13.5-5,15-5,15l-2,5.5-2,4.5" transform="translate(5.92 -69)"></path>
                        <polygon id="id_10940" data-name="id 10940" className="plot_block cls-1" points="94.92 333.8 154.72 331.8 154.82 341.2 153.72 351.1 152.02 366.8 150.12 375.1 96.62 364.2 92.22 363 93.72 355.3 94.62 347.4 95.12 339.5 94.92 333.8"></polygon>
                        <path id="id_10938" data-name="id 10938" className="plot_block cls-1" d="M84.8,372.9c.3-.1,60.5-15.8,60.5-15.8s2.7,12.8,2.9,16.7.8,23.2.8,23.2l-.2,3.8-59.7,1.5-.8-4.6-.4-9.5-1.9-11" transform="translate(5.92 -69)"></path>
                        <polyline id="id_10936" data-name="id 10936" className="plot_block cls-1" points="140.72 247.2 145.22 260.3 148.12 271.8 150.32 279.7 151.72 288.2 90.42 304.1 88.82 296.8 86.72 290.7 84.72 285.1 82.72 280.5 80.92 277.5"></polyline>
                        <polyline id="id_10934" data-name="id 10934" className="plot_block cls-1" points="122.32 208.6 126.42 215.4 130.92 224.2 137.22 238 139.62 243.7 140.72 246.8 140.42 247.2 138.62 248.4 82.12 276.6 81.02 277.2 80.52 276.5 75.42 267.4 73.22 263.6 71.82 261.8 72.32 256.9 75.02 230 102.42 206.6"></polyline>
                        <polygon id="id_10937" data-name="id 10937" className="plot_block cls-1" points="144.02 256.1 190.22 257.8 189.02 293.9 152.52 292.2 150.92 285.2 149.32 276.1 147.32 268.8 144.52 258.4 144.02 256.1"></polygon>
                        <polyline id="id_10939" data-name="id 10939" className="plot_block cls-1" points="188.82 294.4 187.32 329.2 155.22 328.1 154.82 316.9 154.62 311.2 154.12 304.8 153.02 296 152.52 292.2"></polyline>
                        <path id="id_10941" data-name="id 10941" className="plot_block cls-1" d="M149.1,397.9s.1-1,.7-1,31.5,1.5,31.5,1.5v6L180,434l-33.5-1.5.3-3.1,1.4-10.9.4-7.4.3-7Z" transform="translate(5.92 -69)"></path>
                        <polyline id="id_10943" data-name="id 10943" className="plot_block cls-1" points="185.72 365.4 184.22 400.8 143.92 398.8 146.02 392.3 148.82 382 150.82 372.9 152.22 364.9 152.42 363.5"></polyline>
                        <polyline id="id_10945" data-name="id 10945" className="plot_block cls-1" points="183.92 401 182.02 443.8 124.32 444.3 126.82 439.4 130.42 432.7 137.52 416.9 140.42 409 144.02 399"></polyline>
                        <path className="no_plot_block cls-1" d="M63.9,353.2s3.5,6.3,4.3,9.3,4.1,12.1,4.1,12.1,1.1,6.9,1.1,7.3,1.8,7.2,1.8,7.2l.9,12.4-.1,8.2-.5,9.9L73.4,431l-2.2,9.3-2.9,8.9-3.5,7.7L61,464.3l-2.6,5-4,6.1-1.8,1.8" transform="translate(5.92 -69)"></path>
                        <path className="no_plot_block cls-1" d="M194.7,482.4l5.8-5.9,537.9-4,3.7,3.2,2,2,.4,31-.1,3.4a21,21,0,0,1-.2,4c-.3,1.1-1.7,7.1-2.4,8.9a40.22,40.22,0,0,1-4.5,7.3,14.69,14.69,0,0,1-2.9,3l-1.7,1.3-2,1.4s-2,1.3-2.2,1.4-2.5,1.4-2.5,1.4l-1.9.8-3,.8s-1.7.4-1.8.4-3.2.4-3.2.4l-2.6.3-.8.2-1,.1H681.4l-437.8,3.6-46.9.4-5.1-5.1" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11044" data-name="id 11044" className="plot_block cls-1" points="240.62 318.7 240.12 337 204.42 335.3 204.72 324.6 209.72 319.6 211.52 319"></polyline>
                        <polyline id="id_11045" data-name="id 11045" className="plot_block cls-1" points="239.92 337.2 239.12 354.6 203.62 352.9 204.42 335.7"></polyline>
                        <polyline id="id_11046" data-name="id 11046" className="plot_block cls-1" points="239.02 355.1 238.22 372.3 202.82 370.8 203.42 353.5"></polyline>
                        <path id="id_11047" data-name="id 11047" className="plot_block cls-1" d="M232.2,441.8c0,.1-.4,17.5-.8,17.5l-30.3.3-4.8-5,.4-14.4" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11043" data-name="id 11043" className="plot_block cls-1" points="237.52 390.2 268.02 390.1 267.82 354.9 239.42 355"></polyline>
                        <polyline id="id_11042" data-name="id 11042" className="plot_block cls-1" points="267.62 319 267.72 354.2 239.42 354.5 240.92 319.5"></polyline>
                        <polyline id="id_11040" data-name="id 11040" className="plot_block cls-1" points="267.92 319.2 289.02 318.8 289.22 354.1 268.12 354.3"></polyline>
                        <path id="id_11038" data-name="id 11038" className="plot_block cls-1" d="M283.6,387.8l20.8-.1.3,35.3-21.1.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11036" data-name="id 11036" className="plot_block cls-1" points="332.22 353.7 331.92 318.3 310.72 318.8 311.12 354.1"></polyline>
                        <polyline id="id_11034" data-name="id 11034" className="plot_block cls-1" points="332.42 353.8 332.32 318.2 353.22 318.3 353.62 353.6"></polyline>
                        <polyline id="id_11032" data-name="id 11032" className="plot_block cls-1" points="353.42 318.2 374.52 318.1 374.92 353.6 353.82 353.7"></polyline>
                        <polyline id="id_11030" data-name="id 11030" className="plot_block cls-1" points="374.92 318.1 395.92 317.9 396.22 353.2 375.32 353.4"></polyline>
                        <polyline id="id_11028" data-name="id 11028" className="plot_block cls-1" points="396.52 353.2 417.92 353.2 417.72 322.3 415.52 322.2 415.22 317.8 395.92 317.9"></polyline>
                        <polyline id="id_11026" data-name="id 11026" className="plot_block cls-1" points="419.92 317.7 419.72 322.1 417.92 322.2 418.12 353.1 439.22 353 439.12 317.8"></polyline>
                        <polyline id="id_11024" data-name="id 11024" className="plot_block cls-1" points="439.32 317.5 439.52 353.1 460.42 352.8 460.12 317.5"></polyline>
                        <polyline id="id_11022" data-name="id 11022" className="plot_block cls-1" points="460.42 317.3 461.02 352.8 482.12 352.8 481.92 317.5"></polyline>
                        <polyline id="id_11020" data-name="id 11020" className="plot_block cls-1" points="482.32 317.4 502.92 317 503.32 352.5 482.52 352.6"></polyline>
                        <polyline id="id_11018" data-name="id 11018" className="plot_block cls-1" points="503.62 352.4 503.32 317.1 524.52 317 524.92 352.2"></polyline>
                        <polyline id="id_11016" data-name="id 11016" className="plot_block cls-1" points="525.32 352.4 546.32 352.2 546.42 316.7 525.02 316.9"></polyline>
                        <polyline id="id_11014" data-name="id 11014" className="plot_block cls-1" points="546.72 316.5 567.32 316.6 567.82 351.9 546.92 352.1"></polyline>
                        <polyline id="id_11012" data-name="id 11012" className="plot_block cls-1" points="568.02 316.4 588.72 316.5 589.22 352 567.82 351.9"></polyline>
                        <polyline id="id_11010" data-name="id 11010" className="plot_block cls-1" points="589.12 315.9 610.12 316 610.62 351.4 589.22 352"></polyline>
                        <polyline id="id_11008" data-name="id 11008" className="plot_block cls-1" points="610.72 316.2 631.72 316 632.02 351.8 610.92 352.2"></polyline>
                        <polyline id="id_11006" data-name="id 11006" className="plot_block cls-1" points="631.92 316 652.92 316 653.12 351.4 632.62 351.8"></polyline>
                        <polyline id="id_11004" data-name="id 11004" className="plot_block cls-1" points="653.42 315.7 674.32 315.5 674.72 351.3 653.52 351.4"></polyline>
                        <polyline id="id_11002" data-name="id 11002" className="plot_block cls-1" points="674.72 315.5 695.82 315.3 696.32 351.1 675.12 351.4"></polyline>
                        <polyline id="id_11000" data-name="id 11000" className="plot_block cls-1" points="696.52 315.4 717.62 315.2 717.82 350.9 696.62 350.9"></polyline>
                        <polyline id="id_11041" data-name="id 11041" className="plot_block cls-1" points="268.22 354.7 289.32 354.6 289.82 389.8 268.32 390"></polyline>
                        <polyline id="id_11039" data-name="id 11039" className="plot_block cls-1" points="289.82 354.4 310.32 354.3 311.12 389.4 289.82 389.8"></polyline>
                        <rect id="id_11037" data-name="id 11037" className="plot_block cls-1" x="311.02" y="354.5" width="21.5" height="35"></rect>
                        <rect id="id_11035" data-name="id 11035" className="plot_block cls-1" x="332.32" y="354.3" width="21.5" height="35.1"></rect>
                        <rect id="id_11033" data-name="id 11033" className="plot_block cls-1" x="353.82" y="354.1" width="21.5" height="35.3"></rect>
                        <rect id="id_11031" data-name="id 11031" className="plot_block cls-1" x="375.22" y="353.9" width="21.5" height="35.5"></rect>
                        <rect id="id_11029" data-name="id 11029" className="plot_block cls-1" x="396.92" y="353.5" width="21.5" height="35.5"></rect>
                        <rect id="id_11027" data-name="id 11027" className="plot_block cls-1" x="418.12" y="353.5" width="21.5" height="35.5"></rect>
                        <rect id="id_11025" data-name="id 11025" className="plot_block cls-1" x="439.52" y="353" width="21.5" height="35.5"></rect>
                        <rect id="id_11023" data-name="id 11023" className="plot_block cls-1" x="460.72" y="353" width="21.5" height="35.5"></rect>
                        <rect id="id_11021" data-name="id 11021" className="plot_block cls-1" x="482.12" y="353" width="21.5" height="35.5"></rect>
                        <rect id="id_11019" data-name="id 11019" className="plot_block cls-1" x="503.72" y="352.8" width="21.5" height="35.5"></rect>
                        <rect id="id_11017" data-name="id 11017" className="plot_block cls-1" x="525.42" y="352.6" width="21.5" height="35.5"></rect>
                        <rect id="id_11015" data-name="id 11015" className="plot_block cls-1" x="546.62" y="352.3" width="21.5" height="35.5"></rect>
                        <rect id="id_11013" data-name="id 11013" className="plot_block cls-1" x="568.12" y="352.1" width="21.5" height="35.5"></rect>
                        <rect id="id_11011" data-name="id 11011" className="plot_block cls-1" x="589.22" y="352.1" width="21.5" height="35.5"></rect>
                        <rect id="id_11009" data-name="id 11009" className="plot_block cls-1" x="610.72" y="352.1" width="21.5" height="35.5"></rect>
                        <rect id="id_11007" data-name="id 11007" className="plot_block cls-1" x="632.32" y="351.6" width="21.5" height="35.7"></rect>
                        <rect id="id_11005" data-name="id 11005" className="plot_block cls-1" x="653.42" y="351.6" width="21.5" height="35.7"></rect>
                        <rect id="id_11003" data-name="id 11003" className="plot_block cls-1" x="662.17" y="427.46" width="35.6" height="21.5" transform="translate(242.75 1045.98) rotate(-89.58)"></rect>
                        <rect id="id_11001" data-name="id 11001" className="plot_block cls-1" x="683.57" y="427.46" width="35.6" height="21.5" transform="translate(264 1067.38) rotate(-89.58)"></rect>

                        <rect id="id_10998" data-name="id 10998" className="plot_block cls-1" x="718.32" y="314.6" width="31.5" height="35.7"></rect>
                        <rect id="id_10999" data-name="id 10999" className="plot_block cls-1" x="717" y="351" width="31.5" height="35.7"></rect>



                        <path id="id_10949" data-name="id 10949" className="plot_block cls-1" d="M239.2,287.1c-.1.5-1.7,41.7-1.7,41.7l-35.6-1.7.4-11.2,1.6-5.7,3.6-7.1,3.5-4.8,6.7-5.9,7.3-3.8,5.6-1.7,4.6-.6" transform="translate(5.92 -69)"></path>
                        <polyline id="id_10950" data-name="id 10950" className="plot_block cls-1" points="243.22 260.2 242.72 277.8 206.82 275.7 207.62 258.7"></polyline>
                        <polyline id="id_10951" data-name="id 10951" className="plot_block cls-1" points="207.02 276.2 242.52 277.9 241.52 301.7 211.32 302.1 206.02 297"></polyline>
                        <polyline className="plot_block cls-1" points="245.72 218 267.12 219.8 267.32 265.6 243.52 266 267.12 265.9 267.62 301.8 241.52 301.7"></polyline>
                        <polyline id="id_10954" data-name="id 10954" className="plot_block cls-1" points="267.32 219.7 288.22 221.8 288.72 265.5 267.32 265.6"></polyline>
                        <polyline id="id_10956" data-name="id 10956" className="plot_block cls-1" points="288.72 221.4 309.62 223.1 310.12 265.4 288.92 265.8"></polyline>
                        <polyline id="id_10958" data-name="id 10958" className="plot_block cls-1" points="310.02 222.9 331.12 224.8 331.62 265.1 310.12 265.6"></polyline>
                        <polyline id="id_10960" data-name="id 10960" className="plot_block cls-1" points="331.42 224.9 352.32 226.2 352.82 264.8 332.02 265.5"></polyline>
                        <polyline id="id_10962" data-name="id 10962" className="plot_block cls-1" points="352.62 226.4 373.82 227.2 374.62 265.1 352.82 264.8"></polyline>
                        <polyline id="id_10964" data-name="id 10964" className="plot_block cls-1" points="374.42 227.4 395.42 228.3 395.62 265 374.92 265"></polyline>
                        <polyline id="id_10966" data-name="id 10966" className="plot_block cls-1" points="395.82 227.9 416.82 228.9 417.52 264.8 395.82 264.6"></polyline>
                        <polyline id="id_10968" data-name="id 10968" className="plot_block cls-1" points="417.32 228.6 438.12 228.8 438.32 264.3 417.22 264.6"></polyline>
                        <polyline id="id_10970" data-name="id 10970" className="plot_block cls-1" points="438.72 228.8 459.82 229.1 460.02 264.6 439.02 264.6"></polyline>
                        <path id="id_10972" data-name="id 10972" className="plot_block cls-1" d="M453.8,297.6l21.4.1.4,35.3-21.6.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_10974" data-name="id 10974" className="plot_block cls-1" points="481.62 228.9 502.32 228.8 502.72 264 481.92 264"></polyline>
                        <polyline id="id_10976" data-name="id 10976" className="plot_block cls-1" points="501.92 228 523.92 228 524.12 263.6 502.72 264"></polyline>
                        <polyline id="id_10978" data-name="id 10978" className="plot_block cls-1" points="524.22 228.4 545.12 228 545.92 263.6 524.52 263.9"></polyline>
                        <polyline id="id_10980" data-name="id 10980" className="plot_block cls-1" points="545.92 228 566.92 228 567.32 263.3 545.92 263.6"></polyline>
                        <polyline id="id_10982" data-name="id 10982" className="plot_block cls-1" points="567.12 228.1 588.42 227.9 588.62 263.1 567.62 263.4"></polyline>
                        <polyline id="id_10984" data-name="id 10984" className="plot_block cls-1" points="588.72 227.5 609.72 227.8 609.82 263.2 588.62 263.1"></polyline>
                        <polyline id="id_10986" data-name="id 10986" className="plot_block cls-1" points="609.32 227.3 631.02 227.6 631.52 262.8 610.12 263"></polyline>
                        <polyline id="id_10988" data-name="id 10988" className="plot_block cls-1" points="631.32 227.4 652.02 227.2 652.72 262.6 631.52 262.8"></polyline>
                        <rect id="id_10990" data-name="id 10990" className="plot_block cls-1" x="652.62" y="227.5" width="21.4" height="35.1"></rect>
                        <rect id="id_10992" data-name="id 10992" className="plot_block cls-1" x="674.42" y="227.1" width="21.4" height="35.1"></rect>
                        <rect id="id_10994" data-name="id 10994" className="plot_block cls-1" x="695.52" y="226.8" width="21.4" height="35.4"></rect>

                        <rect id="id_10995" data-name="id 10995" className="plot_block cls-1" x="696.02" y="262.5" width="21" height="35.6"></rect>
                        <rect id="id_10997" data-name="id 10997" className="plot_block cls-1" x="718.02" y="262.5" width="31" height="35.6"></rect>

                        <rect id="id_10993" data-name="id 10993" className="plot_block cls-1" x="674.42" y="262.5" width="21.4" height="35.6"></rect>
                        <rect id="id_10991" data-name="id 10991" className="plot_block cls-1" x="652.72" y="262.5" width="21.4" height="35.6"></rect>
                        <rect id="id_10989" data-name="id 10989" className="plot_block cls-1" x="631.52" y="263" width="21.4" height="35.6"></rect>
                        <rect id="id_10987" data-name="id 10987" className="plot_block cls-1" x="610.12" y="263" width="21.4" height="35.6"></rect>
                        <rect id="id_10985" data-name="id 10985" className="plot_block cls-1" x="588.92" y="263.3" width="21.6" height="35.6"></rect>
                        <rect id="id_10983" data-name="id 10983" className="plot_block cls-1" x="567.22" y="263.3" width="21.6" height="35.6"></rect>
                        <rect id="id_10981" data-name="id 10981" className="plot_block cls-1" x="546.22" y="263.9" width="21.6" height="35.6"></rect>
                        <rect id="id_10979" data-name="id 10979" className="plot_block cls-1" x="524.42" y="263.9" width="21.6" height="35.6"></rect>
                        <rect id="id_10977" data-name="id 10977" className="plot_block cls-1" x="502.92" y="263.9" width="21.6" height="35.6"></rect>
                        <rect id="id_10975" data-name="id 10975" className="plot_block cls-1" x="481.52" y="263.9" width="21.6" height="35.9"></rect>
                        <rect id="id_10973" data-name="id 10973" className="plot_block cls-1" x="459.92" y="264.2" width="21.6" height="35.6"></rect>
                        <rect id="id_10971" data-name="id 10971" className="plot_block cls-1" x="438.72" y="264.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10969" data-name="id 10969" className="plot_block cls-1" x="417.42" y="264.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10967" data-name="id 10967" className="plot_block cls-1" x="395.82" y="264.9" width="21.6" height="35.6"></rect>
                        <rect id="id_10965" data-name="id 10965" className="plot_block cls-1" x="374.52" y="265" width="21.6" height="35.6"></rect>
                        <rect id="id_10963" data-name="id 10963" className="plot_block cls-1" x="353.12" y="265.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10961" data-name="id 10961" className="plot_block cls-1" x="331.62" y="265.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10959" data-name="id 10959" className="plot_block cls-1" x="310.02" y="265.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10957" data-name="id 10957" className="plot_block cls-1" x="288.52" y="265.5" width="21.6" height="35.6"></rect>
                        <rect id="id_10955" data-name="id 10955" className="plot_block cls-1" x="267.72" y="265.8" width="21.3" height="35.6"></rect>
                        <path id="id_10996" data-name="id 10996" className="plot_block cls-1" d="M710.8,295.2l5.2-.1s3.6.4,4,.5,4.9,1.3,5.1,1.4l4.5,2.1,3.1,2s2.1,2.2,2.4,2.4,2.2,2.8,2.2,2.8l2.4,3.9,2.2,5,.9,4.7.2,4.1-.2,6.9-31.6.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11171" data-name="id 11171" className="plot_block cls-1" points="809.32 296.5 809.52 328.9 772.92 329 772.22 302 777.72 296.7"></polyline>
                        <polyline id="id_11169" data-name="id 11169" className="plot_block cls-1" points="773.12 350.4 809.52 350.1 809.82 386 778.92 386 772.72 380.8"></polyline>
                        <rect id="id_11170" data-name="id 11170" className="plot_block cls-1" x="772.92" y="329.4" width="36.8" height="20.4"></rect>
                        <rect id="id_11166" data-name="id 11166" className="plot_block cls-1" x="809.42" y="296.8" width="43.2" height="53.1"></rect>
                        <rect id="id_11163" data-name="id 11163" className="plot_block cls-1" x="852.52" y="296.1" width="42.9" height="53.2"></rect>
                        <rect id="id_11160" data-name="id 11160" className="plot_block cls-1" x="895.62" y="295.8" width="42.9" height="53.5"></rect>
                        <path id="id_11157" data-name="id 11157" className="plot_block cls-1" d="M932,364.1l40.5.4.1,4.5H975l.4,49-42.9.3" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11154" data-name="id 11154" className="plot_block cls-1" points="983.12 295.5 982.82 300 980.92 300 981.32 349 1023.92 349 1023.92 295"></polyline>
                        <rect id="id_11151" data-name="id 11151" className="plot_block cls-1" x="1024.12" y="294.7" width="42.2" height="53.4"></rect>
                        <rect id="id_11148" data-name="id 11148" className="plot_block cls-1" x="1066.42" y="294.7" width="43.1" height="53"></rect>
                        <polyline id="id_11146" data-name="id 11146" className="plot_block cls-1" points="1109.52 293.8 1146.62 294 1151.92 298.9 1151.92 347 1109.52 347.7"></polyline>
                        <polyline id="id_11145" data-name="id 11145" className="plot_block cls-1" points="1130.92 348 1151.92 348 1152.72 377.6 1147.52 383.2 1131.22 382.9"></polyline>
                        <rect id="id_11147" data-name="id 11147" className="plot_block cls-1" x="1109.52" y="347.7" width="22" height="35.7"></rect>
                        <rect id="id_11149" data-name="id 11149" className="plot_block cls-1" x="1088.42" y="347.7" width="21.1" height="35.7"></rect>
                        <rect id="id_11150" data-name="id 11150" className="plot_block cls-1" x="1066.92" y="347.7" width="21.5" height="35.7"></rect>
                        <rect id="id_11152" data-name="id 11152" className="plot_block cls-1" x="1045.62" y="348" width="21.5" height="35.3"></rect>
                        <rect id="id_11153" data-name="id 11153" className="plot_block cls-1" x="1024.22" y="348.1" width="21.5" height="35.8"></rect>
                        <rect id="id_11155" data-name="id 11155" className="plot_block cls-1" x="1002.82" y="348.1" width="21.5" height="35.8"></rect>
                        <path id="id_11156" data-name="id 11156" className="plot_block cls-1" d="M976,418v31s1-.2,1.1.2.6.1.6.1l-.1,4,19.4-.1V418" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11158" data-name="id 11158" className="plot_block cls-1" points="981.02 348.5 981.52 379.6 979.52 380 979.22 384 959.92 384 959.92 349"></polyline>
                        <rect id="id_11159" data-name="id 11159" className="plot_block cls-1" x="938.52" y="349.6" width="21.4" height="34.8"></rect>
                        <rect id="id_11161" data-name="id 11161" className="plot_block cls-1" x="916.92" y="349.6" width="21.6" height="35.4"></rect>
                        <rect id="id_11162" data-name="id 11162" className="plot_block cls-1" x="895.52" y="349.8" width="21.4" height="35.4"></rect>
                        <rect id="id_11164" data-name="id 11164" className="plot_block cls-1" x="874.32" y="349.9" width="21.3" height="35.2"></rect>
                        <rect id="id_11165" data-name="id 11165" className="plot_block cls-1" x="852.72" y="350.1" width="21.7" height="35.1"></rect>
                        <rect id="id_11167" data-name="id 11167" className="plot_block cls-1" x="831.52" y="350.2" width="21.2" height="35.2"></rect>
                        <rect id="id_11168" data-name="id 11168" className="plot_block cls-1" x="810.12" y="350.4" width="21.4" height="35.2"></rect>
                        <path id="id_11098" data-name="id 11098" className="plot_block cls-1" d="M767.5,507.2s0,.5,1.7.7,24-.2,24-.2l-.4-35.5-20,.1-5.1,5.5" transform="translate(5.92 -69)"></path>
                        <rect id="id_11099" data-name="id 11099" className="plot_block cls-1" x="799.12" y="403.3" width="21.1" height="35.3"></rect>
                        <rect id="id_11100" data-name="id 11100" className="plot_block cls-1" x="820.52" y="403.1" width="21.1" height="35.5"></rect>
                        <rect id="id_11102" data-name="id 11102" className="plot_block cls-1" x="841.92" y="403.1" width="21.4" height="35.4"></rect>
                        <rect id="id_11104" data-name="id 11104" className="plot_block cls-1" x="863.62" y="402.5" width="21.4" height="35.9"></rect>
                        <rect id="id_11106" data-name="id 11106" className="plot_block cls-1" x="884.72" y="402.5" width="21.4" height="35.7"></rect>
                        <rect id="id_11108" data-name="id 11108" className="plot_block cls-1" x="906.22" y="402.5" width="21.4" height="35.7"></rect>
                        <rect id="id_11110" data-name="id 11110" className="plot_block cls-1" x="927.72" y="402" width="21.4" height="35.7"></rect>
                        <rect id="id_11112" data-name="id 11112" className="plot_block cls-1" x="948.92" y="402" width="21.4" height="35.7"></rect>
                        <rect id="id_11114" data-name="id 11114" className="plot_block cls-1" x="970.22" y="402" width="21.4" height="35.7"></rect>
                        <rect id="id_11116" data-name="id 11116" className="plot_block cls-1" x="992.22" y="401.8" width="21.4" height="35.7"></rect>
                        <rect id="id_11118" data-name="id 11118" className="plot_block cls-1" x="1013.32" y="401.4" width="21.4" height="35.7"></rect>
                        <rect id="id_11120" data-name="id 11120" className="plot_block cls-1" x="1034.52" y="401.2" width="21.4" height="35.7"></rect>
                        <rect id="id_11122" data-name="id 11122" className="plot_block cls-1" x="1056.12" y="400.9" width="21.4" height="35.7"></rect>
                        <rect id="id_11124" data-name="id 11124" className="plot_block cls-1" x="1077.32" y="400.9" width="21.4" height="35.7"></rect>
                        <rect id="id_11126" data-name="id 11126" className="plot_block cls-1" x="1098.82" y="400.6" width="21.4" height="35.7"></rect>
                        <rect id="id_11128" data-name="id 11128" className="plot_block cls-1" x="1120.12" y="400.6" width="21.4" height="35.7"></rect>
                        <rect id="id_11130" data-name="id 11130" className="plot_block cls-1" x="1141.82" y="400.6" width="21.4" height="35.7"></rect>
                        <rect id="id_11132" data-name="id 11132" className="plot_block cls-1" x="1163.32" y="400.3" width="21.4" height="36"></rect>
                        <rect id="id_11134" data-name="id 11134" className="plot_block cls-1" x="1184.52" y="400.1" width="21.4" height="36.2"></rect>
                        <rect id="id_11136" data-name="id 11136" className="plot_block cls-1" x="1205.92" y="399.7" width="21.4" height="36.2"></rect>
                        <rect id="id_11142" data-name="id 11142" className="plot_block cls-1" x="1270.32" y="399.1" width="21.4" height="36"></rect>
                        <polyline id="id_11140" data-name="id 11140" className="plot_block cls-1" points="1251.02 399.2 1250.72 403.7 1248.62 403.8 1248.92 435 1270.32 435.1 1270.02 399.2"></polyline>
                        <path id="id_11138" data-name="id 11138" className="plot_block cls-1" d="M1240.1,468.5c-.1.2.4,4.5.4,4.5h1.5l1,31-21.4.2-.2-35.5" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11144" data-name="id 11144" className="plot_block cls-1" points="1291.52 399 1307.42 398.8 1312.92 404.4 1312.92 434.6 1312.92 435 1291.52 434.6"></polyline>
                        <path id="id_11143" data-name="id 11143" className="plot_block cls-1" d="M1307.3,503.7l-1.2,7s-.4,4.1-.9,4.7-3.2,5.6-3.2,5.6l-3.9,5.4-3,3.4-6,4.6-5.8,2.9-4.1,1.4-4,.6s-7.2.2-7.6.2-2.8-.1-2.8-.1l-.4-35.2" transform="translate(5.92 -69)"></path>
                        <path id="id_11097" data-name="id 11097" className="plot_block cls-1" d="M797.67,507.75c.75.08,47.33-.33,47.33-.33l-.17,35.66s-11.16.34-11.58.34-6.42-.42-6.42-.42L820,542l-3.58-1.75-4.75-2.58-3.92-3.17-3.5-3.92-3.5-5.91L798.83,518l-.58-2.5Z" transform="translate(-23 -68)"></path>
                        <rect id="id_11141" data-name="id 11141" className="plot_block cls-1" x="1249.02" y="435.5" width="21.6" height="35.1"></rect>
                        <rect id="id_11139" data-name="id 11139" className="plot_block cls-1" x="1227.52" y="435.7" width="21.6" height="34.9"></rect>
                        <rect id="id_11137" data-name="id 11137" className="plot_block cls-1" x="1206.42" y="435.8" width="21.2" height="35.1"></rect>
                        <rect id="id_11135" data-name="id 11135" className="plot_block cls-1" x="1184.72" y="436.1" width="21.6" height="35.1"></rect>
                        <rect id="id_11133" data-name="id 11133" className="plot_block cls-1" x="1163.32" y="436.4" width="21.4" height="34.9"></rect>
                        <rect id="id_11131" data-name="id 11131" className="plot_block cls-1" x="1141.82" y="436.4" width="21.8" height="34.8"></rect>
                        <rect id="id_11129" data-name="id 11129" className="plot_block cls-1" x="1120.42" y="436.7" width="21.6" height="35"></rect>
                        <rect id="id_11127" data-name="id 11127" className="plot_block cls-1" x="1098.92" y="436.5" width="21.5" height="35.2"></rect>
                        <rect id="id_11125" data-name="id 11125" className="plot_block cls-1" x="1077.72" y="436.9" width="21.4" height="35"></rect>
                        <rect id="id_11123" data-name="id 11123" className="plot_block cls-1" x="1056.42" y="437.1" width="21.3" height="34.9"></rect>
                        <rect id="id_11121" data-name="id 11121" className="plot_block cls-1" x="1035.02" y="437.4" width="21.3" height="34.8"></rect>
                        <rect id="id_11119" data-name="id 11119" className="plot_block cls-1" x="1013.62" y="437.3" width="21.4" height="35"></rect>
                        <rect id="id_11117" data-name="id 11117" className="plot_block cls-1" x="992.02" y="437.4" width="22" height="35.2"></rect>
                        <rect id="id_11115" data-name="id 11115" className="plot_block cls-1" x="970.52" y="437.7" width="21.4" height="35"></rect>
                        <rect id="id_11113" data-name="id 11113" className="plot_block cls-1" x="948.92" y="438" width="21.8" height="34.9"></rect>
                        <rect id="id_11111" data-name="id 11111" className="plot_block cls-1" x="927.82" y="438" width="21.3" height="35.1"></rect>
                        <rect id="id_11109" data-name="id 11109" className="plot_block cls-1" x="906.32" y="438.2" width="21.5" height="35.1"></rect>
                        <rect id="id_11107" data-name="id 11107" className="plot_block cls-1" x="884.92" y="438.4" width="21.5" height="35"></rect>
                        <rect id="id_11105" data-name="id 11105" className="plot_block cls-1" x="863.82" y="438.6" width="21.1" height="34.9"></rect>
                        <rect id="id_11103" data-name="id 11103" className="plot_block cls-1" x="842.02" y="438.8" width="21.9" height="34.9"></rect>
                        <rect id="id_11101" data-name="id 11101" className="plot_block cls-1" x="820.82" y="439.1" width="21.7" height="35.3"></rect>
                        <path id="id_11177" data-name="id 11177" className="plot_block cls-1" d="M896.9,265.7l.3,81.6,242.4-1.4,1.4-.3,4.8-5.3-1.1-124.9s-8.4-6.9-8.5-6.9" transform="translate(5.92 -69)"></path>
                        <polygon className="plot_block cls-1" points="898.82 198 902.62 197.2 903.42 278.6 899.32 278.5 898.82 198"></polygon>
                        <polyline id="id_11176" data-name="id 11176" className="plot_block cls-1" points="898.32 198 885.92 201.2 885.92 225 898.52 225.5"></polyline>
                        <polyline id="id_11174" data-name="id 11174" className="plot_block cls-1" points="878.92 202.8 879.02 209.6 885.92 209.9 885.92 201"></polyline>
                        <polyline className="plot_block cls-1" points="878.22 203 878.82 217.1 885.42 217.1 885.72 217.4 885.92 225.3 870.22 225.2 870.12 205"></polyline>
                        <rect id="id_11175" data-name="id 11175" className="plot_block cls-1" x="878.62" y="209.7" width="7.2" height="7.1"></rect>
                        <polyline className="no_plot_block cls-1" points="869.82 204.9 870.22 225.2 898.82 225.6 898.72 250.4 870.62 250.5 870.72 278.8 777.52 279.2 772.32 274 772.12 267.3 772.22 254.9 773.12 248.6 774.92 243.2 777.92 236.9 783.72 229.3 790.22 224.1 796.72 220.5 803.12 218.2 807.02 217.4"></polyline>
                        <rect id="id_11173" data-name="id 11173" className="plot_block cls-1" x="870.92" y="250.7" width="28.2" height="27.8"></rect>
                        <polyline id="id_11172" data-name="id 11172" className="plot_block cls-1" points="814.72 225.8 823.42 231.7 830.22 260.7 825.12 269.1 797.82 275.5 789.32 270.1 786.82 259.9 784.12 258.4 782.92 255.8 782.82 253.4 784.32 251.2 784.72 250 782.32 240.7 787.82 232.1 814.12 226"></polyline>
                        <rect id="id_11188" data-name="id 11188" className="plot_block cls-1" x="1169.32" y="311.5" width="71.4" height="35.4"></rect>
                        <rect id="id_11189" data-name="id 11189" className="plot_block cls-1" x="1240.82" y="311" width="71.4" height="35.4"></rect>
                        <rect id="id_11182" data-name="id 11182" className="plot_block cls-1" x="1168.72" y="204.5" width="71.4" height="35.4"></rect>
                        <rect id="id_11183" data-name="id 11183" className="plot_block cls-1" x="1240.02" y="203.9" width="71.4" height="35.4"></rect>
                        <rect id="id_11180" data-name="id 11180" className="plot_block cls-1" x="1168.52" y="168.6" width="71.4" height="35.7"></rect>
                        <rect id="id_11181" data-name="id 11181" className="plot_block cls-1" x="1239.72" y="167.8" width="71.4" height="35.7"></rect>
                        <path id="id_11179" data-name="id 11179" className="plot_block cls-1" d="M1236.7,191.7l-1.4-6.8,31.4-7.4,3.9-.2,2.4-.3a81.66,81.66,0,0,1,8.4,1.6c2.7.8,4.7,1.4,4.9,1.4s2.9,1.4,2.9,1.4l3.3,2.6,3.4,2.7,3.7,4.4,2.4,4.4,2,6.3,1.1,5.5-.2,4.5.2,24.6-71.8.4-.2-44.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11178" data-name="id 11178" className="plot_block cls-1" points="1233.72 117.5 1235.72 124.3 1238.82 123.8 1239.32 167.9 1168.22 168.6 1168.02 138.5 1173.02 132"></polyline>
                        <polyline id="id_11185" data-name="id 11185" className="plot_block cls-1" points="1311.62 239.3 1311.72 272.2 1307.52 272.8 1307.32 274.8 1240.02 275.3 1239.92 239.9"></polyline>
                        <polyline id="id_11184" data-name="id 11184" className="plot_block cls-1" points="1240.12 239.5 1240.32 275.5 1173.62 275.9 1173.42 273.7 1168.92 273.6 1168.82 240.2"></polyline>
                        <polyline id="id_11187" data-name="id 11187" className="plot_block cls-1" points="1307.32 274.8 1307.62 276.5 1311.82 276.8 1312.22 310.4 1240.62 310.9 1240.32 275.5"></polyline>
                        <polyline id="id_11186" data-name="id 11186" className="plot_block cls-1" points="1240.02 275.7 1240.32 310.9 1169.22 311.4 1169.02 277.9 1173.62 278.2 1173.82 276.2"></polyline>
                        <polyline id="id_11195" data-name="id 11195" className="plot_block cls-1" points="1284.12 346.5 1284.32 381.8 1307.22 381.8 1312.52 376.4 1312.22 346.4"></polyline>
                        <path id="id_11190" data-name="id 11190" className="plot_block cls-1" d="M1192,416.4c0,.2.2,34.9.2,34.9l-23.1.4-5.5-5,.1-30" transform="translate(5.92 -69)"></path>
                        <rect id="id_11191" data-name="id 11191" className="plot_block cls-1" x="1198.32" y="347.2" width="21.5" height="35.1"></rect>
                        <rect id="id_11192" data-name="id 11192" className="plot_block cls-1" x="1220.12" y="346.9" width="20.6" height="35.4"></rect>
                        <rect id="id_11193" data-name="id 11193" className="plot_block cls-1" x="1241.32" y="346.8" width="20.9" height="35.3"></rect>
                        <rect id="id_11194" data-name="id 11194" className="plot_block cls-1" x="1262.72" y="346.5" width="21.2" height="35.7"></rect>
                        <rect id="id_11231" data-name="id 11231" className="plot_block cls-1" x="1471.32" y="166.8" width="41.9" height="71.7"></rect>
                        <rect id="id_11233" data-name="id 11233" className="plot_block cls-1" x="1435.22" y="167.2" width="35.8" height="71.4"></rect>
                        <polyline id="id_11235" data-name="id 11235" className="plot_block cls-1" points="1434.72 167.7 1399.52 167.8 1399.92 234.4 1402.42 234.5 1402.32 238.8 1435.62 238.6"></polyline>
                        <polyline id="id_11237" data-name="id 11237" className="plot_block cls-1" points="1363.72 168.1 1364.42 239.1 1397.72 239.6 1398.02 235 1399.92 235 1399.22 167.9"></polyline>
                        <polyline id="id_11239" data-name="id 11239" className="plot_block cls-1" points="1328.12 168.1 1363.32 167.8 1364.02 239 1334.12 239.2 1328.72 233.8"></polyline>
                        <polyline id="id_11238" data-name="id 11238" className="plot_block cls-1" points="1328.12 167.5 1328.12 138.8 1329.72 131.4 1332.72 123.6 1335.92 118.5 1342.02 111.9 1348.82 106.9 1356.02 103.8 1360.92 102.6 1362.92 102.2 1363.52 167.9 1328.52 168.2"></polyline>
                        <path id="id_11236" data-name="id 11236" className="plot_block cls-1" d="M1357.5,171.3s2.1-.6,2.3-.6,1.7.1,1.7.1l29.2-2,.5,4.1,1.8.4.5,63.5-35.5.1" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11234" data-name="id 11234" className="plot_block cls-1" points="1401.32 99.5 1401.42 103.7 1399.32 104.2 1399.92 167.6 1434.72 167.2 1434.32 97.5"></polyline>
                        <polyline id="id_11232" data-name="id 11232" className="plot_block cls-1" points="1434.62 97.4 1435.22 167.2 1470.72 167.1 1470.22 95.2"></polyline>
                        <polyline id="id_11230" data-name="id 11230" className="plot_block cls-1" points="1483.52 93.8 1493.72 98.7 1512.52 97.5 1513.02 166.8 1471.22 167.1 1470.42 95"></polyline>
                        <polyline id="id_11228" data-name="id 11228" className="plot_block cls-1" points="1512.82 97.5 1510.62 68.8 1593.02 63.3 1686.92 193.5 1626.32 237.2 1567.12 237.4 1567.32 216 1513.62 216.8"></polyline>
                        <rect id="id_11229" data-name="id 11229" className="plot_block cls-1" x="1513.82" y="216.2" width="53.4" height="21.8"></rect>
                        <polyline id="id_11227" data-name="id 11227" className="plot_block cls-1" points="1687.32 193.7 1708.32 222.2 1647.42 266.2 1626.72 237.5"></polyline>
                        <polyline id="id_11226" data-name="id 11226" className="plot_block cls-1" points="1708.42 222.7 1728.72 251.2 1668.32 294.8 1647.72 266.5"></polyline>
                        <polyline id="id_11225" data-name="id 11225" className="plot_block cls-1" points="1729.32 251.2 1749.82 280.2 1695.12 319.4 1693.52 317.1 1687.22 320.9 1668.72 294.8"></polyline>
                        <polyline id="id_11224" data-name="id 11224" className="plot_block cls-1" points="1750.22 280.4 1770.42 308.8 1710.32 352.8 1691.42 326.8 1697.02 322.5 1695.52 319.6"></polyline>
                        <polyline id="id_11223" data-name="id 11223" className="plot_block cls-1" points="1770.82 309.2 1791.72 337.6 1731.22 381.6 1710.62 353.3"></polyline>
                        <polyline id="id_11222" data-name="id 11222" className="plot_block cls-1" points="1791.92 338.2 1812.72 366.8 1752.62 410.4 1731.32 382.2"></polyline>
                        <polyline id="id_11221" data-name="id 11221" className="plot_block cls-1" points="1813.02 367.3 1858.32 429.9 1766.82 430.9 1752.12 410.7"></polyline>
                        <polyline id="id_11218" data-name="id 11218" className="plot_block cls-1" points="1766.92 431 1766.92 461 1772.22 466.2 1802.92 465.9 1801.92 431"></polyline>
                        <polyline id="id_11220" data-name="id 11220" className="plot_block cls-1" points="1838.32 430.4 1858.62 430.3 1884.12 465.3 1838.12 465.7"></polyline>
                        <rect id="id_11219" data-name="id 11219" className="plot_block cls-1" x="1802.72" y="430.6" width="35.5" height="35.9"></rect>
                        <rect id="id_11198" data-name="id 11198" className="plot_block cls-1" x="1365.62" y="362.9" width="35.9" height="71.1"></rect>
                        <rect id="id_11200" data-name="id 11200" className="plot_block cls-1" x="1401.72" y="362.4" width="35.1" height="71.3"></rect>
                        <rect id="id_11202" data-name="id 11202" className="plot_block cls-1" x="1436.92" y="362.3" width="36" height="71.6"></rect>
                        <rect id="id_11208" data-name="id 11208" className="plot_block cls-1" x="1544.32" y="361.8" width="35.7" height="71.1"></rect>
                        <rect id="id_11210" data-name="id 11210" className="plot_block cls-1" x="1580.12" y="360.7" width="35.4" height="72.2"></rect>
                        <rect id="id_11212" data-name="id 11212" className="plot_block cls-1" x="1615.92" y="360.8" width="35.1" height="71.2"></rect>
                        <polyline id="id_11216" data-name="id 11216" className="plot_block cls-1" points="1650.92 360.4 1688.92 360.1 1697.52 364.3 1719.72 395.2 1651.12 396"></polyline>
                        <polyline id="id_11214" data-name="id 11214" className="plot_block cls-1" points="1651.02 396.6 1651.22 431.9 1745.62 431.2 1719.72 395.2"></polyline>
                        <path id="id_11217" data-name="id 11217" className="plot_block cls-1" d="M1709.6,500.6s.2,35.2.4,35.2l27.9-.1,5.6-4.9.3-25.2-4.4-5.4" transform="translate(5.92 -69)"></path>
                        <path id="id_11211" data-name="id 11211" className="plot_block cls-1" d="M1645.1,501c-.2.6.6,35.3.6,35.3l-33.4.4.1-3.6-2-.5-.3-31.1" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11209" data-name="id 11209" className="plot_block cls-1" points="1580.02 432.6 1580.22 467.7 1613.82 467.6 1614.12 463.4 1615.82 463.4 1615.52 432.2"></polyline>
                        <polyline id="id_11197" data-name="id 11197" className="plot_block cls-1" points="1330.12 434.4 1331.92 444.2 1336.52 453.2 1338.72 456.4 1341.42 459.1 1345.12 462.2 1348.82 464.8 1353.02 466.8 1359.42 469 1364.52 469.7 1371.92 469.8 1402.02 469.4 1401.52 434"></polyline>
                        <path id="id_11196" data-name="id 11196" className="plot_block cls-1" d="M1323.8,437.1c.3-.1,5.4-5.2,5.4-5.2l30.1-.1.4,71.4-35.1.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11204" data-name="id 11204" className="plot_block cls-1" points="1504.52 361.6 1504.22 368.6 1508.12 368.7 1508.72 433.2 1473.12 433.2 1472.42 361.9"></polyline>
                        <polyline id="id_11206" data-name="id 11206" className="plot_block cls-1" points="1508.62 368.9 1511.92 368.8 1511.72 361.5 1543.62 361.7 1544.32 433.2 1508.72 433.2"></polyline>
                        <rect id="id_11199" data-name="id 11199" className="plot_block cls-1" x="1401.92" y="434.3" width="35.6" height="34.7"></rect>
                        <rect id="id_11201" data-name="id 11201" className="plot_block cls-1" x="1437.22" y="433.6" width="36" height="35.4"></rect>
                        <rect id="id_11203" data-name="id 11203" className="plot_block cls-1" x="1473.32" y="433.5" width="35.4" height="34.9"></rect>
                        <rect id="id_11205" data-name="id 11205" className="plot_block cls-1" x="1509.12" y="433" width="35.2" height="35.7"></rect>
                        <rect id="id_11207" data-name="id 11207" className="plot_block cls-1" x="1544.62" y="432.5" width="35.4" height="35.8"></rect>
                        <rect id="id_11215" data-name="id 11215" className="plot_block cls-1" x="1687.42" y="431.9" width="28.5" height="35"></rect>
                        <rect id="id_11213" data-name="id 11213" className="plot_block cls-1" x="1651.32" y="432.1" width="35.9" height="35"></rect>
                        <polyline id="id_11254" data-name="id 11254" className="plot_block cls-1" points="1578.92 308 1578.92 255 1604.02 254.4 1611.02 255.8 1614.72 257.7 1619.22 259.8 1623.52 263.1 1626.42 266.2 1628.42 270.1 1655.92 307.1"></polyline>
                        <polyline id="id_11256" data-name="id 11256" className="plot_block cls-1" points="1656.02 307.5 1671.52 328.3 1664.22 343.1 1614.92 343.3 1614.92 308"></polyline>
                        <polyline id="id_11241" data-name="id 11241" className="plot_block cls-1" points="1329.72 317.2 1357.62 317.1 1358.02 345.2 1334.72 345.7 1329.52 340.7"></polyline>
                        <polyline id="id_11240" data-name="id 11240" className="plot_block cls-1" points="1365.02 309.9 1358.22 317 1329.32 317.2 1328.92 261.7 1334.42 256.6 1364.42 256.5"></polyline>
                        <path id="id_11243" data-name="id 11243" className="plot_block cls-1" d="M1359.3,379.1s35.7-.9,35.5-.5.2,35.8.2,35.8-35.8.1-35.8-.2-.5-6.7-.5-6.7l-6.7-.2.1-21" transform="translate(5.92 -69)"></path>
                        <rect className="no_plot_block cls-1" x="1357.92" y="338.7" width="7.1" height="6.8"></rect>
                        <rect id="id_11245" data-name="id 11245" className="plot_block cls-1" x="1400.92" y="309.8" width="35.6" height="35.1"></rect>
                        <rect id="id_11247" data-name="id 11247" className="plot_block cls-1" x="1436.52" y="309.3" width="35.5" height="35.3"></rect>
                        <rect id="id_11249" data-name="id 11249" className="plot_block cls-1" x="1472.42" y="309.2" width="35.2" height="35.4"></rect>
                        <rect id="id_11251" data-name="id 11251" className="plot_block cls-1" x="1507.92" y="309" width="35.4" height="35.3"></rect>
                        <rect id="id_11253" data-name="id 11253" className="plot_block cls-1" x="1543.62" y="308.3" width="35.6" height="35.8"></rect>
                        <rect id="id_11255" data-name="id 11255" className="plot_block cls-1" x="1579.22" y="307.9" width="35.6" height="35.9"></rect>
                        <rect id="id_11252" data-name="id 11252" className="plot_block cls-1" x="1543.42" y="254.8" width="35.3" height="53.8"></rect>
                        <rect id="id_11250" data-name="id 11250" className="plot_block cls-1" x="1507.42" y="255.3" width="35.8" height="53.3"></rect>
                        <polyline id="id_11248" data-name="id 11248" className="plot_block cls-1" points="1473.82 255.4 1473.92 259.5 1471.52 259.8 1472.42 309.2 1507.72 308.7 1507.12 255.5"></polyline>
                        <polyline id="id_11246" data-name="id 11246" className="plot_block cls-1" points="1469.42 255.4 1469.52 259.8 1471.12 260 1471.82 309 1436.22 309.3 1435.42 256"></polyline>
                        <polyline id="id_11244" data-name="id 11244" className="plot_block cls-1" points="1435.72 255.9 1435.92 309.6 1400.72 309.3 1400.12 256.1"></polyline>
                        <rect id="id_11242" data-name="id 11242" className="plot_block cls-1" x="1364.62" y="256.7" width="35.9" height="53.1"></rect>
                        <path id="id_11257" data-name="id 11257" className="plot_block cls-1" d="M1481.4,73.2c.4.1,60.6-4,60.6-4l45.1,63-101.4,6.3" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11258" data-name="id 11258" className="plot_block cls-1" points="1491.92 70.1 1482.32 76.4 1446.82 78.9 1441.82 7.7 1487.02 4.5"></polyline>
                        <polyline id="id_11259" data-name="id 11259" className="plot_block cls-1" points="1406.62 10.2 1411.02 81.5 1446.82 78.9 1441.72 8"></polyline>
                        <polyline id="id_11260" data-name="id 11260" className="plot_block cls-1" points="1406.32 10.5 1370.72 12.8 1375.62 83.8 1411.42 81.5"></polyline>
                        <polyline id="id_11261" data-name="id 11261" className="plot_block cls-1" points="1369.92 12.4 1323.12 16.3 1326.52 69.9 1332.72 73.1 1335.62 74.7 1337.62 76.3 1342.72 79.2 1347.22 81.6 1353.12 83.9 1362.62 85 1365.92 85 1374.12 84.1 1375.62 83.8 1370.62 13.2"></polyline>
                        <polyline id="id_11262" data-name="id 11262" className="plot_block cls-1" points="1322.32 16.2 1268.82 20.2 1285.42 87.4 1293.12 83.2 1297.02 79.5 1299.72 77.2 1303.52 74.1 1309.32 71.2 1313.52 70.1 1318.72 69.1 1322.02 69.4 1326.22 70.3 1323.02 15.9"></polyline>
                        <path id="id_11263" data-name="id 11263" className="plot_block cls-1" d="M1279.2,157s-8.3,2.8-11,3.2-29,5.8-29,5.8l-6.5,1.5-16.4-69.1,36-9.2,11-.2" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11264" data-name="id 11264" className="plot_block cls-1" points="1221.62 29.3 1187.32 37.8 1204.12 106.6 1238.32 98.6"></polyline>
                        <polyline id="id_11265" data-name="id 11265" className="plot_block cls-1" points="1186.32 37.4 1152.62 45.8 1169.22 115.3 1204.12 106.6"></polyline>
                        <polyline id="id_11266" data-name="id 11266" className="plot_block cls-1" points="1151.92 45.8 1118.22 54.4 1134.32 123.7 1169.62 115.2"></polyline>
                        <polyline id="id_11267" data-name="id 11267" className="plot_block cls-1" points="1117.52 54.1 1083.42 62.5 1099.72 131.5 1134.12 123.8"></polyline>
                        <polyline id="id_11268" data-name="id 11268" className="plot_block cls-1" points="1083.02 62.9 1049.22 70.6 1048.22 70.8 1065.22 140.4 1099.72 132.2"></polyline>
                        <polyline id="id_11269" data-name="id 11269" className="plot_block cls-1" points="1013.72 79.4 1030.12 148.2 1064.82 140.1 1048.22 70.8"></polyline>
                        <polyline id="id_11270" data-name="id 11270" className="plot_block cls-1" points="1013.92 79.5 979.12 87.8 995.52 156.8 1030.62 148.2"></polyline>
                        <polyline id="id_11271" data-name="id 11271" className="plot_block cls-1" points="979.32 87.6 944.52 95.7 961.12 165.1 995.52 157"></polyline>
                        <polyline id="id_11272" data-name="id 11272" className="plot_block cls-1" points="944.02 96 909.92 104.2 926.42 173.2 961.12 165.1"></polyline>
                        <polyline id="id_11273" data-name="id 11273" className="plot_block cls-1" points="909.22 104.1 874.72 112.2 891.72 181.4 925.92 173.3"></polyline>
                        <polyline id="id_11274" data-name="id 11274" className="plot_block cls-1" points="874.22 112.5 869.32 113.9 840.02 120 857.02 189.8 891.32 181.5"></polyline>
                        <path id="id_11275" data-name="id 11275" className="plot_block cls-1" d="M833.8,188.9l-34.6,6.7s16.5,70.9,17.1,70.9,34.2-7.4,34.2-7.4" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11276" data-name="id 11276" className="plot_block cls-1" points="804.52 126.4 764.92 133 779.72 194.8 784.22 197.4 790.12 199.1 794.62 200.1 800.52 200.6 804.32 200.6 808.12 200.2 815.52 198.8 818.82 198.3 821.72 197.6"></polyline>
                        <polyline id="id_11277" data-name="id 11277" className="plot_block cls-1" points="764.32 132.9 729.62 137.6 735.12 200.6 735.62 200.7 737.12 199.2 740.62 196.2 746.12 191.8 751.62 189.3 756.72 188.1 761.82 187.9 765.82 188.3 770.02 189.8 775.52 192.4 779.32 194.6"></polyline>
                        <path id="id_11278" data-name="id 11278" className="plot_block cls-1" d="M722.9,206.4s-40,4.4-39.9,4.6,6.7,67.6,6.7,67.6l15.9-.2,10.1-1.5,10.4-4.7,3.6-2.4" transform="translate(5.92 -69)"></path>
                        <polyline id="id_11279" data-name="id 11279" className="plot_block cls-1" points="689.12 142.4 655.12 144.9 655.72 209.9 695.62 209.6"></polyline>
                        <polygon id="id_11280" data-name="id 11280" className="plot_block cls-1" points="655.62 209.9 619.72 209.9 619.72 147.1 655.32 145.1 655.62 209.9"></polygon>
                        <polygon id="id_11281" data-name="id 11281" className="plot_block cls-1" points="620.02 210.3 583.82 210.3 583.82 148.6 619.72 147.2 620.02 210.3"></polygon>
                        <rect id="id_11282" data-name="id 11282" className="plot_block cls-1" x="548.02" y="148.8" width="36.4" height="62.2"></rect>
                        <rect id="id_11283" data-name="id 11283" className="plot_block cls-1" x="512.32" y="148.9" width="36.2" height="62.2"></rect>
                        <polyline id="id_11284" data-name="id 11284" className="plot_block cls-1" points="512.32 148.9 476.92 147.7 477.12 211.3 512.32 211.1"></polyline>
                        <polyline id="id_11285" data-name="id 11285" className="plot_block cls-1" points="476.42 147.2 442.22 144.8 436.72 204.2 439.62 204.8 439.22 212 477.02 211.7"></polyline>
                        <polyline id="id_11286" data-name="id 11286" className="plot_block cls-1" points="441.42 144.5 405.72 141.9 400.22 210.8 432.32 211.7 432.62 204.8 436.52 204.6"></polyline>
                        <polyline id="id_11287" data-name="id 11287" className="plot_block cls-1" points="406.12 142.2 370.52 139.2 364.72 209.6 400.22 210.8"></polyline>
                        <polyline id="id_11288" data-name="id 11288" className="plot_block cls-1" points="371.12 138.8 335.02 136.5 329.12 207.1 364.72 209.9"></polyline>
                        <polyline id="id_11289" data-name="id 11289" className="plot_block cls-1" points="334.52 136.1 299.12 133.2 293.42 204.2 329.12 207.4"></polyline>
                        <polyline id="id_11290" data-name="id 11290" className="plot_block cls-1" points="293.42 204.8 257.52 201.4 263.42 130.6 299.02 133.4"></polyline>
                        <polygon id="id_10952" data-name="id 10952" className="plot_block cls-1" points="245.72 217.5 266.42 219.5 267.72 265.8 242.72 265.7 245.72 217.5"></polygon>
                        <polyline id="id_10953" data-name="id 10953" className="plot_block cls-1" points="267.12 266.5 267.02 301.2 241.52 301.7 243.42 266"></polyline>
                        <path id="id_11291" data-name="id 11291" className="plot_block cls-1" d="M256.8,199.3l-34.5-2.6-5.1,60.4,4.4,4.5c.2.2,2.8,2,2.8,2l5.6,3.2s4.9,1.6,5.5,1.6,4.1.8,4.1.8,2.1.2,2.3.2a6.21,6.21,0,0,1,1,.3l1.4.5,4.4.4,3.1-.1.2-.1" transform="translate(5.92 -69)"></path>
                        <path id="id_11292" data-name="id 11292" className="plot_block cls-1" d="M221.9,196.8l-35-2.7-4.8,56.8,6-2.5,2.6-.1,2.9-.3H198l3.3-.1,3.1.9,3,1.2,2.1,1.7,2.8,1.2s3.8,3.2,3.9,3.4.6,1,.6,1l.2.2" transform="translate(5.92 -69)"></path>
                        <path id="id_11293" data-name="id 11293" className="plot_block cls-1" d="M187,193.7s-108.1-9.4-108.1-8.6-4.4,45.6-4.4,45.6l23.6,27.7,59.9,4.8s8.2-.7,12.6-3.6a35.26,35.26,0,0,0,6.8-5.5l3.6-2.7,1-.6" transform="translate(5.92 -69)"></path>
                    </g>
                    <g id="Layer_1" data-name="Layer 1">
                        <polygon className="plot_block cls-2 class_10946" id="id_10946" points="68.02 417.6 56.22 430.5 53.52 459.2 73.52 480.7 99.22 480.3 117.72 454.9 68.02 417.6"></polygon>
                        <path className="plot_block cls-3" d="M1284.7,163.1" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M1284.7,163.1" transform="translate(5.92 -69)"></path>
                        <polyline className="plot_block cls-4" points="1330.52 76 1325.72 74 1320.42 73.1"></polyline>
                        <polyline className="plot_block cls-4" points="1282.92 94.5 1287.82 92.1 1292.42 89.2"></polyline>
                        <path className="plot_block cls-3" d="M1284.7,163.1" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M1284.7,163.1" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M1339.1,170.1" transform="translate(5.92 -69)"></path>
                        <line className="plot_block cls-4" x1="880.62" y1="194.9" x2="882.82" y2="194.3"></line>
                        <line className="plot_block cls-4" x1="1489.52" y1="78.4" x2="1490.52" y2="77.5"></line>
                        <polyline className="plot_block cls-4" points="1499.42 74.7 1496.22 74.5 1493.12 75.5 1490.52 77.5"></polyline>
                        <polyline className="plot_block cls-4" points="1494.52 93.4 1498.12 93.8 1501.72 93.2"></polyline>
                        <polyline className="plot_block cls-4" points="1505.92 88.9 1506.92 86.3 1507.12 83.7 1506.52 80.6 1505.32 78.2"></polyline>
                        <polygon className="plot_block cls-4" points="870.22 204.8 874.42 203.8 870.22 205.8 870.22 204.8"></polygon>
                        <path className="plot_block cls-4" d="M864.3,274.8l8.4-2.9-8.4,3.9Zm4.2-2-4.4,2.1,8.4-2.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M864.3,275.6l8.5,3.2-8.4,3.9Zm8.4-3.8-8.4,3.9,8.5,3.2Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M864.3,282.7l8.5,8-8.4,3.9Zm8.5-3.9-8.4,3.9,8.5,8Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-4" d="M872.9,293.1l-3.2,1.4h3.2Zm-8.5,1.5,8.5-1.5-3.2,1.4Zm8.5-3.9-8.4,3.9,8.5-1.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M801.3,286.9a.22.22,0,0,1-.2-.2c0-.1.1-.3.2-.3,18-3,36-6.6,53.5-10.7.1,0,.3.1.3.2s0,.3-.2.3c-17.5,4.1-35.5,7.7-53.6,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M766.4,327.7a.22.22,0,0,1-.2-.2,41,41,0,0,1,34.9-41c.1,0,.3.1.3.2s-.1.3-.2.3a40.44,40.44,0,0,0-34.5,40.5C766.6,327.5,766.5,327.7,766.4,327.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1447.3,67.3" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-7" d="M693.2,279.3H692a.5.5,0,0,1,0-1h1.2a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1305,208.9a.22.22,0,0,1-.2-.2,31.42,31.42,0,0,0-10.8-23.5,31,31,0,0,0-24.9-7.4c-.1,0-.3-.1-.3-.2s.1-.3.2-.3a31.65,31.65,0,0,1,25.2,7.5,32.11,32.11,0,0,1,11,23.9A.21.21,0,0,1,1305,208.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1255.4,180.1a.22.22,0,0,1-.2-.2c0-.1,0-.3.2-.3,4.4-1,8.9-1.9,13.3-2.5.1,0,.3.1.3.2s-.1.3-.2.3a126.09,126.09,0,0,0-13.4,2.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M799.7,543.4A31.48,31.48,0,0,1,768,512.2a.2.2,0,1,1,.4,0,30.88,30.88,0,0,0,31.2,30.7.22.22,0,0,1,.2.2C800,543.3,799.9,543.4,799.7,543.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1270.4,539.8a.2.2,0,0,1,0-.4,36.83,36.83,0,0,0,36.5-37.1.2.2,0,1,1,.4,0A37.13,37.13,0,0,1,1270.4,539.8Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M202.3,319.7h0c-.1,0-.3-.1-.2-.3a34.73,34.73,0,0,1,37.3-32.7c.1,0,.2.1.2.3s-.1.2-.3.2a34.21,34.21,0,0,0-36.7,32.3C202.6,319.6,202.5,319.7,202.3,319.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M743,322.6a.22.22,0,0,1-.2-.2,26.84,26.84,0,0,0-28.1-26.7.22.22,0,0,1-.2-.2c0-.1.1-.2.2-.3a27.51,27.51,0,0,1,28.7,27.3C743.2,322.5,743.1,322.6,743,322.6Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M692.4,296a.2.2,0,1,1,0-.4c7.5-.1,15.2-.3,22-.6h0a.22.22,0,0,1,.2.2c0,.1-.1.2-.2.3C707.6,295.7,699.9,295.9,692.4,296Z" transform="translate(5.92 -69)"></path>
                        <path d="M710.6,208.3c-.2,0-.5-.2-.5-.4a.46.46,0,0,1,.4-.5l13.1-1.3a.45.45,0,1,1,.1.9l-13.1,1.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M52.7,477.9h-.1a.37.37,0,0,1-.1-.3A120.35,120.35,0,0,0,63.8,353.2a.22.22,0,1,1,.4-.2A120.82,120.82,0,0,1,52.9,477.9C52.8,477.8,52.8,477.9,52.7,477.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1322.2,213a.22.22,0,0,1-.2-.2,42.11,42.11,0,0,1,39-42.3c.2,0,.2.1.3.2s-.1.2-.2.3a41.57,41.57,0,0,0-38.5,41.8C1322.4,212.9,1322.3,213,1322.2,213Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1357,153.9a41.13,41.13,0,0,1-26.5-9.4.21.21,0,1,1,.3-.3,40.8,40.8,0,0,0,28.5,9.2c.1,0,.2.1.3.2s-.1.2-.2.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1292.8,147.7c-.1,0-.1,0-.2-.1a.19.19,0,0,1,0-.3,27.64,27.64,0,0,1,38-2.8.21.21,0,0,1-.3.3,26,26,0,0,0-19.2-6.1,27.67,27.67,0,0,0-18.2,8.8C1293,147.7,1292.9,147.7,1292.8,147.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1267.7,160.4a.22.22,0,0,1-.2-.2c0-.1.1-.3.2-.3a40.19,40.19,0,0,0,24.9-13,.21.21,0,0,1,.3.3,40.17,40.17,0,0,1-25.2,13.2Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1251.6,163.6a.22.22,0,0,1-.2-.2c0-.1.1-.3.2-.3a160.29,160.29,0,0,1,16.3-3c.1,0,.3.1.3.2s-.1.3-.2.3c-4.9.6-10.2,1.6-16.4,3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M801.2,269.3a.22.22,0,0,1-.2-.2c0-.1.1-.3.2-.3,16.6-2.7,33.3-6.1,49.5-10.1.1,0,.3,0,.3.2s0,.3-.2.3c-16.3,4-33,7.4-49.6,10.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M794.7,269.9a40.85,40.85,0,0,1-24.2-7.8.22.22,0,0,1,.2-.4,40.19,40.19,0,0,0,30.3,7.2c.1,0,.3.1.3.2s-.1.3-.2.3A43.7,43.7,0,0,1,794.7,269.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M734.4,265.9c-.1,0-.1,0-.2-.1a.19.19,0,0,1,0-.3,27.36,27.36,0,0,1,36.4-3.5.22.22,0,0,1-.2.4,26.89,26.89,0,0,0-35.7,3.5C734.6,265.8,734.5,265.9,734.4,265.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M705.7,278.8a.2.2,0,0,1,0-.4A40.6,40.6,0,0,0,734,265.6a.21.21,0,0,1,.3.3,40.79,40.79,0,0,1-28.6,12.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M692,278.9a.2.2,0,1,1,0-.4c4.7,0,9.1-.1,13.7-.3.1,0,.2.1.3.2s-.1.2-.2.3C701.1,278.8,696.7,278.8,692,278.9Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M1361.9,539.1a37.3,37.3,0,0,1-37.6-37,.2.2,0,1,1,.4,0,36.83,36.83,0,0,0,37.1,36.5.22.22,0,0,1,.2.2C1362.1,539,1362,539.1,1361.9,539.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M710.6,208.3c-.2,0-.5-.2-.5-.4a.46.46,0,0,1,.4-.5l13.1-1.3a.45.45,0,1,1,.1.9l-13.1,1.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-8" d="M1685.5,396c-.1,0-.2,0-.2-.1l-4.1-5.8c0-.1-.1-.1,0-.2s0-.1.1-.2l5.8-4.1c.1-.1.3-.1.3.1l4.1,5.8c0,.1.1.1,0,.2s0,.1-.1.2l-5.8,4.1Zm-3.7-6,3.8,5.4,5.4-3.8-3.8-5.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M413.9,391.5h-4.2a.43.43,0,0,1-.4-.4v-4.4a.43.43,0,0,1,.4-.4h4.2a.43.43,0,0,1,.4.4v4.4C414.2,391.3,414.1,391.5,413.9,391.5Zm-3.9-.7h3.5v-3.7H410Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M743.8,422.1h-4.4a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4v4.2A.78.78,0,0,1,743.8,422.1Zm-4.1-.7h3.7v-3.5h-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M977.7,453.8h-4.2a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.2a.43.43,0,0,1,.4.4v4.2A.37.37,0,0,1,977.7,453.8Zm-3.8-.7h3.5v-3.5h-3.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1167.5,347.3h-4.4a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4v4.2A.37.37,0,0,1,1167.5,347.3Zm-4.1-.7h3.7v-3.5h-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1306.1,346.2h-4.4a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4v4.2A.43.43,0,0,1,1306.1,346.2Zm-4.1-.7h3.7V342H1302Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1244.9,473.2h-4.2a.43.43,0,0,1-.4-.4v-4.4a.43.43,0,0,1,.4-.4h4.2a.43.43,0,0,1,.4.4v4.4C1245.2,473.1,1245.1,473.2,1244.9,473.2Zm-3.9-.7h3.5v-3.7H1241Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1396.5,308.4h-4.4a.43.43,0,0,1-.4-.4v-4.4a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4V308C1396.8,308.2,1396.7,308.4,1396.5,308.4Zm-4.1-.7h3.7V304h-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1468,329.2h-4.4a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4v4.2C1468.3,329,1468.2,329.2,1468,329.2Zm-4.1-.7h3.7V325h-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1391.2,173.5c-.1,0-.2,0-.2-.1a.31.31,0,0,1-.1-.2l-.2-4.4c0-.2.1-.4.3-.4l4.4-.2a.37.37,0,0,1,.3.1.31.31,0,0,1,.1.2l.2,4.4c0,.2-.1.4-.3.4l-4.5.2Zm.1-4.5.2,3.7,3.7-.2-.2-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M1612.5,537.2h-4.4a.43.43,0,0,1-.4-.4v-4.2a.43.43,0,0,1,.4-.4h4.4a.43.43,0,0,1,.4.4v4.2A.43.43,0,0,1,1612.5,537.2Zm-4.1-.7h3.7V533h-3.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-8" d="M1505.7,438.2l-7.1.1a.22.22,0,0,1-.2-.2l-.1-7.3a.22.22,0,0,1,.2-.2l7.1-.1a.22.22,0,0,1,.2.2l.1,7.3C1506,438.1,1505.8,438.2,1505.7,438.2Zm-6.8-.5,6.6-.1-.1-6.8-6.6.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-8" d="M1229.9,193.8a.22.22,0,0,1-.2-.2l-1.9-7v-.2c0-.1.1-.1.2-.1l7-1.5c.1,0,.3.1.3.2l1.7,7v.2c0,.1-.1.1-.2.1l-6.9,1.5Zm-1.5-7.1,1.7,6.6,6.4-1.4-1.6-6.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M394.4,280.7h0a.54.54,0,0,1-.5-.5l5.7-69.2c0-.3.3-.5.5-.4s.5.3.4.5l-5.7,69.2C394.9,280.5,394.7,280.7,394.4,280.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-10" d="M197.5,424.5h-1.2a.2.2,0,0,1,0-.4h1.2a.22.22,0,0,1,.2.2C197.8,424.4,197.7,424.5,197.5,424.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-5" d="M185.4,316.8h0c-.1,0-.2-.1-.2-.3a34.73,34.73,0,0,0-32.1-36.4c-.1,0-.2-.1-.2-.3s.1-.2.3-.2a35.34,35.34,0,0,1,32.6,36.9C185.6,316.7,185.5,316.8,185.4,316.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M864.4,291.6a.47.47,0,0,1-.5-.5l-.1-17.3a.5.5,0,1,1,1,0l.1,17.3A.47.47,0,0,1,864.4,291.6Z" transform="translate(5.92 -69)"></path>
                        <polygon className="plot_block cls-4" points="878.72 217.1 886.02 217 878.82 220.3 878.72 217.1"></polygon>
                        <path className="plot_block cls-4" d="M872.9,294.5l7.2-3.3v3.2Zm0-5.2v5.2l7.2-3.3Z" transform="translate(5.92 -69)"></path>
                        <polygon className="plot_block cls-4" points="878.82 220.3 886.02 217 886.02 222.2 878.82 220.3"></polygon>
                        <path d="M880.1,286.5l-7.3.1a.47.47,0,0,1-.5-.5l-.1-7.3a.47.47,0,0,1,.5-.5l7.3-.1a.47.47,0,0,1,.5.5l.1,7.3A.54.54,0,0,1,880.1,286.5Zm-6.8-.9,6.3-.1-.1-6.3-6.3.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M824.4,330.3a.66.66,0,0,1-.5-.4l-6.4-29-8.4-5.4L797,298.4l-14.5,3.3-5.4,8.2,2.2,9.4c.1.3-.1.5-.4.6s-.5-.1-.6-.4l-2.3-9.7a.6.6,0,0,1,.1-.4l5.6-8.5c.1-.1.2-.2.3-.2l14.7-3.3,12.3-2.9a.6.6,0,0,1,.4.1l8.7,5.6c.1.1.2.2.2.3l6.5,29.2c.1.3-.1.5-.4.6Q824.55,330.15,824.4,330.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M781.5,329.4h0a5.65,5.65,0,0,1-4.7-4.3,5.14,5.14,0,0,1,2.2-6,.49.49,0,0,1,.7.1.62.62,0,0,1-.1.7,4.32,4.32,0,0,0-1.8,4.9,4.56,4.56,0,0,0,3.9,3.6c.3,0,.4.3.4.6C781.9,329.2,781.7,329.4,781.5,329.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M791.9,345a.37.37,0,0,1-.3-.1l-8.5-5.4c-.1-.1-.2-.2-.2-.3l-2.1-10.1c-.1-.3.1-.5.4-.6a.55.55,0,0,1,.6.4l2.1,9.9,8.2,5.2,27-6.2,5.1-8.3a.51.51,0,1,1,.9.5l-5.2,8.5c-.1.1-.2.2-.3.2L792,345Z" transform="translate(5.92 -69)"></path>
                        <path d="M860.4,348.5h-3.8a.5.5,0,1,1,0-1h3.8a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M893.3,348.2a.47.47,0,0,1-.5-.5l-.7-81a.5.5,0,0,1,1,0l.7,81A.54.54,0,0,1,893.3,348.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M880.1,286.5l-7.3.1a.5.5,0,0,1,0-1l7.3-.1a.47.47,0,0,1,.5.5A.54.54,0,0,1,880.1,286.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M892.8,294.8l-28.4.2a.47.47,0,0,1-.5-.5l-.2-20.8a.5.5,0,1,1,1,0l.2,20.3,27.9-.2a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M880.2,294.9a.47.47,0,0,1-.5-.5l-.2-24.4a.5.5,0,0,1,1,0l.2,24.4C880.6,294.7,880.4,294.9,880.2,294.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M893.3,348.2a.47.47,0,0,1-.5-.5l-.7-81a.5.5,0,0,1,1,0l.7,81A.54.54,0,0,1,893.3,348.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M897.7,348.2a.47.47,0,0,1-.5-.5l-.7-82a.5.5,0,1,1,1,0l.7,82A.47.47,0,0,1,897.7,348.2Z" transform="translate(5.92 -69)"></path>
                        <polygon className="plot_block cls-4" points="898.52 197.8 902.92 196.7 898.52 198.8 898.52 197.8"></polygon>
                        <path className="plot_block cls-4" d="M893.3,347.7l4.4-2.1v2Zm-.7-79.9.6,80,4.4-2.1Z" transform="translate(5.92 -69)"></path>
                        <polygon className="plot_block cls-4" points="898.52 198.8 902.92 196.7 903.62 276.9 898.52 198.8"></polygon>
                        <path className="plot_block cls-8" d="M433.5,281.2h0l-6.9-.1a.22.22,0,0,1-.2-.2l-.1-7.1a.22.22,0,0,1,.2-.2l7.3-.1c.1,0,.1,0,.2.1a.35.35,0,0,1,.1.2l-.3,7.3A.44.44,0,0,1,433.5,281.2Zm-6.6-.7,6.4.1.3-6.8-6.8.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M880.1,286.5l-7.3.1a.5.5,0,0,1,0-1l7.3-.1a.47.47,0,0,1,.5.5A.54.54,0,0,1,880.1,286.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-11" d="M872.8,286.3c-.1,0-.1,0-.2-.1s-.1-.1-.1-.2,0-.1.1-.2a.19.19,0,0,1,.3,0c.1,0,.1.1.1.2s0,.1-.1.2S872.9,286.3,872.8,286.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M864.4,295.1a.47.47,0,0,1-.5-.5l-.2-20.1-9.1,2.2c-.3.1-.5-.1-.6-.4s.1-.5.4-.6l9.7-2.3a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.2,20.8A.55.55,0,0,1,864.4,295.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M801.3,287.1a.66.66,0,0,1-.5-.4.55.55,0,0,1,.4-.6c18-3,36-6.6,53.5-10.7.3-.1.5.1.6.4s-.1.5-.4.6c-17.4,4.1-35.5,7.7-53.6,10.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M766.4,327.9a.47.47,0,0,1-.5-.5A41.28,41.28,0,0,1,801,286.1a.51.51,0,1,1,.2,1,40.22,40.22,0,0,0-34.3,40.3A.54.54,0,0,1,766.4,327.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M864.9,348.5l-92.9.7a.37.37,0,0,1-.3-.1l-5.5-5.2c-.1-.1-.1-.2-.2-.3l-.1-15.9a.5.5,0,0,1,1,0l.1,15.7,5.2,4.9,92.2-.7-.2-27.9a.47.47,0,0,1,.5-.5l27.9-.2-.2-24-27.9.2a.5.5,0,0,1,0-1l28.4-.2a.47.47,0,0,1,.5.5l.2,25a.47.47,0,0,1-.5.5l-27.9.2.2,27.9C865.3,348.2,865.1,348.5,864.9,348.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M146.4,433h0a.56.56,0,0,1-.5-.6,232.22,232.22,0,0,0,2.7-35.6.5.5,0,1,1,1,0,235.62,235.62,0,0,1-2.7,35.8A.46.46,0,0,1,146.4,433Z" transform="translate(5.92 -69)"></path>
                        <path d="M180.1,434.8h0a.47.47,0,0,1-.5-.5l1.3-35.4-31.8-1.3a.5.5,0,1,1,0-1l32.2,1.4a.47.47,0,0,1,.5.5l-1.3,35.9C180.6,434.6,180.3,434.8,180.1,434.8Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M146.4,432.5" transform="translate(5.92 -69)"></path>
                        <path d="M149.2,397.5h0a.47.47,0,0,1-.5-.5,238.4,238.4,0,0,0-2.7-35.8c0-.3.1-.5.4-.5s.5.1.5.4a238.72,238.72,0,0,1,2.7,35.9A.46.46,0,0,1,149.2,397.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M146.3,361.2" transform="translate(5.92 -69)"></path>
                        <path d="M181.4,398.9h0l-32.3-1.4a.5.5,0,0,1,0-1l31.8,1.3,1.7-35a.54.54,0,0,1,.5-.5.47.47,0,0,1,.5.5l-1.7,35.5C181.9,398.7,181.7,398.9,181.4,398.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M235.6,371.4l-30.2.2a1,1,0,0,1-.3-.1l-5.1-5.2c-.1-.1-.1-.2-.1-.4l.8-21c0-.1.1-.3.2-.3a.37.37,0,0,1,.3-.1l35.7,1.7c.1,0,.2.1.3.2a.37.37,0,0,1,.1.3l-1.2,24.2A.47.47,0,0,1,235.6,371.4Zm-30-.7,29.6-.2,1.2-23.2-34.7-1.7-.8,20.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M236.8,347.3h0l-35.7-1.7a.47.47,0,0,1-.5-.5l.7-17.7c0-.1.1-.3.2-.3a.37.37,0,0,1,.3-.1l35.9,1.5c.1,0,.3.1.3.2s.1.2.1.4l-.4,5.8-.5,12.1c0,.1-.1.2-.2.3C237,347.2,236.9,347.3,236.8,347.3Zm-35.2-2.7,34.7,1.7.5-11.6.3-5.4-34.9-1.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M231.7,459.9l-30.4.2a1,1,0,0,1-.3-.1l-5.1-5.2c-.1-.1-.1-.2-.1-.4l.5-14.5c0-.1.1-.3.2-.3a.37.37,0,0,1,.3-.1l35.9,1.5c.1,0,.2.1.3.2s.1.2.1.4l-.9,17.9A.66.66,0,0,1,231.7,459.9Zm-30.3-.7,29.8-.2.8-17-34.9-1.5-.5,13.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M232.5,442h0l-35.9-1.5c-.1,0-.2-.1-.3-.2a.37.37,0,0,1-.1-.3l.9-17.7a.47.47,0,0,1,.5-.5l35.9,1.5c.1,0,.2.1.3.2a.37.37,0,0,1,.1.3l-.9,17.7A.47.47,0,0,1,232.5,442Zm-35.3-2.5,34.9,1.5.8-16.8L198,422.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M233.4,424.3h0l-35.9-1.5c-.1,0-.2-.1-.3-.2s-.1-.2-.1-.4l.9-17.9a.47.47,0,0,1,.5-.5l35.9,1.7c.1,0,.2.1.3.2a.37.37,0,0,1,.1.3l-.9,17.7C233.9,424.1,233.7,424.3,233.4,424.3ZM198,421.8l34.9,1.5.8-16.8-34.9-1.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M234.3,406.5h0l-35.9-1.7c-.1,0-.3-.1-.3-.2s-.1-.2-.1-.4l.7-10.5c0-.1.1-.2.1-.3l5.4-5.5a.37.37,0,0,1,.3-.1l30.4-.2c.1,0,.3.1.4.1s.1.2.1.4l-.7,17.9c0,.1-.1.3-.2.3C234.5,406.5,234.4,406.5,234.3,406.5Zm-35.4-2.7,34.9,1.7.6-16.9-29.7.2-5.1,5.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1306.7,415.6l-71.7.6a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l71.7-.6a.47.47,0,0,1,.5.5l.3,35.7C1307.1,415.4,1306.9,415.6,1306.7,415.6Zm-71.3-.4,70.8-.6-.3-34.7-70.8.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1234.9,416.2l-71.3.6a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l71.3-.6a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1234.9,416.2Zm-70.8-.4,70.4-.6-.3-34.7-70.4.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1234.1,309l-71.3.6a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l71.3-.6a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1234.1,309Zm-70.9-.4,70.4-.6-.3-34.5-70.4.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1305.8,308.5l-71.7.6a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l71.7-.6a.47.47,0,0,1,.5.5l.3,35.5C1306.3,308.2,1306.1,308.4,1305.8,308.5Zm-71.2-.4,70.8-.6L1305,273l-70.8.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1305.5,273l-71.7.6a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l71.7-.6a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1305.5,273Zm-71.2-.4,70.8-.6-.3-34.9-70.8.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1233.8,273.6l-71.3.6a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l71.3-.6a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,1233.8,273.6Zm-70.9-.4,70.4-.6-.3-34.9-70.4.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1395.8,503.6l-35.9.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.6,71.5A.47.47,0,0,1,1395.8,503.6Zm-35.4-.7,34.9-.3-.6-70.6-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1431.3,503.3l-35.5.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.6,71.5A.47.47,0,0,1,1431.3,503.3Zm-35-.7,34.5-.3-.6-70.6-34.5.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1574.1,502.1l-35.7.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.6,71.5A.47.47,0,0,1,1574.1,502.1Zm-35.2-.6,34.7-.3-.6-70.6-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1467.1,503l-35.9.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.6,71.5A.47.47,0,0,1,1467.1,503Zm-35.3-.7,34.9-.3-.6-70.6-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1645.5,501.6l-35.5.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.6,71.5C1645.9,501.3,1645.7,501.6,1645.5,501.6Zm-35-.7,34.5-.3-.6-70.6-34.5.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1610,501.9l-35.9.3a.47.47,0,0,1-.5-.5l-.6-71.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.6,71.5A.54.54,0,0,1,1610,501.9Zm-35.4-.7,34.9-.3-.6-70.6-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1609.3,413.2l-35.9.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1609.3,413.2Zm-35.4-.7,34.9-.3-.3-34.7-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1573.4,413.5l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1573.4,413.5Zm-35.2-.7,34.7-.3-.3-34.7-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1537.8,413.8l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.7C1538.2,413.6,1538,413.8,1537.8,413.8Zm-35.2-.7,34.7-.3-.3-34.7-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1466.4,414.4l-35.9.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,1466.4,414.4Zm-35.4-.7,34.9-.3-.3-34.7-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1502.1,414.1l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,1502.1,414.1Zm-35.2-.7,34.7-.3-.3-34.7-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1430.6,414.6l-35.5.3a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,1430.6,414.6Zm-35-.6,34.5-.3-.3-34.7-34.5.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1573.1,377.8l-35.7.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.4,53.6A.47.47,0,0,1,1573.1,377.8Zm-35.2-.7,34.7-.3-.4-52.6-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1537.5,378.1l-35.7.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.4,53.6A.54.54,0,0,1,1537.5,378.1Zm-35.2-.7,34.7-.3-.4-52.6-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1430.3,379l-35.5.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.4,53.6A.47.47,0,0,1,1430.3,379Zm-35-.7,34.5-.3-.4-52.6-34.5.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1465.6,308l-35.9.3a.47.47,0,0,1-.5-.5l-.6-71.3a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.6,71.3A.54.54,0,0,1,1465.6,308Zm-35.4-.7,34.9-.3-.6-70.4-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1507.9,307.6l-42.3.3a.47.47,0,0,1-.5-.5l-.6-71.3a.47.47,0,0,1,.5-.5l42.3-.3a.47.47,0,0,1,.5.5l.6,71.3A.47.47,0,0,1,1507.9,307.6Zm-41.8-.6,41.3-.3-.6-70.4-41.3.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1358.4,308.8l-30.2.2a1,1,0,0,1-.3-.1l-5.3-5.4a.37.37,0,0,1-.1-.3l-.5-65.9a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.6,71.3C1358.9,308.6,1358.7,308.8,1358.4,308.8Zm-30-.7,29.5-.2-.6-70.4-34.5.3.5,65.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1507.3,236.3l-42.3.3a.47.47,0,0,1-.5-.5l-.6-72.3a.46.46,0,0,1,.4-.5l13.5-1.1h.3l10,4.9,18.6-1.4a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.6,69.5Q1507.75,236.3,1507.3,236.3Zm-41.8-.6,41.3-.3-.6-68.5-18.2,1.3h-.2l-10-4.9-12.9,1.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1469.7,164" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1470.2,236.3" transform="translate(5.92 -69)"></path>
                        <path d="M1429.1,236.9a.47.47,0,0,1-.5-.5l-.6-70.1a.46.46,0,0,1,.4-.5l35.8-2.5a.45.45,0,1,1,.1.9l-35.4,2.5.6,69.7C1429.6,236.7,1429.4,236.9,1429.1,236.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1561.5,307.2l-53.6.4a.47.47,0,0,1-.5-.5l-.2-21.4a.47.47,0,0,1,.5-.5l53.6-.4a.47.47,0,0,1,.5.5l.2,21.4A.47.47,0,0,1,1561.5,307.2Zm-53.1-.5,52.6-.4-.2-20.4-52.6.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1662.5,364.6a.52.52,0,0,1-.4-.2l-21-28.8c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l60.9-44c.1-.1.2-.1.4-.1s.2.1.3.2l20.8,28.8a.49.49,0,0,1-.1.7l-60.7,44A.37.37,0,0,1,1662.5,364.6Zm-20.3-29.2,20.4,28.1,59.9-43.4L1702.3,292Z" transform="translate(5.92 -69)"></path>
                        <path d="M359,279.4h0l-35.9-2.5c-.1,0-.2-.1-.3-.2s-.1-.2-.1-.4l5.9-71c0-.3.3-.5.5-.4l35.7,2.7c.1,0,.2.1.3.2s.1.2.1.4L359.5,279A.66.66,0,0,1,359,279.4Zm-35.4-3.5,34.9,2.5,5.6-69.8-34.7-2.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M329,205.9h0L293.3,203c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l35.7,2.9c.3,0,.5.3.4.5A.66.66,0,0,1,329,205.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M323.1,276.9h0l-3.9-.2-31.7-2.6c-.3,0-.5-.3-.4-.5l5.6-70.7L257.5,200c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l35.7,2.9c.3,0,.5.3.4.5l-5.6,70.7,31.2,2.5,3.8.2a.47.47,0,0,1,.5.5A.64.64,0,0,1,323.1,276.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M287.6,274.1h0l-35.7-2.9c-.3,0-.5-.3-.4-.5s.2-.5.5-.4l35.7,2.9c.3,0,.5.3.4.5A.46.46,0,0,1,287.6,274.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M293.3,202.5" transform="translate(5.92 -69)"></path>
                        <path d="M1128.8,193.4a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-16.7-69.6c-.1-.3.1-.5.4-.6l23.7-5.8,10.9-2.5c.3-.1.5.1.6.4l16.9,69.4c.1.3-.1.5-.4.6l-34.8,8.5Zm-16.1-69.7,16.5,68.6L1163,184l-16.7-68.4-10.4,2.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1112.1,123.3" transform="translate(5.92 -69)"></path>
                        <path d="M1094,201.5a.51.51,0,0,1-.5-.4L1077,131.7c-.1-.3.1-.5.4-.6l34.6-8.3c.3-.1.5.1.6.4s-.1.5-.4.6l-34.1,8.2,16.3,68.4,34.3-8c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1077.5,131.7" transform="translate(5.92 -69)"></path>
                        <path d="M1059.2,209.9a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-16.5-69.6a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l34.8-8.1c.3-.1.5.1.6.4s-.1.5-.4.6l-34.3,8,16.3,68.6,34.3-8.2c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1042.7,139.8" transform="translate(5.92 -69)"></path>
                        <path d="M1024.6,218a.51.51,0,0,1-.5-.4l-16.7-69.2c-.1-.3.1-.5.4-.6l34.8-8.5c.3-.1.5.1.6.4s-.1.5-.4.6l-34.3,8.4L1025,217l34.1-8c.3-.1.5.1.6.4s-.1.5-.4.6l-34.6,8.1A.1.1,0,0,0,1024.6,218Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1007.9,148.4" transform="translate(5.92 -69)"></path>
                        <path d="M989.8,226.6a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-16.5-69.4c-.1-.3.1-.5.4-.6l34.6-8.3c.3-.1.5.1.6.4s-.1.5-.4.6l-34.1,8.2,16.3,68.4,34.3-8.4c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M973.3,156.7" transform="translate(5.92 -69)"></path>
                        <path d="M955.2,234.9a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3L938,164.9a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l34.8-8.1c.3-.1.5.1.6.4s-.1.5-.4.6l-34.3,8,16.5,68.6,34.1-8.2c.3-.1.5.1.6.4s-.1.5-.4.6l-34.6,8.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M938.5,164.8" transform="translate(5.92 -69)"></path>
                        <path d="M920.4,243a.51.51,0,0,1-.5-.4l-16.7-69.4a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l34.8-8.3c.3-.1.5.1.6.4s-.1.5-.4.6l-34.3,8.2,16.5,68.4,34.3-8c.3-.1.5.1.6.4s-.1.5-.4.6L920.5,243Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M903.8,173.2" transform="translate(5.92 -69)"></path>
                        <path d="M885.8,251.4a.51.51,0,0,1-.5-.4l-16.9-69.6a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l34.8-8.1c.3-.1.5.1.6.4s-.1.5-.4.6l-34.3,8,16.7,68.6,34.1-8.2c.3-.1.5.1.6.4s-.1.5-.4.6l-34.6,8.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M863.7,183.5a.55.55,0,0,1-.5-.3.49.49,0,0,1,.3-.6l5.2-1.7a.47.47,0,0,1,.3.9l-5.2,1.7C863.8,183.4,863.8,183.5,863.7,183.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M851.1,259.7a.51.51,0,0,1-.5-.4L833.7,189a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l29.6-5.9c.3-.1.5.1.6.4s-.1.5-.4.6l-29.1,5.8,16.7,69.4,34.3-8.2c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1405.5,151.3a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-4.8-71.3a.46.46,0,0,1,.4-.5l35.4-2.5a.6.6,0,0,1,.4.1c.1.1.2.2.2.3l5,71.1a.46.46,0,0,1-.4.5l-35.7,2.7ZM1401.2,80l4.7,70.3,34.7-2.6-4.9-70.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M138.2,468.3H138a.45.45,0,0,1-.3-.6,253.5,253.5,0,0,0,8.4-35.3c0-.3.3-.4.6-.4s.4.3.4.6a226.57,226.57,0,0,1-8.4,35.4A.46.46,0,0,1,138.2,468.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M134.5,549.2l-41.5.3a.5.5,0,0,1,0-1l41-.3-.3-34.5-15.4.1a.5.5,0,1,1,0-1l15.9-.1a.47.47,0,0,1,.5.5l.3,35.5C134.9,548.9,134.7,549.2,134.5,549.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1574.4,537.6l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1574.4,537.6Zm-35.2-.7,34.7-.3-.3-34.5-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1538.8,537.9l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.5C1539.2,537.7,1539,537.9,1538.8,537.9Zm-35.2-.7,34.7-.3-.3-34.5-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1503.1,538.2l-35.7.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.7-.3a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1503.1,538.2Zm-35.2-.7,34.7-.3-.3-34.5-34.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1467.4,538.5l-35.9.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1467.4,538.5Zm-35.4-.7,34.9-.3-.3-34.5-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1431.6,538.8l-35.5.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.3,35.5C1432.1,538.5,1431.8,538.7,1431.6,538.8Zm-35-.7,34.5-.3-.3-34.5-34.5.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1352.2,415.3l-23.2.2a1,1,0,0,1-.3-.1l-5.3-5.4a.37.37,0,0,1-.1-.3l-.2-23.2a.47.47,0,0,1,.5-.5l28.4-.2a.47.47,0,0,1,.5.5l.2,28.6A.55.55,0,0,1,1352.2,415.3Zm-23-.8,22.5-.2-.2-27.6-27.4.2.2,22.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M649.7,279.7l-35.5.3a.47.47,0,0,1-.5-.5l-.5-63.5a.47.47,0,0,1,.5-.5l35.4-2.1a.6.6,0,0,1,.4.1c.1.1.2.2.2.3l.5,65.3A.56.56,0,0,1,649.7,279.7Zm-35-.7,34.5-.3-.5-64.3-34.5,2Z" transform="translate(5.92 -69)"></path>
                        <path d="M577.9,217.8a.5.5,0,0,1,0-1l35.9-1.3a.5.5,0,0,1,0,1l-35.9,1.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M614.3,280l-35.9.3a.47.47,0,0,1-.5-.5l-.5-62.5a.5.5,0,1,1,1,0l.5,62,35.4-.3a.47.47,0,0,1,.5.5A.54.54,0,0,1,614.3,280Z" transform="translate(5.92 -69)"></path>
                        <path d="M542.2,218.3a.5.5,0,0,1,0-1l35.7-.5h0a.5.5,0,0,1,0,1l-35.7.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M578.4,280.2l-35.7.3a.47.47,0,0,1-.5-.5l-.5-62.3a.5.5,0,1,1,1,0l.5,61.8,35.2-.3a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M542.2,218.3h0l-35.7-.1a.5.5,0,0,1,0-1l35.7.1a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M542.7,280.5l-35.7.3a.47.47,0,0,1-.5-.5l-.5-62.7a.5.5,0,0,1,1,0l.5,62.2,35.2-.3a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M506.6,218.2h0l-35.7-1.1a.5.5,0,0,1,0-1l35.7,1.1a.47.47,0,0,1,.5.5C507,217.9,506.8,218.2,506.6,218.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M507.1,280.8l-35.7.3a.47.47,0,0,1-.5-.5l-.5-64.1a.5.5,0,0,1,1,0l.5,63.6,35.2-.3a.47.47,0,0,1,.5.5A.54.54,0,0,1,507.1,280.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M130.8,488.5h-.2c-.2-.1-.4-.4-.3-.6,3.1-7.5,5.5-14,7.5-20a.47.47,0,0,1,.9.3c-1.9,6-4.4,12.6-7.5,20A.54.54,0,0,1,130.8,488.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M118.3,513.6c-.1,0-.2,0-.2-.1a.57.57,0,0,1-.2-.7c4.3-7.6,8.5-16,12.5-25,.1-.2.4-.4.6-.2s.4.4.2.6a279.53,279.53,0,0,1-12.5,25.1A.54.54,0,0,1,118.3,513.6Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M176.3,512.9" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M119.4,505.1" transform="translate(5.92 -69)"></path>
                        <path d="M1361.9,539.3a37.44,37.44,0,0,1-37.8-35.8.5.5,0,0,1,1,0,36.61,36.61,0,0,0,36.9,34.9.47.47,0,0,1,.5.5C1362.3,539.1,1362.1,539.3,1361.9,539.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1396.1,539l-34.5.3a.5.5,0,0,1,0-1l34-.3-.3-34.5-70.8.6a.5.5,0,0,1,0-1l71.3-.6a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1396.1,539Z" transform="translate(5.92 -69)"></path>
                        <path d="M1357.8,237.5a.47.47,0,0,1-.5-.5l-.5-65.9a.5.5,0,1,1,1,0l.5,65.9A.47.47,0,0,1,1357.8,237.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1322.4,237.8a.47.47,0,0,1-.5-.5l-.2-24.6a.5.5,0,0,1,1,0l.2,24.6C1322.8,237.6,1322.6,237.8,1322.4,237.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M1322.2,213.2a.47.47,0,0,1-.5-.5,42.06,42.06,0,0,1,35.7-42.1.51.51,0,1,1,.2,1,41,41,0,0,0-34.9,41.1C1322.6,213,1322.4,213.2,1322.2,213.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1394.8,379.3l-35.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.4,53.6A.54.54,0,0,1,1394.8,379.3Zm-35.4-.7,34.9-.3-.4-52.6-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1352,386.7l-28.4.2a.47.47,0,0,1-.5-.5l-.4-55.4c0-.1,0-.3.1-.3l5.2-5.3a.37.37,0,0,1,.3-.1l30.2-.2a.47.47,0,0,1,.5.5l.4,53.6c0,.1,0,.3-.1.3l-7,7.1C1352.2,386.6,1352.1,386.7,1352,386.7Zm-28-.8,27.7-.2,6.7-6.8L1358,326l-29.5.2-4.9,5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1486.2,139.8a.47.47,0,0,1-.5-.5l-4.6-65.6a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2L1542,69a.52.52,0,0,1,.4.2l45.2,62.9a.45.45,0,0,1,0,.5c-.1.2-.2.3-.4.3l-82.6,5.7-18.4,1.2Zm-4.1-65.7,4.5,64.7,18-1.1,81.7-5.6-44.6-62Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1481.6,73.6" transform="translate(5.92 -69)"></path>
                        <path d="M1441.1,148.6a.45.45,0,1,1-.1-.9l35.3-2.5,9.5-6.3a.5.5,0,0,1,.6.8l-9.6,6.3c-.1,0-.1.1-.2.1l-35.5,2.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1436.1,77.5a.45.45,0,1,1-.1-.9l45.5-3.4a.45.45,0,1,1,.1.9l-45.5,3.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1324.5,502.7a.47.47,0,0,1-.5-.5l-.5-64.9c0-.1,0-.3.1-.3l5.2-5.3a.49.49,0,0,1,.7.7l-5.1,5.1.5,64.7C1325,502.5,1324.8,502.7,1324.5,502.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M1324.5,503.9a.47.47,0,0,1-.5-.5V502a.5.5,0,0,1,1,0v1.4A.47.47,0,0,1,1324.5,503.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1360,503.9l-35.5.3a.5.5,0,0,1,0-1l35-.3-.6-70.6-29.7.2a.5.5,0,0,1,0-1l30.2-.2a.47.47,0,0,1,.5.5l.6,71.5C1360.4,503.6,1360.2,503.9,1360,503.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M729.6,270.4a.76.76,0,0,1-.4-.2.49.49,0,0,1,.1-.7,36.05,36.05,0,0,0,4.5-4.2.49.49,0,1,1,.7.7,38.7,38.7,0,0,1-4.7,4.3A.35.35,0,0,1,729.6,270.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M734.4,266.1a.37.37,0,0,1-.3-.1.48.48,0,0,1,0-.7,27.76,27.76,0,0,1,36.7-3.6.5.5,0,0,1-.6.8,26.72,26.72,0,0,0-35.4,3.5C734.7,266,734.6,266.1,734.4,266.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M774.1,264.6h-.2a25.64,25.64,0,0,1-3.5-2.2.5.5,0,0,1,.6-.8,20.88,20.88,0,0,0,3.4,2.1.48.48,0,0,1,.2.6A.55.55,0,0,1,774.1,264.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M729.6,270.4c-.2,0-.5-.2-.5-.4l-5.9-63.4a.46.46,0,0,1,.4-.5l35.2-4.5c.2,0,.5.1.5.4l15,62.3c.1.3-.1.5-.4.6s-.5-.1-.6-.4l-14.9-61.9L724.1,207l5.9,63c.1.1-.1.3-.4.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M649.7,279.2" transform="translate(5.92 -69)"></path>
                        <path d="M689.8,279.3l-40.1.3a.5.5,0,0,1,0-1l39.6-.3-6.5-66.8-33.6,2.9a.45.45,0,1,1-.1-.9l34-2.9a.46.46,0,0,1,.5.4l6.6,67.8a.6.6,0,0,1-.1.4C690.1,279.3,690,279.3,689.8,279.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M692,279.1a.5.5,0,0,1,0-1c4.7,0,9.1-.1,13.7-.3a.5.5,0,0,1,0,1C701.1,279.1,696.7,279.1,692,279.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M705.7,279a.5.5,0,0,1,0-1,40.57,40.57,0,0,0,23.6-8.6.5.5,0,0,1,.6.8,41.88,41.88,0,0,1-24.2,8.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M692,279.3h-2.2a.5.5,0,0,1,0-1H692a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M683.2,211.5c-.2,0-.5-.2-.5-.4a.46.46,0,0,1,.4-.5l40.5-4.6a.45.45,0,1,1,.1.9l-40.5,4.6Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M729.6,269.9" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1400.7,79.5" transform="translate(5.92 -69)"></path>
                        <path d="M1369.8,153.6a.47.47,0,0,1-.5-.5l-5-71.3a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l35.8-2.3a.5.5,0,0,1,0,1l-35.4,2.3,4.9,70.3,35.2-2.3a.5.5,0,0,1,0,1l-35.4,2.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1279.6,157.5a.51.51,0,0,1-.5-.4l-16.3-68c-.1-.3.1-.5.4-.6s.5.1.6.4l16.3,68c.1.3-.1.5-.4.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1279.6,157.3c-.2,0-.4-.1-.4-.3a.48.48,0,0,1,.2-.6,36.46,36.46,0,0,0,13-9.6.5.5,0,1,1,.8.6,38.41,38.41,0,0,1-13.4,9.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1293,148a.37.37,0,0,1-.3-.1.48.48,0,0,1,0-.7,27.23,27.23,0,0,1,28.4-8,.47.47,0,1,1-.3.9,26.41,26.41,0,0,0-27.4,7.7A.52.52,0,0,1,1293,148Z" transform="translate(5.92 -69)"></path>
                        <path d="M1320.8,140.1a.47.47,0,0,1-.5-.5l-3.4-53.9-53.5,3.8a.45.45,0,1,1-.1-.9l54-3.9a.6.6,0,0,1,.4.1c.1.1.2.2.2.3l3.5,54.4c-.1.3-.3.6-.6.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1232.7,168.4a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-16.5-69.6c-.1-.3.1-.5.4-.6l36.4-8.8c.3-.1.5.1.6.4s-.1.5-.4.6l-35.9,8.6,16.3,68.6,18.2-4.5c.3-.1.5.1.6.4s-.1.5-.4.6l-18.7,4.6C1232.8,168.4,1232.8,168.4,1232.7,168.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1251.7,163.8a.66.66,0,0,1-.5-.4c-.1-.3.1-.5.4-.6a165.45,165.45,0,0,1,16.3-3,.45.45,0,1,1,.1.9,151.72,151.72,0,0,0-16.2,2.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1267.7,160.7c-.2,0-.5-.2-.5-.4a.46.46,0,0,1,.4-.5,40.77,40.77,0,0,0,11.7-3.3.47.47,0,1,1,.3.9,42.56,42.56,0,0,1-11.9,3.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1252.7,90.1a.5.5,0,0,1,0-1l10.7-.5a.54.54,0,0,1,.5.5.47.47,0,0,1-.5.5l-10.7.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1279.6,157.1" transform="translate(5.92 -69)"></path>
                        <path d="M1330.5,145.2a.37.37,0,0,1-.3-.1,29,29,0,0,0-9.4-5.1.47.47,0,1,1,.3-.9,30.77,30.77,0,0,1,9.7,5.2.62.62,0,0,1,.1.7A.4.4,0,0,1,1330.5,145.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1357,154.2a41.86,41.86,0,0,1-26.7-9.4.5.5,0,0,1,.6-.8,40.37,40.37,0,0,0,28.3,9.1.5.5,0,0,1,0,1C1358.6,154.1,1357.8,154.2,1357,154.2Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1320.8,139.6" transform="translate(5.92 -69)"></path>
                        <path d="M1317.3,85.7a.45.45,0,1,1-.1-.9l47.5-3.4a.45.45,0,1,1,.1.9l-47.5,3.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1359.2,154.3a.5.5,0,0,1,0-1l10.7-.7a.5.5,0,0,1,0,1l-10.7.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M169.3,548.9l-34.9.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l42.1-.3c.1,0,.3.1.4.1s.1.2.1.4l-1.4,30.2c0,.1-.1.2-.2.3l-5.6,5.3C169.6,548.8,169.4,548.9,169.3,548.9Zm-34.4-.7,34.2-.3,5.3-5,1.3-29.5-41.1.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1650.3,377.2l-77.2.6a.47.47,0,0,1-.5-.5l-.4-53.6a.5.5,0,1,1,1,0l.4,53.1,75.7-.6-27.6-38.1a.5.5,0,1,1,.8-.6l28.1,38.9a.45.45,0,0,1,0,.5A.27.27,0,0,1,1650.3,377.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1622.4,338.1a.52.52,0,0,1-.4-.2,33.21,33.21,0,0,0-27.5-13.9.5.5,0,0,1,0-1,34,34,0,0,1,28.3,14.3.49.49,0,0,1-.1.7A.37.37,0,0,1,1622.4,338.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1594.3,324.1l-21.6.2a.5.5,0,0,1,0-1l21.6-.2a.47.47,0,0,1,.5.5C1594.7,323.8,1594.5,324.1,1594.3,324.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M116.1,518.8" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M66.2,481.3" transform="translate(5.92 -69)"></path>
                        <path d="M131.2,488.2l-.9-.4c.4-1,.8-2,1.4-3.3a.45.45,0,1,1,.8.4C132,486.2,131.6,487.2,131.2,488.2Zm-.4-.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M132.1,485.5h-.2L77.3,461c-.2-.1-.4-.4-.2-.6s.4-.4.6-.2l54.6,24.5c.2.1.4.4.2.6C132.5,485.4,132.3,485.5,132.1,485.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M62.4,486.7a.37.37,0,0,1-.3-.1.49.49,0,0,1-.1-.7,123.27,123.27,0,0,0,15-25.6c.1-.2.4-.4.6-.3s.4.4.3.6a123,123,0,0,1-15.2,25.8A.27.27,0,0,1,62.4,486.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M136.3,477.9" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M81.7,453.6" transform="translate(5.92 -69)"></path>
                        <path d="M132.1,485.3h-.2c-.2-.1-.4-.4-.3-.6A236.88,236.88,0,0,0,143.9,444a.51.51,0,1,1,1,.2,239.3,239.3,0,0,1-12.4,40.9C132.5,485.2,132.3,485.3,132.1,485.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M144.3,444.5h-.1L86.3,432.7a.51.51,0,1,1,.2-1l57.9,11.8a.55.55,0,0,1,.4.6A.51.51,0,0,1,144.3,444.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M77.5,461h-.2a.48.48,0,0,1-.2-.6,129.07,129.07,0,0,0,9-28.4.51.51,0,0,1,1,.2A125.77,125.77,0,0,1,78,460.8.76.76,0,0,1,77.5,461Z" transform="translate(5.92 -69)"></path>
                        <path d="M86.6,432.5h0c-.4-.1-.5-.3-.5-.6a135.4,135.4,0,0,0,2.6-29.5.47.47,0,0,1,.5-.5h0a.47.47,0,0,1,.5.5,130.23,130.23,0,0,1-2.6,29.7A.66.66,0,0,1,86.6,432.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M144.3,444" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M86.4,432.2" transform="translate(5.92 -69)"></path>
                        <path d="M144.5,444.5h-.1a.55.55,0,0,1-.4-.6c.8-3.8,1.5-7.7,2.1-11.3,0-.3.3-.4.6-.4s.4.3.4.6c-.6,3.6-1.4,7.5-2.1,11.3A.51.51,0,0,1,144.5,444.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M146.4,433h0a.63.63,0,0,1-.5-.6,244.12,244.12,0,0,0,2.8-31.8.5.5,0,0,1,1,0,244.17,244.17,0,0,1-2.8,31.9A.47.47,0,0,1,146.4,433Z" transform="translate(5.92 -69)"></path>
                        <path d="M89.2,402.8a.5.5,0,0,1,0-1l59.8-1.5h0a.5.5,0,1,1,0,1l-59.8,1.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M89,402.8a.47.47,0,0,1-.5-.5,133.71,133.71,0,0,0-4.1-29.5.47.47,0,0,1,.9-.3,134.69,134.69,0,0,1,4.1,29.7c0,.4-.2.6-.4.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M89.2,402.8a.5.5,0,0,1,0-1l59.8-1.5h0a.5.5,0,1,1,0,1l-59.8,1.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M149.2,401.2h0a.47.47,0,0,1-.5-.5,216.71,216.71,0,0,0-3.4-43.4.51.51,0,1,1,1-.2,226.38,226.38,0,0,1,3.4,43.6A.54.54,0,0,1,149.2,401.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M84.7,373.5a.51.51,0,0,1-.5-.4.49.49,0,0,1,.3-.6l61.1-15.6a.47.47,0,1,1,.3.9L84.8,373.4A.1.1,0,0,1,84.7,373.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M84.9,373.3a.51.51,0,0,1-.5-.4,126.6,126.6,0,0,0-9.8-26.4.51.51,0,1,1,.9-.5,125.1,125.1,0,0,1,9.9,26.6c.1.3-.1.5-.4.6A.1.1,0,0,1,84.9,373.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M146.7,362.6" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M85.7,378.2" transform="translate(5.92 -69)"></path>
                        <path d="M145.8,357.9a.66.66,0,0,1-.5-.4,231.91,231.91,0,0,0-10.8-40.7.47.47,0,0,1,.9-.3,233.9,233.9,0,0,1,10.8,40.9c.1.2,0,.4-.4.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M74.8,346.9c-.2,0-.4-.1-.4-.3a.48.48,0,0,1,.2-.6l60-30.1a.45.45,0,0,1,.4.8L75,346.8C75,346.9,74.9,346.9,74.8,346.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M65.8,331.5h0a.54.54,0,0,1-.5-.5l3.2-32.1c0-.1.1-.2.2-.3l27.8-23.4a.5.5,0,0,1,.6.8L69.4,299.3l-3.1,31.9C66.3,331.3,66.1,331.5,65.8,331.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M75,346.7c-.2,0-.4-.1-.4-.3a131.08,131.08,0,0,0-9-15.4.5.5,0,1,1,.8-.6,149,149,0,0,1,9,15.5.48.48,0,0,1-.2.6C75.2,346.7,75.1,346.7,75,346.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M74.8,346.9c-.2,0-.4-.1-.4-.3a.48.48,0,0,1,.2-.6l60-30.1a.45.45,0,0,1,.4.8L75,346.8C75,346.9,74.9,346.9,74.8,346.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M135,316.8a.55.55,0,0,1-.5-.3,237.86,237.86,0,0,0-18.6-39.3.51.51,0,1,1,.9-.5,235.61,235.61,0,0,1,18.7,39.4.42.42,0,0,1-.3.6C135.1,316.8,135.1,316.8,135,316.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M116.2,277.7h0L96.8,276c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l19.4,1.7c.3,0,.5.3.4.5A.66.66,0,0,1,116.2,277.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M1832.5,535.5l-35.9.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1832.5,535.5Zm-35.4-.7,34.9-.3-.3-34.5-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1878.4,535.2l-45.9.4a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l20.6-.2a.52.52,0,0,1,.4.2l25.7,35.3a.45.45,0,0,1,0,.5A.55.55,0,0,1,1878.4,535.2Zm-45.4-.6,44.5-.4-25-34.3-19.8.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1796.6,535.8l-30.2.2c-.1,0-.3,0-.3-.1l-5.3-5.2a.37.37,0,0,1-.1-.3l-.2-30.2a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.3,35.5A1,1,0,0,1,1796.6,535.8Zm-30-.7,29.5-.2-.3-34.5-34.5.3.2,29.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1852.8,499.9l-91.9.7a.52.52,0,0,1-.4-.2L1745.8,480l-21-28.8c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l60.7-44c.1-.1.2-.1.4-.1s.2.1.3.2l21,29h0l45.8,63.5a.45.45,0,0,1,0,.5A.27.27,0,0,1,1852.8,499.9Zm-91.7-.2,90.7-.7-45-62.4-59.9,43.2ZM1725.9,451l20.4,28.1,59.9-43.2-20.4-28.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1725.2,451.4h0a.52.52,0,0,1-.4-.2L1704,422.4a.49.49,0,0,1,.1-.7l60.5-44a.49.49,0,0,1,.7.1l21,28.8c.1.1.1.2.1.4s-.1.2-.2.3l-60.7,44C1725.4,451.3,1725.3,451.4,1725.2,451.4Zm-20.1-29.3,20.2,28.1,59.9-43.4-20.4-28.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1738.6,536.3l-28.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l29.8-.2a.52.52,0,0,1,.4.2l4.1,5.2a.37.37,0,0,1,.1.3l.2,25a1,1,0,0,1-.1.3l-5.4,5.3A.76.76,0,0,1,1738.6,536.3Zm-27.9-.8,27.7-.2,5.1-5-.2-24.6-3.8-4.9-29.1.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1710.2,536.5l-28.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l28.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1710.2,536.5Zm-28.1-.7,27.6-.2-.3-34.5-27.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1681.6,536.7l-35.9.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1681.6,536.7Zm-35.4-.6,34.9-.3-.3-34.5-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1739.7,500.8l-94.3.8a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l68.9-.6a.52.52,0,0,1,.4.2l25.7,35.7a.45.45,0,0,1,0,.5C1740.1,500.7,1739.9,500.8,1739.7,500.8Zm-93.8-.2,92.9-.7-25-34.7-68.2.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1714.1,465.2l-68.9.6a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l38.3-.3h.2l8.5,4.2c.1,0,.1.1.2.2l22.4,31.3a.45.45,0,0,1,0,.5A.52.52,0,0,1,1714.1,465.2Zm-68.5-.5,67.5-.5-21.8-30.4-8.3-4.1-37.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1641.5,335.8h0a.52.52,0,0,1-.4-.2l-21-29c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l60.9-43.8a.49.49,0,0,1,.7.1l21,28.8c.1.1.1.2.1.4s-.1.2-.2.3l-60.9,44C1641.7,335.7,1641.6,335.8,1641.5,335.8Zm-20.3-29.4,20.4,28.3,60.1-43.4-20.4-28.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1620.5,306.7l-59,.5a.47.47,0,0,1-.5-.5l-.2-20.9-53.1.4a.47.47,0,0,1-.5-.5l-1-119.5-2-28.2a.6.6,0,0,1,.1-.4c.1-.1.2-.2.3-.2l82.6-5.7a.52.52,0,0,1,.4.2l94.1,130c.1.1.1.2.1.4s-.1.2-.2.3l-60.9,43.8C1620.7,306.7,1620.6,306.7,1620.5,306.7Zm-58.5-.5,58.4-.5,60.4-43.4L1587,132.9l-81.8,5.6,2,27.7,1,119,53.1-.4a.47.47,0,0,1,.5.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1658.4,412.8l-49.2.4a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l41.3-.3a.52.52,0,0,1,.4.2l15.5,21a.45.45,0,0,1,0,.5l-7.3,14.6C1658.8,412.7,1658.6,412.8,1658.4,412.8Zm-48.6-.6,48.4-.4,7.1-14-15.2-20.6-40.6.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M202.3,319.9h0a.47.47,0,0,1-.5-.5,35.07,35.07,0,0,1,37.6-33c.3,0,.5.3.4.5s-.2.5-.5.4a33.88,33.88,0,0,0-36.5,32C202.8,319.7,202.6,319.9,202.3,319.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M237.7,329.3h0l-35.9-1.5c-.1,0-.3-.1-.3-.2s-.1-.2-.1-.4l.5-7.9c0-.3.3-.5.5-.4s.5.2.4.5l-.5,7.4,34.9,1.5.3-11.6,1.5-29.1h-.1a.5.5,0,0,1,0-1h.6c.1,0,.3.1.4.1s.1.2.1.4l-1.6,29.6-.3,12.1c0,.1-.1.3-.2.3C237.9,329.3,237.8,329.3,237.7,329.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M216.1,257a.76.76,0,0,1-.4-.2,26.56,26.56,0,0,0-33.3-5.3.51.51,0,1,1-.5-.9,27.38,27.38,0,0,1,34.5,5.5.48.48,0,0,1,0,.7C216.4,256.9,216.3,257,216.1,257Z" transform="translate(5.92 -69)"></path>
                        <path d="M217.4,258.2a.37.37,0,0,1-.3-.1l-1.2-1.2a.49.49,0,0,1,.7-.7l1.2,1.2a.48.48,0,0,1,0,.7C217.6,258.1,217.5,258.2,217.4,258.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M217.4,258.4h0a.54.54,0,0,1-.5-.5l4.7-60.6-34.5-2.9-4.7,57c0,.3-.3.5-.5.4s-.5-.3-.4-.5l4.8-57.5c0-.3.3-.5.5-.4l35.5,2.9c.3,0,.5.3.4.5L218,257.9C217.8,258.2,217.6,258.4,217.4,258.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M146.5,361.7a.66.66,0,0,1-.5-.4,247.43,247.43,0,0,0-8.5-36.1.47.47,0,0,1,.9-.3,267.82,267.82,0,0,1,8.6,36.3c0,.2-.2.4-.5.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M243.2,270.2h0A41,41,0,0,1,217,258a.49.49,0,0,1,.7-.7,40.06,40.06,0,0,0,25.6,11.9c.3,0,.5.3.4.5A.47.47,0,0,1,243.2,270.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M394.4,280.7h0l-42.3-1.3a.5.5,0,0,1,0-1l41.9,1.3.3-4.4.4-2.3,4.9-61.6-35-2.9c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l35.5,2.9c.3,0,.5.3.4.5l-4.9,62.1-.4,2.3-.4,4.8A.47.47,0,0,1,394.4,280.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M357.8,208.2" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M773.9,264.3" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M758.9,201.9" transform="translate(5.92 -69)"></path>
                        <path d="M795,270.1a41.16,41.16,0,0,1-21.1-5.6.51.51,0,0,1,.5-.9,40.62,40.62,0,0,0,26.7,5,.51.51,0,1,1,.2,1A44.57,44.57,0,0,1,795,270.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M801.2,269.6a.66.66,0,0,1-.5-.4.55.55,0,0,1,.4-.6l11.5-1.9a.51.51,0,1,1,.2,1l-11.6,1.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M812.6,267.9a.66.66,0,0,1-.5-.4.55.55,0,0,1,.4-.6l2.9-.5-1.4-6.6-15.5-63.7-39.6,6.3a.51.51,0,1,1-.2-1l40-6.4c.2,0,.5.1.5.4l15.6,64.2,1.5,7.1a.6.6,0,0,1-.1.4c-.1.1-.2.2-.3.2l-3.3.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M816.1,267.3a.37.37,0,0,1-.3-.1c-.1-.1-.2-.2-.2-.3l-1.5-7c-.1-.3.1-.5.4-.6a.55.55,0,0,1,.6.4l1.4,6.6,3.1-.7c.3-.1.5.1.6.4s-.1.5-.4.6l-3.6.8C816.1,267.2,816.1,267.3,816.1,267.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M799,195.6" transform="translate(5.92 -69)"></path>
                        <path d="M819.9,266.4a.66.66,0,0,1-.5-.4.55.55,0,0,1,.4-.6c9.6-1.8,20-4,30.9-6.7.3-.1.5.1.6.4s-.1.5-.4.6c-10.9,2.7-21.3,4.9-31,6.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M799,196.1a.66.66,0,0,1-.5-.4c-.1-.3.1-.5.4-.6l35.2-6.7c.3-.1.5.1.6.4s-.1.5-.4.6L799,196.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M850.5,259.9a.55.55,0,0,1-.5-.3.42.42,0,0,1,.3-.6l.6-.2a.47.47,0,0,1,.3.9l-.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1163.6,184.9a.51.51,0,0,1-.5-.4c-.1-.3.1-.5.4-.6l31.2-7.3,3.1-.9-1.7-6.6-15-62.1c-.1-.3.1-.5.4-.6s.5.1.6.4l15,62.1,1.9,7a.49.49,0,0,1-.3.6l-3.6,1-31.2,7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1146.7,115.5a.51.51,0,0,1-.5-.4c-.1-.3.1-.5.4-.6l34.8-8.1c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.1Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1216.3,98.3" transform="translate(5.92 -69)"></path>
                        <path d="M1198.4,176.5a.51.51,0,0,1-.5-.4l-1.9-7a.47.47,0,0,1,.9-.3l1.8,6.6,2.8-.5,31.2-7.5c.3-.1.5.1.6.4s-.1.5-.4.6l-31.2,7.5-3.3.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1181.5,107.4a.51.51,0,0,1-.5-.4c-.1-.3.1-.5.4-.6l34.8-8.5c.3-.1.5.1.6.4s-.1.5-.4.6l-34.8,8.5C1181.6,107.3,1181.5,107.4,1181.5,107.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1502.8,502.7l-35.7.3a.47.47,0,0,1-.5-.5L1466,431a.47.47,0,0,1,.5-.5l32-.3a.47.47,0,0,1,.5.5l.1,6.8h3.1a.47.47,0,0,1,.5.5l.5,64.3C1503.3,502.5,1503.1,502.7,1502.8,502.7Zm-35.2-.7,34.7-.3-.5-63.3h-3.1a.47.47,0,0,1-.5-.5l-.1-6.8-31.1.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1538.5,502.4l-35.7.3a.47.47,0,0,1-.5-.5l-.5-64.3a.47.47,0,0,1,.5-.5h2.9l-.1-6.8a.47.47,0,0,1,.5-.5l32.2-.3a.47.47,0,0,1,.5.5l.6,71.5A.45.45,0,0,1,1538.5,502.4Zm-35.2-.7,34.7-.3-.6-70.6-31.3.3.1,6.8a.47.47,0,0,1-.5.5h-2.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1391.2,173.6a.47.47,0,0,1-.5-.5l-.2-3.9-29.7,2.2a.45.45,0,1,1-.1-.9l30.2-2.3a.6.6,0,0,1,.4.1c.1.1.2.2.2.3l.2,4.4a.65.65,0,0,1-.5.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1357.5,171.6a.66.66,0,0,1-.5-.4c-.1-.3.1-.5.4-.6a17.75,17.75,0,0,1,3.5-.4.5.5,0,0,1,0,1,17.61,17.61,0,0,0-3.4.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M1393.7,237.2l-35.9.3a.47.47,0,0,1-.5-.5l-.5-65.9a.5.5,0,1,1,1,0l.5,65.4,34.9-.3-.5-62.7h-1.5a.5.5,0,0,1,0-1h2a.47.47,0,0,1,.5.5l.5,63.7A.47.47,0,0,1,1393.7,237.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1429.1,236.9l-35.5.3a.47.47,0,0,1-.5-.5l-.5-63.7a.46.46,0,0,1,.4-.5l2-.2-.2-4a.47.47,0,0,1,.5-.5l33.2-2.1a.6.6,0,0,1,.4.1c.1.1.2.2.2.3l.6,70.1C1429.6,236.7,1429.4,236.9,1429.1,236.9Zm-34.9-.6,34.5-.3-.6-69.1-32.2,2,.2,4a.46.46,0,0,1-.4.5l-2,.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1704.5,422.5h0a.52.52,0,0,1-.4-.2l-18.9-26.2c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l5.4-3.8-1.9-2.6c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l55.1-39.5a.49.49,0,0,1,.7.1l20.8,28.8a.49.49,0,0,1-.1.7l-60.5,44A.76.76,0,0,1,1704.5,422.5Zm-18.3-26.6,18.4,25.5,59.7-43.4-20.2-28.1-54.3,39,1.9,2.6c.1.1.1.2.1.4s-.1.2-.2.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1681.4,390.5a.52.52,0,0,1-.4-.2l-18.9-25.8c-.1-.1-.1-.2-.1-.4s.1-.2.2-.3l60.7-44c.1-.1.2-.1.4-.1s.2.1.3.2l21,29c.1.1.1.2.1.4s-.1.2-.2.3l-55.1,39.5c-.1.1-.2.1-.4.1s-.2-.1-.3-.2l-1.6-2.4-5.4,3.8C1681.6,390.4,1681.5,390.5,1681.4,390.5Zm-18.2-26.3,18.4,25.1,5.4-3.8c.1-.1.2-.1.4-.1s.2.1.3.2l1.6,2.4,54.3-39-20.4-28.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1608.1,537.3l-33.6.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l35.9-.3a.47.47,0,0,1,.5.5l.3,31.2a.47.47,0,0,1-.5.5h-1.7v3.7Q1608.4,537.3,1608.1,537.3Zm-33.2-.7,32.7-.3v-3.7a.47.47,0,0,1,.5-.5h1.7l-.2-30.3-34.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1645.7,537l-33.2.3a.47.47,0,0,1-.5-.5v-3.7h-1.7a.47.47,0,0,1-.5-.5l-.3-31.2a.47.47,0,0,1,.5-.5l35.5-.3a.47.47,0,0,1,.5.5l.3,35.5A.68.68,0,0,1,1645.7,537Zm-32.7-.7,32.3-.3-.3-34.5-34.5.3.2,30.3h1.7a.47.47,0,0,1,.5.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1392,308.6l-33.6.3a.47.47,0,0,1-.5-.5l-.6-71.3a.5.5,0,1,1,1,0l.6,70.8,32.7-.3v-3.9a.47.47,0,0,1,.5-.5h1.7l-.5-66.4a.5.5,0,1,1,1,0l.5,66.9a.47.47,0,0,1-.5.5h-1.7v3.9C1392.5,308.3,1392.3,308.6,1392,308.6Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1393.7,236.7" transform="translate(5.92 -69)"></path>
                        <path d="M1429.7,308.3l-33.2.3a.47.47,0,0,1-.5-.5v-3.9h-1.7c-.1,0-.3,0-.3-.1a.37.37,0,0,1-.1-.3l-.3-58.8a.47.47,0,0,1,.5-.5h0a.47.47,0,0,1,.5.5l.3,58.3h1.7a.47.47,0,0,1,.5.5v3.9l32.3-.3-.6-70.8a.5.5,0,1,1,1,0l.6,71.3C1430.2,308,1430,308.3,1429.7,308.3Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M1429.6,244.5" transform="translate(5.92 -69)"></path>
                        <path d="M1466.2,378.7l-35.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l33.6-.3a.47.47,0,0,1,.5.5v3.7h1.7a.47.47,0,0,1,.5.5l.4,49.4A.46.46,0,0,1,1466.2,378.7Zm-35.4-.7,34.9-.3-.4-48.4h-1.7a.47.47,0,0,1-.5-.5v-3.7l-32.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1501.8,378.4l-35.7.3a.47.47,0,0,1-.5-.5l-.4-49.4a.47.47,0,0,1,.5-.5h1.7v-3.7a.47.47,0,0,1,.5-.5l33.4-.3a.47.47,0,0,1,.5.5l.4,53.6C1502.3,378.2,1502.1,378.4,1501.8,378.4Zm-35.2-.7,34.7-.3-.4-52.6-32.5.3v3.7a.47.47,0,0,1-.5.5h-1.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M1306.4,380l-71.7.6a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l67.3-.5a.47.47,0,0,1,.5.5v1.5h3.9a.47.47,0,0,1,.5.5l.3,33.6A.54.54,0,0,1,1306.4,380Zm-71.3-.4,70.8-.6-.3-32.7h-3.9a.47.47,0,0,1-.5-.5v-1.5l-66.3.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1301.7,344.3l-67.3.5a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l71.7-.6a.47.47,0,0,1,.5.5l.3,33.6a.47.47,0,0,1-.5.5h-3.9v1.7C1302.1,344.1,1301.9,344.3,1301.7,344.3Zm-66.9-.4,66.3-.5v-1.7a.47.47,0,0,1,.5-.5h3.9l-.3-32.7-70.8.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M1234.4,344.9l-66.9.5a.47.47,0,0,1-.5-.5v-1.7h-3.9a.47.47,0,0,1-.5-.5l-.3-33.6a.47.47,0,0,1,.5-.5l71.3-.6a.47.47,0,0,1,.5.5l.3,35.9C1234.8,344.7,1234.6,344.9,1234.4,344.9Zm-66.4-.4,65.9-.5-.3-34.9-70.4.6.3,32.7h3.9a.47.47,0,0,1,.5.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1234.7,380.6l-71.3.6a.47.47,0,0,1-.5-.5l-.3-33.6a.47.47,0,0,1,.5-.5h3.9v-1.5a.47.47,0,0,1,.5-.5l66.9-.5a.47.47,0,0,1,.5.5l.3,35.7C1235.1,380.3,1234.9,380.5,1234.7,380.6Zm-70.9-.4,70.4-.6-.3-34.7-65.9.5v1.5a.47.47,0,0,1-.5.5h-3.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1233.5,237.7l-71.3.6a.47.47,0,0,1-.5-.5l-.2-30a.37.37,0,0,1,.1-.3l5.4-6.5c.1-.1.2-.1.3-.2L1228,186a.49.49,0,0,1,.6.3l1.7,6.6,2.8-.7a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.4,44.5A.63.63,0,0,1,1233.5,237.7Zm-70.8-.4,70.4-.6-.3-43.4-2.6.7a.49.49,0,0,1-.6-.3l-1.7-6.6-60.1,14.6-5.2,6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1305.2,237.1l-71.7.6a.47.47,0,0,1-.5-.5l-.4-44.5a.66.66,0,0,1,.4-.5l3.1-.5-1.5-6.5c-.1-.3.1-.5.4-.6l20.3-5c.3-.1.5.1.6.4s-.1.5-.4.6l-19.8,4.9,1.6,6.6a.6.6,0,0,1-.1.4c-.1.1-.2.2-.3.2l-3.2.6.4,43.6,70.8-.6-.2-27.3a.5.5,0,1,1,1,0l.2,27.8A1.49,1.49,0,0,1,1305.2,237.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1305,209.1a.47.47,0,0,1-.5-.5,31.26,31.26,0,0,0-10.7-23.3,30.89,30.89,0,0,0-24.7-7.3c-.3,0-.5-.1-.5-.4s.1-.5.4-.5a32.07,32.07,0,0,1,36.4,31.6C1305.5,208.9,1305.3,209.1,1305,209.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1255.4,180.3a.51.51,0,0,1-.5-.4c-.1-.3.1-.5.4-.6,4.4-1,8.9-1.9,13.3-2.5.3,0,.5.1.5.4s-.1.5-.4.5c-4.4.6-8.8,1.5-13.2,2.5A.1.1,0,0,1,1255.4,180.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M154.9,263.5h0l-56.7-4.6c-.1,0-.2-.1-.3-.2L74.5,231.1c-.1-.1-.1-.2-.1-.4l4.3-45.6c0-.3.3-.5.5-.4s.5.3.4.5l-4.2,45.4L98.5,258l56.5,4.6c.3,0,.5.3.4.5A.46.46,0,0,1,154.9,263.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M157.7,263.6a27.15,27.15,0,0,1-2.8-.1c-.3,0-.5-.3-.4-.5s.3-.5.5-.4a28,28,0,0,0,21.7-7.7.49.49,0,1,1,.7.7A29.57,29.57,0,0,1,157.7,263.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M176.8,255.5a.76.76,0,0,1-.4-.2.48.48,0,0,1,0-.7,30.82,30.82,0,0,1,5.3-3.9.51.51,0,1,1,.5.9,35.15,35.15,0,0,0-5.1,3.8A1,1,0,0,1,176.8,255.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M181.8,251.8h0a.54.54,0,0,1-.5-.5l4.7-57L79,185.7c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l107.5,8.6c.1,0,.2.1.3.2s.1.2.1.4l-4.8,57.5C182.3,251.6,182.1,251.8,181.8,251.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M137.9,325.5a.55.55,0,0,1-.5-.3c-.5-1.4-1-2.9-1.5-4.3s-.9-2.9-1.4-4.3a.47.47,0,0,1,.9-.3c.5,1.4,1,2.9,1.5,4.3s.9,2.9,1.4,4.3a.42.42,0,0,1-.3.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M184.7,327.5h0a.54.54,0,0,1-.5-.5l.6-6.2.2-4a.5.5,0,0,1,1,0l-.2,4-.6,6.3A.66.66,0,0,1,184.7,327.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M137.7,325" transform="translate(5.92 -69)"></path>
                        <path d="M185.4,317h0a.47.47,0,0,1-.5-.5A34.5,34.5,0,0,0,153,280.4c-.3,0-.5-.3-.4-.5s.3-.5.5-.4a35.48,35.48,0,0,1,32.8,37.1C185.9,316.8,185.6,317,185.4,317Z" transform="translate(5.92 -69)"></path>
                        <path d="M152.9,280.4h0l-36.7-2.7c-.3,0-.5-.3-.4-.5s.2-.5.5-.4l36.7,2.7c.3,0,.5.3.4.5A.66.66,0,0,1,152.9,280.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M432.8,334l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5h3.6l17.7.1a.47.47,0,0,1,.5.5l.3,35.7A.43.43,0,0,1,432.8,334Zm-20.9-.8,20.4-.2-.3-34.7H411.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M411.4,334.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-36.7c0-.1.1-.3.1-.4s.2-.1.4-.1l17.9.7H411a.47.47,0,0,1,.5.5l.3,35.9C411.9,334,411.7,334.2,411.4,334.2Zm-21.1-.8,20.6-.2-.3-34.9h-3.1l-17.5-.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M540.2,369l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,540.2,369Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M539.9,333.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,539.9,333.1Zm-21-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M433,369.9l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,433,369.9Zm-20.8-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M411.7,370l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,411.7,370Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M389.8,334.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-37.7c0-.1.1-.3.1-.4s.2-.1.4-.1l21.4.8a.47.47,0,0,1,.5.5l.3,36.7A.47.47,0,0,1,389.8,334.3Zm-20.8-.8,20.4-.2-.3-35.7-20.4-.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M390.1,370.2l-21.4.2a.47.47,0,0,1-.5-.5L368,334a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.63.63,0,0,1,390.1,370.2Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M368.8,370.4l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,368.8,370.4Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M368.5,334.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-38.9c0-.1.1-.3.1-.4s.2-.1.4-.1l21.6,1a.47.47,0,0,1,.5.5L369,334A.47.47,0,0,1,368.5,334.5Zm-21.1-.8,20.6-.2-.3-36.7-20.6-1Z" transform="translate(5.92 -69)"></path>
                        <path d="M346.9,334.7l-21.2.2a.47.47,0,0,1-.5-.5l-.3-40.5a.76.76,0,0,1,.2-.4c.1-.1.2-.1.4-.1l21.2,1.4c.3,0,.4.2.5.5l.3,38.9A.63.63,0,0,1,346.9,334.7Zm-20.7-.8,20.2-.2-.3-38-20.2-1.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M347.2,370.6l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9C347.7,370.3,347.5,370.5,347.2,370.6Zm-20.7-.9,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M326.1,370.7l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9C326.5,370.5,326.3,370.7,326.1,370.7Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M325.8,334.9l-21.6.2a.47.47,0,0,1-.5-.5l-.3-42.5a.76.76,0,0,1,.2-.4c.1-.1.2-.1.4-.1l14.1,1.3,7.5.5c.3,0,.4.2.4.5l.3,40.5A.64.64,0,0,1,325.8,334.9Zm-21.1-.8,20.6-.2-.3-39.6-7-.5h0l-13.6-1.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M304.2,335l-21.4.2a.47.47,0,0,1-.5-.5l-.4-44.1a.76.76,0,0,1,.2-.4c.1-.1.2-.1.4-.1l21.4,1.4c.3,0,.4.2.5.5l.3,42.5A.47.47,0,0,1,304.2,335Zm-20.9-.8,20.4-.2-.3-41.6L283,291Z" transform="translate(5.92 -69)"></path>
                        <path d="M304.5,370.9l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,304.5,370.9Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M283.1,371.1l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,283.1,371.1Zm-20.8-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M282.9,335.2l-21.4.2a.47.47,0,0,1-.5-.5l-.4-46.1a.76.76,0,0,1,.2-.4c.1-.1.2-.1.4-.1l21.4,1.8a.46.46,0,0,1,.4.5l.4,44.1C283.3,335,283.1,335.2,282.9,335.2Zm-20.9-.8,20.4-.2L282,291l-20.4-1.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M261.5,335.4l-24.2.2c-.1,0-.3-.1-.4-.1s-.1-.2-.1-.4l2.2-48c0-.1.1-.3.2-.3s.2-.1.4-.1l21.6,1.6c.3,0,.4.2.4.5l.4,46.1A.47.47,0,0,1,261.5,335.4Zm-23.7-.8,23.2-.2-.4-45.2L240,287.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M261.8,371.2l-26.2.2c-.1,0-.3-.1-.4-.1s-.1-.2-.1-.4l1.7-35.9a.47.47,0,0,1,.5-.5l24.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,261.8,371.2Zm-25.7-.7,25.2-.2-.3-34.9-23.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M262.5,459.7l-30.8.2c-.1,0-.3-.1-.4-.1s-.1-.2-.1-.4l1.7-35.7a.47.47,0,0,1,.5-.5l28.8-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,262.5,459.7Zm-30.3-.7,29.8-.2-.3-34.7-27.9.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M262.2,424l-28.8.2c-.1,0-.3-.1-.4-.1s-.1-.2-.1-.4l1.5-35.7a.47.47,0,0,1,.5-.5l27-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,262.2,424Zm-28.3-.7,27.8-.2-.3-34.7-26.1.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M283.9,459.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C284.3,459.3,284.1,459.5,283.9,459.5Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M283.6,423.9l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C284,423.6,283.8,423.9,283.6,423.9Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M305.2,459.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,305.2,459.3Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M304.9,423.7l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,304.9,423.7Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M326.8,459.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.64.64,0,0,1,326.8,459.2Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M326.5,423.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5L327,423A.54.54,0,0,1,326.5,423.5Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M347.9,459l-21.2.2a.47.47,0,0,1-.5-.5L326,423a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.63.63,0,0,1,347.9,459Zm-20.7-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M347.6,423.3l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,347.6,423.3Zm-20.6-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M369.5,458.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,369.5,458.8Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M369.2,423.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,369.2,423.2Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M390.8,458.7l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,390.8,458.7Zm-20.8-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M390.6,423l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C391,422.8,390.8,423,390.6,423Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M412.4,458.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,412.4,458.5Zm-21.1-.8,20.6-.2-.3-34.7L391,423Z" transform="translate(5.92 -69)"></path>
                        <path d="M433.8,458.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C434.2,458.1,434,458.3,433.8,458.3Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M454.8,422.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,454.8,422.5Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M455.1,458.1l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,455.1,458.1Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M476.7,458l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7C477.1,457.7,476.9,458,476.7,458Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M476.4,422.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,476.4,422.3Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M497.8,457.8l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,497.8,457.8Zm-20.7-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M497.5,422.1l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,497.5,422.1Zm-20.6-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M519.1,422l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,519.1,422Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M519.4,457.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,519.4,457.6Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M540.9,457.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7C541.4,457.2,541.2,457.4,540.9,457.5Zm-21-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M540.7,421.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7C541.1,421.6,540.9,421.8,540.7,421.8Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M562,421.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,562,421.6Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M562.3,457.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,562.3,457.3Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M583.7,457.1l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C584.1,456.9,583.9,457.1,583.7,457.1Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M583.4,421.4l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C583.8,421.2,583.6,421.4,583.4,421.4Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M604.7,421.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,604.7,421.3Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M605,456.9l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,605,456.9Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M626.6,456.8,605,457a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7C627,456.5,626.8,456.8,626.6,456.8Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M626.3,421.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,626.3,421.1Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M647.4,420.9l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,647.4,420.9Zm-20.6-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M647.7,456.6l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,647.7,456.6Zm-20.7-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M669.3,456.4l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,669.3,456.4Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M669,420.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,669,420.8Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M690.6,420.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7C691,420.4,690.8,420.6,690.6,420.6Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M690.8,456.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,690.8,456.2Zm-21-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M712.2,456.1l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,712.2,456.1Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M711.9,420.4l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,711.9,420.4Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-12" d="M562.4,473.9" transform="translate(5.92 -69)"></path>
                        <path d="M625.9,368.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9C626.3,368.1,626.1,368.3,625.9,368.3Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M625.6,332.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5L626,332A.66.66,0,0,1,625.6,332.5Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M604,332.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,604,332.6Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M604.3,368.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,604.3,368.5Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M582.9,368.7l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,582.9,368.7Zm-20.8-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M582.7,332.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5C583.1,332.6,582.9,332.8,582.7,332.8Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M561.3,333l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,561.3,333Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M561.6,368.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,561.6,368.8Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M518.7,369.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,518.7,369.2Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M518.4,333.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,518.4,333.3Zm-21.1-.8,20.6-.2-.3-34.5L497,298Z" transform="translate(5.92 -69)"></path>
                        <path d="M496.8,333.5l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,496.8,333.5Zm-20.7-.8,20.2-.2L496,298l-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M497.1,369.3l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,497.1,369.3Zm-20.7-.8,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M476,369.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9C476.4,369.3,476.2,369.5,476,369.5Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M475.7,333.7l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.64.64,0,0,1,475.7,333.7Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M454.1,333.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a1,1,0,0,1,.1-.3.37.37,0,0,1,.3-.1l11.9.1,9.5-.1a.47.47,0,0,1,.5.5l.3,35.5C454.6,333.6,454.4,333.8,454.1,333.8Zm-20.9-.8,20.4-.2-.3-34.5-9,.1h0l-11.4-.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M454.4,369.7l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,454.4,369.7Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M647,368.1l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,647,368.1Zm-20.7-.8,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M668.6,368l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.64.64,0,0,1,668.6,368Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M690.1,367.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,690.1,367.8ZM669,367l20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M711.5,367.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,711.5,367.6Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M737.7,367.4l-26.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l31.8-.3a.47.47,0,0,1,.5.5l.2,30.6a.76.76,0,0,1-.2.4l-5.6,5.3C737.9,367.4,737.8,367.4,737.7,367.4Zm-25.7-.7,25.5-.2,5.3-5-.2-29.9-30.9.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M743,331.5l-31.8.3a.47.47,0,0,1-.5-.5l-.3-35.7a.5.5,0,0,1,1,0l.3,35.2,30.9-.2-.1-8.2a.5.5,0,1,1,1,0l.1,8.7A.68.68,0,0,1,743,331.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M743,322.8a.47.47,0,0,1-.5-.5,26.89,26.89,0,0,0-8.3-19.1,27.81,27.81,0,0,0-19.7-7.4.5.5,0,0,1,0-1,28.2,28.2,0,0,1,20.4,7.6,27.79,27.79,0,0,1,8.6,19.8C743.4,322.6,743.2,322.8,743,322.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M714.3,295.9h-3.4a.5.5,0,0,1,0-1h3.4a.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M711.2,331.8l-21.4.2a.47.47,0,0,1-.5-.5L689,296a.47.47,0,0,1,.5-.5h2.6a.5.5,0,0,1,0,1H690l.3,34.5,20.4-.2-.3-35.2a.5.5,0,0,1,1,0l.3,35.7A.54.54,0,0,1,711.2,331.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M692.4,296.3a.5.5,0,0,1,0-1c6.1,0,12.5-.1,18.5-.3a.5.5,0,0,1,0,1C704.9,296.2,698.5,296.2,692.4,296.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M689.8,331.9l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,689.8,331.9Zm-21-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M668.3,332.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,668.3,332.1Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M646.7,332.3l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,646.7,332.3Zm-20.7-.8,20.2-.2-.3-34.5-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1071.9,541.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1071.9,541.6Zm-20.8-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1071.7,506.2l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9C1072.1,506,1071.9,506.2,1071.7,506.2Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1007.9,542.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5C1008.3,541.9,1008.1,542.2,1007.9,542.2Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1050.6,541.8l-42.7.3a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l42.7-.3a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1050.6,541.8Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Zm-21.2.2,20.2-.2-.3-34.5-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1050.3,506.4l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9C1050.8,506.1,1050.6,506.3,1050.3,506.4Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1028.7,506.5l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1028.7,506.5Zm-20.6-.8,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1007.6,506.7l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,1007.6,506.7Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M900.7,543l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,900.7,543Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M922,542.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,922,542.8Zm-20.8-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M943.4,542.7l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,943.4,542.7Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M964.7,542.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,964.7,542.5Zm-20.8-.8,20.4-.2L964,507l-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M986.3,542.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,986.3,542.3Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M986,506.9l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,986,506.9Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M964.5,507l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9C964.9,506.8,964.7,507,964.5,507Zm-20.9-.8L964,506l-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M943.1,507.2l-21.4.2a.47.47,0,0,1-.5-.5L921,471a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.63.63,0,0,1,943.1,507.2Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M921.8,507.4l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9C922.2,507.2,922,507.4,921.8,507.4Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M900.4,507.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,900.4,507.6Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M878.8,507.7l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,878.8,507.7Zm-20.6-.8,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M879.1,543.2l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,879.1,543.2Zm-20.7-.8,20.2-.2-.3-34.5-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M858,543.4l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5C858.4,543.1,858.2,543.4,858,543.4Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M857.7,507.9l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,857.7,507.9Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M836.1,508.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,836.1,508.1Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M836.4,543.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,836.4,543.5Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M767.9,512.6a.47.47,0,0,1-.5-.5v-4a.5.5,0,0,1,1,0v4C768.3,512.4,768.1,512.6,767.9,512.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M799.7,543.6a31.86,31.86,0,0,1-32-31.5.5.5,0,0,1,1,0,30.73,30.73,0,0,0,31,30.5.5.5,0,0,1,0,1Z" transform="translate(5.92 -69)"></path>
                        <path d="M814.9,543.7l-15.3.1a.5.5,0,1,1,0-1l14.8-.1-.3-34.5-46.3.4a.5.5,0,0,1,0-1l46.7-.4a.47.47,0,0,1,.5.5l.3,35.5A.46.46,0,0,1,814.9,543.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M814.6,508.2l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9C815,508,814.8,508.2,814.6,508.2Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M793.2,508.4l-25.4.2a.47.47,0,0,1-.5-.5l-.2-30.4c0-.1,0-.3.1-.3l5.4-5.5a.37.37,0,0,1,.3-.1l19.9-.2a.47.47,0,0,1,.5.5l.3,35.9C793.7,508.2,793.5,508.4,793.2,508.4Zm-24.9-.8,24.4-.2-.3-34.9-19.3.2-5.1,5.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1200.5,540.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1200.5,540.6Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1178.9,540.8l-21.2.2a.47.47,0,0,1-.5-.5L1157,505a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.5A.63.63,0,0,1,1178.9,540.8Zm-20.7-.8,20.2-.2-.3-34.5-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1157.8,540.9l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5C1158.2,540.7,1158,540.9,1157.8,540.9Zm-21.1-.7,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1136.2,541.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1136.2,541.1Zm-21.1-.8,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1114.6,541.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1114.6,541.3Zm-20.8-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1093.3,541.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1093.3,541.5Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1093,506l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1093,506Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1114.4,505.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9C1114.8,505.6,1114.6,505.8,1114.4,505.8Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1135.9,505.7l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,1135.9,505.7Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1157.5,505.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,1157.5,505.5Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1178.6,505.3l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1178.6,505.3Zm-20.6-.8,20.2-.2-.3-34.9-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1200.2,505.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1200.2,505.1Zm-21.1-.8,20.6-.2-.3-34.9-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1221.8,540.4l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.47.47,0,0,1,1221.8,540.4Zm-20.8-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1221.5,505l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.47.47,0,0,1,1221.5,505Zm-20.8-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1243.2,540.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.5A.54.54,0,0,1,1243.2,540.3Zm-20.9-.8,20.4-.2-.3-34.5-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1270.4,540l-27.2.2a.47.47,0,0,1-.5-.5l-.3-35.5a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35h5.2a.47.47,0,0,1,.5.5A.63.63,0,0,1,1270.4,540Zm-26.7-.7,20.6-.2-.3-34.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1270.4,540a.5.5,0,0,1,0-1,36.53,36.53,0,0,0,36.3-35.5.5.5,0,0,1,1,0A37.53,37.53,0,0,1,1270.4,540Z" transform="translate(5.92 -69)"></path>
                        <path d="M1307.4,504.3l-42.9.3a.5.5,0,0,1,0-1l42.9-.3a.47.47,0,0,1,.5.5A.54.54,0,0,1,1307.4,504.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1285.8,504.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.9A.54.54,0,0,1,1285.8,504.5Zm-20.9-.8,20.4-.2-.3-34.9-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1307.4,504.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l16.1-.1a.5.5,0,0,1,0,1l-15.6.1.3,34.9,21.1-.2a.47.47,0,0,1,.5.5A.54.54,0,0,1,1307.4,504.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1307.2,504.1a.47.47,0,0,1-.5-.5v-1.4a.5.5,0,1,1,1,0v1.4A.54.54,0,0,1,1307.2,504.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1307.4,502.9a.47.47,0,0,1-.5-.5l-.2-28.8-5.3-5.3a.49.49,0,1,1,.7-.7l5.5,5.4a.37.37,0,0,1,.1.3l.2,29C1307.8,502.7,1307.6,502.9,1307.4,502.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M1301.5,451.3l-23.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l28.6-.2a.47.47,0,0,1,.5.5l.2,30.4a1,1,0,0,1-.1.3l-5.4,5.3C1301.8,451.3,1301.6,451.3,1301.5,451.3Zm-22.7-.7,22.5-.2,5.1-5-.2-29.7-27.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1278.3,451.5l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1278.3,451.5Zm-21-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1256.8,451.7l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1256.8,451.7Zm-21.1-.8,20.6-.2L1256,416l-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1235.2,451.9l-21.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1235.2,451.9Zm-20.7-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1214.1,452l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,1214.1,452Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1192.5,452.2l-23.2.2a.37.37,0,0,1-.3-.1l-5.5-5.2c-.1-.1-.1-.2-.2-.3l-.2-30.4a.47.47,0,0,1,.5-.5l28.6-.2a.47.47,0,0,1,.5.5l.3,35.7C1193,452,1192.8,452.2,1192.5,452.2Zm-23-.8,22.5-.2-.3-34.7-27.6.2.2,29.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M1141.5,452.6l-15.9.1a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.2,30.4c0,.1,0,.3-.1.3l-5.2,5.3C1141.8,452.6,1141.7,452.6,1141.5,452.6Zm-15.4-.8,15.2-.1,4.9-5L1146,417l-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1125.6,452.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,1125.6,452.8Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1146.5,416.9l-42.7.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l37.5-.3a1,1,0,0,1,.3.1l5.3,5.4a.37.37,0,0,1,.1.3l.4,48.2C1147,416.7,1146.8,416.9,1146.5,416.9Zm-42.2-.6,41.7-.3-.4-47.5-5-5.1-36.8.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1103.8,417.3l-42.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l42.9-.3a.47.47,0,0,1,.5.5l.4,53.6A.64.64,0,0,1,1103.8,417.3Zm-42.5-.7,41.9-.3-.4-52.6-41.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1104.1,452.9l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C1104.5,452.7,1104.3,452.9,1104.1,452.9Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1082.7,453.1l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1082.7,453.1Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1061.2,453.3l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C1061.6,453.1,1061.4,453.3,1061.2,453.3Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1039.8,453.4l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1039.8,453.4Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M1060.9,417.6l-42.7.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l42.7-.3a.47.47,0,0,1,.5.5l.4,53.6C1061.3,417.4,1061.1,417.6,1060.9,417.6Zm-42.3-.6,41.7-.3L1060,364l-41.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M1018.2,418l-42.7.3a.47.47,0,0,1-.5-.5l-.4-49.2a.47.47,0,0,1,.5-.5h1.5v-3.9a.47.47,0,0,1,.5-.5l40.7-.3a.47.47,0,0,1,.5.5l.4,53.6C1018.6,417.7,1018.4,418,1018.2,418Zm-42.3-.7,41.7-.3-.4-52.6-39.7.3v3.9a.47.47,0,0,1-.5.5h-1.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M975.1,371.8c-.1,0-.2-.1-.3-.1a.37.37,0,0,1-.1-.3c0-.1.1-.2.1-.3a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.6C975.3,371.7,975.2,371.8,975.1,371.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M1018.4,453.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,1018.4,453.6Zm-21-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M954.2,454.1l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C954.6,453.9,954.4,454.1,954.2,454.1Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M975.4,418.3l-42.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l40.7-.3a.47.47,0,0,1,.5.5v3.9H975a.47.47,0,0,1,.5.5l.4,49.2A.47.47,0,0,1,975.4,418.3Zm-42.4-.6,41.9-.3-.4-48.2h-1.7a.47.47,0,0,1-.5-.5v-3.9l-39.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M932.5,418.6l-42.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l42.9-.3a.47.47,0,0,1,.5.5l.4,53.6A.47.47,0,0,1,932.5,418.6Zm-42.4-.6,41.9-.3-.4-52.6-41.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M932.8,454.3l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,932.8,454.3Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M911.3,454.5l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7C911.7,454.3,911.5,454.5,911.3,454.5Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M889.9,454.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.4-.2a.47.47,0,0,1,.5.5l.3,35.7A.47.47,0,0,1,889.9,454.6Zm-20.9-.8,20.4-.2-.3-34.7-20.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M868.5,454.8,847,455a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.63.63,0,0,1,868.5,454.8Zm-21-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M889.6,419l-42.9.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l42.9-.3a.47.47,0,0,1,.5.5l.4,53.6A.47.47,0,0,1,889.6,419Zm-42.4-.6,41.9-.3-.4-52.6-41.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M846.7,419.3l-42.7.3a.47.47,0,0,1-.5-.5l-.4-53.6a.47.47,0,0,1,.5-.5l42.7-.3a.47.47,0,0,1,.5.5l.4,53.6A.47.47,0,0,1,846.7,419.3Zm-42.2-.6,41.7-.3-.4-52.6-41.7.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M847,455l-21.2.2a.47.47,0,0,1-.5-.5L825,419a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,847,455Zm-20.7-.8,20.2-.2-.3-34.7-20.2.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M825.8,455.2l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,35.7A.54.54,0,0,1,825.8,455.2Zm-21.1-.8,20.6-.2-.3-34.7-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M804.3,455.3l-31.4.3a.37.37,0,0,1-.3-.1l-5.5-5.2c-.1-.1-.1-.2-.2-.3l-.2-30.4a.47.47,0,0,1,.5-.5l36.9-.3a.47.47,0,0,1,.5.5l.3,35.7A1.14,1.14,0,0,1,804.3,455.3Zm-31.3-.7,30.8-.2-.3-34.7-35.9.3.2,29.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M412.1,422.8l-21.6.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l19.3-.2a.47.47,0,0,1,.5.5v3.9h1.7a.47.47,0,0,1,.5.5l.3,31.2C412.6,422.6,412.4,422.8,412.1,422.8ZM391,422l20.6-.2-.2-30.3h-1.7a.47.47,0,0,1-.5-.5v-3.9l-18.4.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M433.5,422.6l-21.4.2a.47.47,0,0,1-.5-.5l-.3-31.2a.47.47,0,0,1,.5-.5h1.5v-3.9a.47.47,0,0,1,.5-.5l19.3-.2a.47.47,0,0,1,.5.5l.3,35.7A.43.43,0,0,1,433.5,422.6Zm-20.9-.8,20.4-.2-.3-34.7-18.4.1v3.9a.47.47,0,0,1-.5.5h-1.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M738.4,455.9l-26.2.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l27.4-.2a.47.47,0,0,1,.5.5v1.5h3.9a.47.47,0,0,1,.5.5l.2,28.4a.76.76,0,0,1-.2.4l-5.6,5.3A.35.35,0,0,1,738.4,455.9Zm-25.7-.8,25.5-.2,5.3-5-.2-27.7h-3.9a.47.47,0,0,1-.5-.5v-1.5l-26.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M739.3,420.2l-27.4.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l26.2-.2a.37.37,0,0,1,.3.1l5.7,5.4c.1.1.1.2.2.3l.2,28a.47.47,0,0,1-.5.5h-3.9v1.7C739.8,420,739.6,420.2,739.3,420.2Zm-26.9-.8,26.4-.2v-1.7a.47.47,0,0,1,.5-.5h3.9l-.2-27.3-5.4-5.1-25.5.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M973.5,454l-19.3.2a.47.47,0,0,1-.5-.5l-.3-35.7a.47.47,0,0,1,.5-.5l21.6-.2a.47.47,0,0,1,.5.5l.3,31.4a.47.47,0,0,1-.5.5h-1.7v3.7Q974,454,973.5,454Zm-18.9-.8L973,453v-3.7a.47.47,0,0,1,.5-.5h1.7l-.2-30.5-20.6.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M996.9,453.8l-19.1.2a.47.47,0,0,1-.5-.5v-3.7h-1.5a.47.47,0,0,1-.5-.5l-.3-31.4a.47.47,0,0,1,.5-.5l21.2-.2a.47.47,0,0,1,.5.5l.3,35.7A.82.82,0,0,1,996.9,453.8Zm-18.7-.8,18.2-.1-.3-34.7-20.2.2.2,30.5h1.5a.47.47,0,0,1,.5.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M1242.9,504.8l-21.4.2a.47.47,0,0,1-.5-.5l-.3-35.9a.47.47,0,0,1,.5-.5l19.3-.2a.47.47,0,0,1,.5.5v3.9h1.5a.47.47,0,0,1,.5.5l.3,31.4C1243.4,504.6,1243.2,504.8,1242.9,504.8ZM1222,504l20.4-.2-.2-30.5h-1.5a.47.47,0,0,1-.5-.5v-3.9l-18.4.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1264.5,504.6l-21.6.2a.47.47,0,0,1-.5-.5l-.3-31.4a.47.47,0,0,1,.5-.5h1.7v-3.9a.47.47,0,0,1,.5-.5l19.3-.2a.47.47,0,0,1,.5.5l.3,35.9A.43.43,0,0,1,1264.5,504.6Zm-21.1-.8,20.6-.2-.3-34.9-18.4.1v3.9a.47.47,0,0,1-.5.5h-1.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M893.3,348.2l-28.4.2a.47.47,0,0,1-.5-.5l-.2-28.4a.47.47,0,0,1,.5-.5l28.4-.2a.47.47,0,0,1,.5.5l.2,28.4A.54.54,0,0,1,893.3,348.2Zm-28-.7,27.4-.2-.2-27.4-27.4.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M880,279.2l-7.3.1a.47.47,0,0,1-.5-.5l-.1-7.1a.66.66,0,0,1,.4-.5l7.2-1.7a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.1,8.7C880.5,279,880.3,279.2,880,279.2Zm-6.8-.9,6.3-.1-.1-7.6-6.3,1.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M880.2,294.9l-15.7.1a.47.47,0,0,1-.5-.5l-.2-20.8a.66.66,0,0,1,.4-.5l8.4-2.1a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.1,13.8,6.8-.1a.47.47,0,0,1,.5.5l.1,8.5C880.6,294.7,880.4,294.9,880.2,294.9Zm-15.3-.8,14.7-.1-.1-7.5-6.8.1a.47.47,0,0,1-.5-.5l-.1-13.7-7.5,1.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M892.8,294.8l-12.7.1a.47.47,0,0,1-.5-.5l-.2-24.4a.51.51,0,0,1,.4-.5l12.7-3.3a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.2,27.6A.47.47,0,0,1,892.8,294.8Zm-12.2-.8,11.7-.1-.2-26.5-11.7,3.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M897.7,348.2h-4.4a.47.47,0,0,1-.5-.5l-.7-81a.66.66,0,0,1,.4-.5l4.4-1a.6.6,0,0,1,.4.1.52.52,0,0,1,.2.4l.7,82A.47.47,0,0,1,897.7,348.2Zm-4-.9h3.5l-.6-80.9-3.5.8Z" transform="translate(5.92 -69)"></path>
                        <path d="M426.7,281.3h0l-32.3-.5c-.1,0-.3-.1-.3-.2s-.1-.2-.1-.4l.4-5.2.4-1.9,4.9-62c0-.3.3-.5.5-.4l35.7,2.7c.1,0,.2.1.3.2s.1.2.1.4l-5,59.9a.46.46,0,0,1-.5.4h-3.7l.1,6.6a1,1,0,0,1-.1.3C426.9,281.2,426.8,281.3,426.7,281.3ZM395,279.8l31.2.5-.1-6.6a.47.47,0,0,1,.5-.5h3.8l4.9-59-34.7-2.7-4.9,61.6-.4,1.9Z" transform="translate(5.92 -69)"></path>
                        <path d="M433.9,274.1h-3a.76.76,0,0,1-.4-.2c-.1-.1-.1-.2-.1-.4l5-59.9c0-.1.1-.2.2-.3s.2-.1.4-.1l31.1,2.8h4a.5.5,0,0,1,0,1h-4l-30.6-2.7-4.9,58.9h2.5a.47.47,0,0,1,.5.5C434.6,274,434.2,274.1,433.9,274.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M471.4,281.1l-37.9.3c-.1,0-.3-.1-.4-.1s-.1-.2-.1-.4l.3-7.3a.5.5,0,0,1,1,0l-.3,6.7,37.4-.3a.47.47,0,0,1,.5.5C471.9,280.9,471.7,281.1,471.4,281.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1395.1,414.9l-42.9.3a.47.47,0,0,1-.5-.5l-.2-28.6c0-.1,0-.3.1-.3l7-7.1a.37.37,0,0,1,.3-.1l35.9-.3a.47.47,0,0,1,.5.5l.3,35.7A.46.46,0,0,1,1395.1,414.9Zm-35.4-.7,34.9-.3-.3-34.7-35.2.3-6.7,6.8.2,20.9,6.6-.1a.47.47,0,0,1,.5.5Zm-7,.1h6.1v-6.1h-6.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M1140.7,346.2l-243,2a.47.47,0,0,1-.5-.5l-.6-77.6a.47.47,0,0,1,.5-.5h3.5v-4.8a.66.66,0,0,1,.4-.5L1136.1,208a.6.6,0,0,1,.4.1l8.7,6.8a.52.52,0,0,1,.2.4l1,125.1c0,.1,0,.3-.1.3l-5.2,5.3C1140.9,346.2,1140.8,346.2,1140.7,346.2Zm-242.5,1,242.3-1.9,4.9-5-1-124.7-8.4-6.5L901.5,265.2v4.9a.47.47,0,0,1-.5.5h-3.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M901.1,270.6h-4a.47.47,0,0,1-.5-.5v-4.4a.66.66,0,0,1,.4-.5l4-.8h.2a.37.37,0,0,1,.3.1h0l.1.1h0a.37.37,0,0,1,.1.3h0v5.2A.63.63,0,0,1,901.1,270.6Zm-3.5-.9h3.1v-4.2l-3.1.6Z" transform="translate(5.92 -69)"></path>
                        <path d="M61.9,375.7h-.2c-.1,0-.1-.1-.2-.1a.37.37,0,0,1-.1-.3c0-.1.1-.2.1-.3a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.6A.44.44,0,0,1,61.9,375.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M1361,412.3h-.4" transform="translate(5.92 -69)"></path>
                        <path d="M804,419.7l-36.9.3a.47.47,0,0,1-.5-.5l-.2-21.4a.47.47,0,0,1,.5-.5l36.9-.3a.47.47,0,0,1,.5.5l.2,21.4A.54.54,0,0,1,804,419.7Zm-36.4-.7,35.9-.3-.2-20.4-35.9.3Z" transform="translate(5.92 -69)"></path>
                        <path d="M803.8,398.3l-36.9.3a.47.47,0,0,1-.5-.5l-.2-26.8c0-.1,0-.3.1-.3l5.4-5.5a.37.37,0,0,1,.3-.1l31.4-.3a.47.47,0,0,1,.5.5l.3,32.2C804.3,398.1,804.1,398.3,803.8,398.3Zm-36.4-.7,35.9-.3L803,366l-30.7.2-5.1,5.2Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-9" d="M66,380.3h0l-4.5-.4c-.2,0-.3-.2-.3-.4l.4-4.4c0-.1.1-.2.1-.3a.37.37,0,0,1,.3-.1l4.2.6c.2,0,.3.2.3.4l-.2,4.2a.37.37,0,0,1-.1.3C66.2,380.3,66.1,380.3,66,380.3Zm-4-1,3.7.3.1-3.5-3.5-.5Z" transform="translate(5.92 -69)"></path>
                        <path d="M52.7,478.1h0a.54.54,0,0,1-.5-.5l8.9-98c0-.3.3-.5.5-.4l3.9.3.1-3.3-3.8-.5c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l4.2.6a.46.46,0,0,1,.4.5l-.2,4.2c0,.1-.1.3-.2.3s-.2.1-.4.1l-4-.3-8.8,97.5C53.1,477.9,52.9,478.1,52.7,478.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M52.7,478.1a.37.37,0,0,1-.3-.1.49.49,0,0,1-.1-.7A120,120,0,0,0,63.5,353.2a.45.45,0,1,1,.8-.4A121.16,121.16,0,0,1,53,477.9C53,478,52.8,478.1,52.7,478.1Z" transform="translate(5.92 -69)"></path>
                        <path d="M61.9,375.7h0a.54.54,0,0,1-.5-.5l2-22c0-.3.3-.5.5-.4s.5.3.4.5l-2,22C62.4,375.5,62.2,375.7,61.9,375.7Z" transform="translate(5.92 -69)"></path>
                        <path d="M176.3,513.4h0a.47.47,0,0,1-.5-.5l2.1-43.1a.5.5,0,0,1,1,0l-2.1,43.1A.54.54,0,0,1,176.3,513.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M178.4,470.2h0l-40.3-1.7a.5.5,0,0,1,0-1l39.8,1.7,1.7-34.5L146.4,433a.5.5,0,0,1,0-1l33.7,1.7a.47.47,0,0,1,.5.5l-1.7,35.5C178.8,470,178.6,470.2,178.4,470.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M183.1,363.4h0l-36.9-1.7a.47.47,0,0,1-.5-.5c0-.3.2-.4.5-.5l36.4,1.7,1.5-34.9-46.5-2a.5.5,0,1,1,0-1l47,2a.47.47,0,0,1,.5.5l-1.5,35.9c0,.1-.1.2-.2.3S183.3,363.4,183.1,363.4Z" transform="translate(5.92 -69)"></path>
                        <path d="M251.9,271.2h0l-8.9-.5a.5.5,0,1,1,0-1l8.4.5L257,200l-35-2.7c-.3,0-.5-.3-.4-.5s.3-.5.5-.4l35.5,2.7c.1,0,.2.1.3.2s.1.2.1.4l-5.7,71.2C252.4,271,252.2,271.2,251.9,271.2Z" transform="translate(5.92 -69)"></path>
                        <path d="M93,549.5a.37.37,0,0,1-.3-.1.62.62,0,0,1-.1-.7,231.77,231.77,0,0,0,25.2-35.8.51.51,0,1,1,.9.5,233.73,233.73,0,0,1-25.4,35.9C93.2,549.4,93.1,549.5,93,549.5Z" transform="translate(5.92 -69)"></path>
                    </g>
                    <g id="Layer_3" data-name="Layer 3">
                        <path className="plot_block cls-1" d="M314.81,284" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-6" d="M92.39,371.78" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M11.4,544.19c7.67-4,12.88-10.74,13.81-19.63" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-14" d="M192.09,275.76" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-14" d="M193.93,278.21" transform="translate(5.92 -69)"></path>
                        <path className="no_plot_block cls-15" d="M189,263.18a14.83,14.83,0,1,0,19.63,7.36A14.94,14.94,0,0,0,189,263.18" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-16" d="M35.63,275.45" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-16" d="M75.21,277.29" transform="translate(5.92 -69)"></path>
                        <line className="plot_block cls-13" x1="52.6" y1="237.44" x2="31.12" y2="455.86"></line>
                        <path className="plot_block cls-16" d="M35.63,532.23" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-16" d="M42.08,534.07" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-17" d="M35,524.25" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-17" d="M37.17,510.75" transform="translate(5.92 -69)"></path>
                        <path className="no_plot_block cls-13" d="M42.69,236.18A29.74,29.74,0,0,0,28,258.88,29.08,29.08,0,0,0,38.09,284" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M47,306.13A25.52,25.52,0,0,0,38.09,284" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-14" d="M192.09,275.76" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-14" d="M193.93,278.21" transform="translate(5.92 -69)"></path>
                        <circle className="no_plot_block cls-18" cx="65.79" cy="192.95" r="17.49"></circle>
                        <path className="plot_block cls-13" d="M40.24,237.41A26.07,26.07,0,0,0,54,216.85" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M57.42,182.49c-1.84,11.66-1.84,23.93-3.37,34.67" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-19" d="M55.88,233.11" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-19" d="M62.32,235.26" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-19" d="M63.86,216.55" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-19" d="M54.65,214.4" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-16" d="M2.19,825.21" transform="translate(5.92 -69)"></path>
                        <path className="no_plot_block cls-13" d="M12.32,544.19A35.81,35.81,0,0,0-5.47,571.8a35.18,35.18,0,0,0,12,30.37" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M17.53,628.56A32.28,32.28,0,0,0,7.1,601.87" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-17" d="M35.94,509.83" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-20" d="M17.53,537.14" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-17" d="M40.24,503.08" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M63.86,607.7c-7.67,4-12.88,10.74-13.81,19.63" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-13" d="M50.05,626.41c0,1.53-.31,2.76-.31,4.3" transform="translate(5.92 -69)"></path>
                        <path d="M24.59,404.91a3.75,3.75,0,0,1-2.76,6.75,4.26,4.26,0,0,1-3.07-1.53,3.94,3.94,0,0,1-1.23-3.07h0a2.75,2.75,0,0,1,3.07-2.45c.31,0,.61,0,.61.61a38.8,38.8,0,0,1-.31,4.91,3.06,3.06,0,0,0,3.68-2.45,2.92,2.92,0,0,0-.61-2.15Zm-6.44,2.45c0,.92.61,1.53,2.15,2.15,0-.92.31-1.84.31-3.07,0-.31,0-.31-.31-.61a2.31,2.31,0,0,0-2.15,1.53Z" transform="translate(5.92 -69)"></path>
                        <path d="M27.35,393.56h-.61c0-.92,0-1.23-1.23-1.53l-3.07-.31c-1.23,0-2.15.31-2.15,1.53a7.8,7.8,0,0,0,.61,2.15h.61l3.37.31c1.23,0,1.23,0,1.53-.92H27l-.31,4h-.61c0-1.23,0-1.23-1.23-1.53l-3.07-.31c-1.23,0-2.15.31-2.15,1.23a5.69,5.69,0,0,0,.61,2.15l4.29.31c1.23,0,1.23,0,1.53-.92h.61l-.31,4h-.61c0-1.23,0-1.53-1.23-1.53l-3.68-.31c-1.23,0-1.23,0-1.53.92h-.61c0-.92-.31-1.84-.31-2.76H20a6.71,6.71,0,0,1-.92-1.53,2.77,2.77,0,0,1-.31-1.53q0-1.38,1.84-1.84a6.71,6.71,0,0,0-.92-1.53,2.3,2.3,0,0,1-.31-1.53c0-1.53,1.23-2.45,3.07-2.15l3.37.31c1.23,0,1.23,0,1.53-.92H28Z" transform="translate(5.92 -69)"></path>
                        <path d="M17.23,386.51c-.61,0-.92-.61-.92-1.23s.61-.92,1.23-.92.92.61.92,1.23C18.15,385.89,17.53,386.51,17.23,386.51Zm10.43,1.84H27c0-1.23,0-1.23-1.23-1.53l-3.68-.31c-1.23,0-1.23,0-1.53.92H20.3c0-.92-.31-1.84-.31-2.76l6.14.61c1.23,0,1.23,0,1.53-1.23h.61Z" transform="translate(5.92 -69)"></path>
                        <path d="M22.44,379.76c-.92-.61-1.84-1.53-1.84-2.45,0-.61.61-1.23.92-.92.31,0,.92.31.92.61v.61a1.13,1.13,0,0,0-.31.92,1.57,1.57,0,0,0,1.23,1.53l3.37.31c1.23,0,1.23,0,1.53-1.53h.61l-.31,4.29H28c0-1.23,0-1.23-1.23-1.53l-3.68-.31c-1.23,0-1.23,0-1.53.92h-.31a9,9,0,0,0-.61-2.76l1.84.31Z" transform="translate(5.92 -69)"></path>
                        <path d="M29.81,370.25c0,.31-.31.61-.31.92a1.13,1.13,0,0,1-.92.31c.31.92.92,1.84.92,2.15A2.17,2.17,0,0,1,27,375.77c-.92,0-1.53-.61-1.53-1.84a19.06,19.06,0,0,0-.92-3.07h-.31c-1.23,0-2.15.31-2.15,1.23a1.13,1.13,0,0,0,.31.92,1.59,1.59,0,0,0,1.23.61c.31,0,.31.31.31.61s-.31.92-.92.61a.66.66,0,0,1-.61-.61,7.26,7.26,0,0,1-.92-3.07,2.34,2.34,0,0,1,.61-1.53,3.31,3.31,0,0,1,2.45-.61l3.37.31c.92,0,1.23-.31,1.23-.61v-.61h.61Zm-4.6,1.23a3.24,3.24,0,0,0,.61,1.53c.31.61.61,1.23,1.23,1.23A1.4,1.4,0,0,0,28.58,373a5.18,5.18,0,0,0-.61-1.53Z" transform="translate(5.92 -69)"></path>
                        <path d="M30.11,364.11a1.13,1.13,0,0,1,.31.92c0,1.23-.92,1.84-2.45,1.84l-5.22-.61v1.23h-.31l-.61-.61V366H20.6l-1.23-1.53v-.31l2.45.31.31-2.15c.31-.31.61,0,.61.31l-.31,1.84L27,365c1.53,0,1.84-.61,1.84-.92a1.84,1.84,0,0,0-.31-1.23h.61Z" transform="translate(5.92 -69)"></path>
                        <path d="M29.5,355.21A3.75,3.75,0,0,1,26.74,362a4.26,4.26,0,0,1-3.07-1.53,3.94,3.94,0,0,1-1.23-3.07h0a2.75,2.75,0,0,1,3.07-2.45c.31,0,.61,0,.61.61a38.8,38.8,0,0,1-.31,4.91A3.06,3.06,0,0,0,29.5,358a2.92,2.92,0,0,0-.61-2.15ZM23.36,358c0,.92.61,1.53,2.15,2.15,0-.92.31-1.84.31-3.07,0-.31,0-.31-.31-.61-1.23,0-2.15.31-2.15,1.53Z" transform="translate(5.92 -69)"></path>
                        <path d="M26.12,348.77A2.69,2.69,0,0,0,24,350.61a1.12,1.12,0,0,0,1.23,1.23c.61,0,1.23-.61,1.53-1.53a3.28,3.28,0,0,1,2.76-2.15,2.62,2.62,0,0,1,2.15,3.37,3.51,3.51,0,0,1-.92,2.15H28.58v-.61c1.23-.31,2.45-.92,2.45-2.15a1.57,1.57,0,0,0-1.23-1.53A2.22,2.22,0,0,0,28,350.61c-.61.92-1.23,2.15-2.76,2.15-1.23,0-2.15-1.23-2.15-3.07a1.8,1.8,0,0,1,.61-1.53c.31,0,1.53-.31,2.15-.31Z" transform="translate(5.92 -69)"></path>
                        <path d="M26.43,339c-.92-.61-1.84-1.53-1.84-2.45a1,1,0,0,1,.92-.92c.31,0,.92.31.92.61v.61a1.13,1.13,0,0,0-.31.92,1.57,1.57,0,0,0,1.23,1.53l3.37.31c1.23,0,1.23,0,1.53-1.53h.61l-.31,4.29H32c0-1.23,0-1.23-1.23-1.53L27,340.49c-1.23,0-1.23,0-1.53.92H25.2a9,9,0,0,0-.61-2.76l1.84.31Z" transform="translate(5.92 -69)"></path>
                        <path d="M25.51,330.36a4,4,0,0,1,4.6-3.68,4.31,4.31,0,0,1,4,4.6A3.8,3.8,0,0,1,29.81,335c-3.37-.31-4.6-2.76-4.29-4.6Zm.61.31c0,1.23.92,2.15,3.07,2.45,2.45.31,4-.61,4.29-2.15,0-.92-.61-2.15-3.07-2.45s-4.29.61-4.29,2.15Z" transform="translate(5.92 -69)"></path>
                        <path d="M35,319.93c0,.31-.31.61-.31.92a1.13,1.13,0,0,1-.92.31c.31.92.92,1.84.92,2.15a2.17,2.17,0,0,1-2.45,2.15c-.92,0-1.53-.61-1.53-1.84a19.06,19.06,0,0,0-.92-3.07H29.5c-1.23,0-2.15.31-2.15,1.23a1.13,1.13,0,0,0,.31.92,1.59,1.59,0,0,0,1.23.61c.31,0,.31.31.31.61s-.31.92-.92.61a.66.66,0,0,1-.61-.61,7.26,7.26,0,0,1-.92-3.07,2.34,2.34,0,0,1,.61-1.53,3.31,3.31,0,0,1,2.45-.61l3.37.31c.92,0,1.23-.31,1.23-.61v-.61H35Zm-4.91.92a3.24,3.24,0,0,0,.61,1.53c.31.61.61,1.23,1.23,1.23a1.4,1.4,0,0,0,1.53-1.23,5.18,5.18,0,0,0-.61-1.53Z" transform="translate(5.92 -69)"></path>
                        <path d="M35.33,308.28a14.09,14.09,0,0,0,.31,3.07H34.41l.61,1.23a4.11,4.11,0,0,1,.31,1.53A3.69,3.69,0,0,1,31,317.17,4.76,4.76,0,0,1,27,312a2.32,2.32,0,0,1,.31-1.23l-2.45-.31c-1.23,0-1.23,0-1.53,1.23h-.61c0-.92-.31-2.45-.31-3.07l10.74,1.23c.92,0,1.23,0,1.23-.61v-.61Zm-6.14,2.45c-.61.31-.92,1.23-1.23,1.84,0,.92.31,2.45,3.07,2.76,2.15.31,3.37-.92,3.37-2.15a3.89,3.89,0,0,0-.31-1.84Z" transform="translate(5.92 -69)"></path>
                        <circle className="no_plot_block cls-18" cx="40.94" cy="507.1" r="19.33"></circle>
                    </g>
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path className="plot_block cls-12" d="M567.46,477.14" transform="translate(5.92 -69)"></path>
                        <rect className="plot_block cls-21" id="id_w040" data-name="id w040" x="724.46" y="408.46" width="9.76" height="10.07"></rect>
                        <rect className="plot_block cls-21" id="id_w039" data-name="id w039" x="715.02" y="408.46" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w038" data-name="id w038" x="704.95" y="408.46" width="9.76" height="10.39"></rect>
                        <rect className="plot_block cls-21" id="id_w037" data-name="id w037" x="695.5" y="408.46" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w042" data-name="id w042" x="718.17" y="423.57" width="9.76" height="10.7"></rect>

                        <rect className="plot_block cls-21" id="id_w133" data-name="id w133" x="718.17" y="443.71" width="9.76" height="11.65"></rect>

                        <rect className="plot_block cls-21" id="id_w134" data-name="id w134" x="727.61" y="444.03" width="12.59" height="11.33"></rect>

                        <rect className="plot_block cls-21" id="id_w132" data-name="id w132" x="708.09" y="443.71" width="9.76" height="11.65"></rect>
                        <rect className="plot_block cls-21" id="id_w131" data-name="id w131" x="698.65" y="443.71" width="9.76" height="11.65"></rect>
                        <rect className="plot_block cls-21" id="id_w130" data-name="id w130" x="688.89" y="443.71" width="9.76" height="11.65"></rect>
                        <rect className="plot_block cls-21" id="id_w129" data-name="id w129" x="679.14" y="444.03" width="9.76" height="11.65"></rect>
                        <rect className="plot_block cls-21" id="id_w128" data-name="id w128" x="669.38" y="444.34" width="9.76" height="11.65"></rect>

                        <rect className="plot_block cls-21" id="id_w138" data-name="id w138" x="666.23" y="461.02" width="9.76" height="10.7"></rect>

                        <rect className="plot_block cls-21" id="id_w139" data-name="id w139" x="656.16" y="461.02" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w140" data-name="id w140" x="646.4" y="461.02" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w141" data-name="id w141" x="634.44" y="461.02" width="11.96" height="10.39"></rect>

                        <rect className="plot_block cls-21" id="id_w142" data-name="id w142" x="614.61" y="461.34" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w143" data-name="id w143" x="604.85" y="461.65" width="9.44" height="10.39"></rect>
                        <rect className="plot_block cls-21" id="id_w144" data-name="id w144" x="595.41" y="461.65" width="9.44" height="10.39"></rect>
                        <rect className="plot_block cls-21" id="id_w145" data-name="id w145" x="585.34" y="461.65" width="9.44" height="10.39"></rect>
                        <rect className="plot_block cls-21" id="id_w146" data-name="id w146" x="575.58" y="461.65" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w147" data-name="id w147" x="548.83" y="461.65" width="9.76" height="11.33"></rect>
                        <rect className="plot_block cls-21" id="id_w148" data-name="id w148" x="539.39" y="461.65" width="9.44" height="11.33"></rect>
                        <rect className="plot_block cls-21" id="id_w149" data-name="id w149" x="529.63" y="461.65" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w150" data-name="id w150" x="520.5" y="461.97" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w151" data-name="id w151" x="510.11" y="461.97" width="10.07" height="11.02"></rect>

                        <rect className="plot_block cls-21" id="id_w114" data-name="id w114" x="510.11" y="446.23" width="10.7" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w115" data-name="id w115" x="520.5" y="446.23" width="10.07" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w116" data-name="id w116" x="530.57" y="446.23" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w117" data-name="id w117" x="540.01" y="446.23" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w118" data-name="id w118" x="549.46" y="445.91" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w119" data-name="id w119" x="559.53" y="445.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w120" data-name="id w120" x="569.29" y="445.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w121" data-name="id w121" x="579.04" y="445.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w122" data-name="id w122" x="588.8" y="445.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w123" data-name="id w123" x="598.56" y="445.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w124" data-name="id w124" x="608" y="445.6" width="9.44" height="11.02"></rect>

                        <rect className="plot_block cls-21" x="617.44" y="445.6" width="6.3" height="4.09"></rect>
                        <rect className="plot_block cls-21" x="617.44" y="429.55" width="6.3" height="5.67"></rect>
                        <rect className="plot_block cls-21" x="617.44" y="424.2" width="3.15" height="5.67"></rect>
                        <rect className="plot_block cls-21" x="620.59" y="424.2" width="3.15" height="5.67"></rect>

                        <rect className="plot_block cls-21" id="id_w051" data-name="id w051" x="608" y="424.2" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w052" data-name="id w052" x="598.24" y="424.2" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w053" data-name="id w053" x="588.8" y="424.51" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w054" data-name="id w054" x="579.04" y="424.51" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w055" data-name="id w055" x="569.29" y="424.83" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w056" data-name="id w056" x="559.21" y="425.14" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w057" data-name="id w057" x="549.46" y="425.14" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w058" data-name="id w058" x="540.01" y="425.14" width="9.13" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w059" data-name="id w059" x="530.26" y="425.14" width="10.07" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w060" data-name="id w060" x="520.5" y="425.14" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w061" data-name="id w061" x="509.48" y="425.14" width="10.7" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w023" data-name="id w023" x="509.48" y="409.72" width="10.7" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w022" data-name="id w022" x="488.08" y="410.03" width="11.33" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w062" data-name="id w062" x="488.4" y="425.45" width="11.02" height="11.02"></rect>

                        <rect className="plot_block cls-21" id="id_w113" data-name="id w113" x="488.4" y="446.23" width="11.02" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w112" data-name="id w112" x="478.32" y="446.23" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w111" data-name="id w111" x="469.19" y="446.54" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w110" data-name="id w110" x="459.12" y="446.54" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w109" data-name="id w109" x="449.68" y="446.54" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w108" data-name="id w108" x="439.92" y="446.54" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w107" data-name="id w107" x="430.17" y="446.86" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w106" data-name="id w106" x="420.41" y="446.86" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w105" data-name="id w105" x="410.65" y="446.86" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w104" data-name="id w104" x="401.21" y="447.17" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w103" data-name="id w103" x="391.45" y="447.17" width="9.76" height="11.02"></rect>

                        <rect className="plot_block cls-21" id="id_w161" data-name="id w161" x="385.16" y="462.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w160" data-name="id w160" x="394.91" y="462.6" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w159" data-name="id w159" x="404.36" y="462.6" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w158" data-name="id w158" x="414.74" y="462.6" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w157" data-name="id w157" x="424.19" y="462.6" width="9.76" height="11.33"></rect>
                        <rect className="plot_block cls-21" id="id_w156" data-name="id w156" x="450.31" y="462.6" width="9.13" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w155" data-name="id w155" x="459.75" y="462.28" width="9.13" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w154" data-name="id w154" x="469.19" y="462.28" width="9.13" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w153" data-name="id w153" x="478.95" y="462.28" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w152" data-name="id w152" x="488.71" y="461.97" width="11.02" height="10.7"></rect>


                        <rect className="plot_block cls-21" x="384.84" y="447.17" width="6.92" height="5.98"></rect>
                        <rect className="plot_block cls-21" x="384.84" y="453.15" width="3.46" height="5.35"></rect>
                        <rect className="plot_block cls-21" x="388.3" y="453.15" width="3.46" height="5.35"></rect>
                        <rect className="plot_block cls-21" id="id_w063" data-name="id w063" x="478.64" y="425.45" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w064" data-name="id w064" x="469.19" y="425.45" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w065" data-name="id w065" x="459.12" y="425.45" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w066" data-name="id w066" x="449.68" y="425.45" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w067" data-name="id w067" x="439.61" y="425.77" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w068" data-name="id w068" x="430.17" y="425.77" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w069" data-name="id w069" x="420.41" y="425.77" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w070" data-name="id w070" x="410.97" y="425.77" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w071" data-name="id w071" x="400.58" y="426.08" width="10.07" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w072" data-name="id w072" x="391.14" y="426.08" width="9.44" height="11.33"></rect>
                        <rect className="plot_block cls-21" x="385.16" y="426.08" width="5.98" height="4.41"></rect>
                        <rect className="plot_block cls-21" x="385.16" y="430.49" width="5.98" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="385.16" y="433.95" width="3.15" height="3.15"></rect>
                        <rect className="plot_block cls-21" x="388.3" y="433.95" width="3.15" height="3.15"></rect>
                        <rect className="plot_block cls-21" id="id_w021" data-name="id w021" x="478.32" y="410.03" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w020" data-name="id w020" x="468.57" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w019" data-name="id w019" x="459.12" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w018" data-name="id w018" x="450.62" y="410.35" width="8.5" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w024" data-name="id w024" x="520.19" y="409.72" width="10.07" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w025" data-name="id w025" x="529.94" y="409.72" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w026" data-name="id w026" x="539.39" y="409.4" width="9.44" height="11.33"></rect>
                        <rect className="plot_block cls-21" id="id_w027" data-name="id w027" x="549.14" y="409.09" width="8.81" height="11.65"></rect>
                        <rect className="plot_block cls-21" x="617.44" y="449.38" width="6.3" height="3.15"></rect>
                        <rect className="plot_block cls-21" x="620.59" y="452.84" width="3.15" height="3.15"></rect>
                        <rect className="plot_block cls-21" x="617.76" y="452.84" width="2.52" height="3.15"></rect>

                        <rect className="plot_block cls-21" id="id_w127" data-name="id w127" x="659.62" y="444.65" width="9.76" height="11.33"></rect>
                        <rect className="plot_block cls-21" id="id_w126" data-name="id w126" x="649.86" y="444.65" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w125" data-name="id w125" x="640.42" y="444.97" width="9.76" height="10.7"></rect>

                        <rect className="plot_block cls-21" x="633.81" y="444.97" width="6.61" height="5.98"></rect>
                        <rect className="plot_block cls-21" x="633.81" y="450.64" width="3.15" height="5.04"></rect>
                        <rect className="plot_block cls-21" x="637.27" y="450.64" width="3.15" height="5.04"></rect>

                        <rect className="plot_block cls-21" id="id_w043" data-name="id w043" x="708.09" y="423.57" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w044" data-name="id w044" x="698.65" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w045" data-name="id w045" x="688.89" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w046" data-name="id w046" x="679.14" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w047" data-name="id w047" x="669.06" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w048" data-name="id w048" x="659.31" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w049" data-name="id w049" x="649.23" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w050" data-name="id w050" x="640.11" y="423.88" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" x="633.5" y="423.88" width="6.61" height="4.09"></rect>
                        <rect className="plot_block cls-21" x="633.5" y="427.97" width="6.61" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="636.64" y="431.75" width="3.46" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="633.81" y="431.75" width="2.83" height="3.46"></rect>
                        <rect className="plot_block cls-21" id="id_w041" data-name="id w041" x="727.61" y="423.57" width="16.37" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w036" data-name="id w036" x="664.34" y="408.46" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" x="673.79" y="408.46" width="3.46" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w035" data-name="id w035" x="654.27" y="408.46" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w034" data-name="id w034" x="644.83" y="408.46" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w033" data-name="id w033" x="635.07" y="408.46" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w032" data-name="id w032" x="613.98" y="408.77" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w031" data-name="id w031" x="604.54" y="408.77" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w030" data-name="id w030" x="594.78" y="409.09" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w029" data-name="id w029" x="585.02" y="409.09" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w028" data-name="id w028" x="574.95" y="409.09" width="10.07" height="11.02"></rect>
                        <rect className="plot_block cls-21" x="633.5" y="408.46" width="1.89" height="11.02"></rect>
                        <polyline className="plot_block cls-21" points="734.53 408.46 738.31 408.14 743.35 412.55 743.35 419.16 734.22 418.85"></polyline>
                        <path className="plot_block cls-21" d="M721.37,529.39l10.39-.31-2.2,2.52-2.83,2.83c-.31,0-4.09,2.2-4.09,2.2l-1.26.31" transform="translate(5.92 -69)"></path>

                        <rect className="plot_block cls-21" id="id_w135" data-name="id w135" x="718.8" y="460.71" width="8.18" height="7.55"></rect>

                        <rect className="plot_block cls-21" id="id_w136" data-name="id w136" x="710.61" y="460.71" width="8.18" height="7.55"></rect>
                        <rect className="plot_block cls-21" id="id_w137" data-name="id w137" x="696.13" y="460.71" width="14.16" height="10.39"></rect>

                        <rect className="plot_block cls-21" id="id_w017" data-name="id w017" x="414.11" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w016" data-name="id w016" x="404.36" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w015" data-name="id w015" x="394.6" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w014" data-name="id w014" x="384.84" y="410.35" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w013" data-name="id w013" x="364.7" y="410.66" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w073" data-name="id w073" x="358.09" y="426.71" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w074" data-name="id w074" x="348.64" y="426.71" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w075" data-name="id w075" x="338.57" y="426.71" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w076" data-name="id w076" x="328.81" y="427.03" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w077" data-name="id w077" x="319.06" y="427.03" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w078" data-name="id w078" x="309.3" y="427.03" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w079" data-name="id w079" x="299.54" y="427.03" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w080" data-name="id w080" x="290.1" y="427.34" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w081" data-name="id w081" x="280.34" y="427.34" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w082" data-name="id w082" x="270.9" y="427.66" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w083" data-name="id w083" x="261.14" y="427.66" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w084" data-name="id w084" x="251.38" y="427.66" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w085" data-name="id w085" x="241.63" y="427.66" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w086" data-name="id w086" x="231.87" y="427.66" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w087" data-name="id w087" x="218.96" y="427.97" width="12.9" height="10.7"></rect>


                        <rect className="plot_block cls-21" id="id_w088" data-name="id w088" x="218.96" y="448.75" width="13.53" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w089" data-name="id w089" x="232.5" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w090" data-name="id w090" x="242.26" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w091" data-name="id w091" x="251.7" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w092" data-name="id w092" x="261.14" y="448.75" width="10.07" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w093" data-name="id w093" x="271.53" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w094" data-name="id w094" x="280.97" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w095" data-name="id w095" x="290.73" y="448.75" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w096" data-name="id w096" x="300.17" y="448.43" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w097" data-name="id w097" x="309.93" y="448.43" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w098" data-name="id w098" x="319.69" y="448.12" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w099" data-name="id w099" x="329.44" y="448.12" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w100" data-name="id w100" x="339.52" y="447.8" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w101" data-name="id w101" x="348.96" y="447.8" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w102" data-name="id w102" x="358.72" y="447.8" width="9.44" height="10.7"></rect>

                        <rect className="plot_block cls-21" id="id_w162" data-name="id w162" x="365.01" y="463.23" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w163" data-name="id w163" x="355.57" y="463.23" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w164" data-name="id w164" x="345.81" y="463.23" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w165" data-name="id w165" x="336.05" y="463.23" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w166" data-name="id w166" x="326.3" y="463.23" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w167" data-name="id w167" x="316.22" y="463.54" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w168" data-name="id w168" x="306.47" y="463.54" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w169" data-name="id w169" x="276.88" y="463.85" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w170" data-name="id w170" x="267.12" y="463.85" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w171" data-name="id w171" x="257.36" y="463.85" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w172" data-name="id w172" x="247.61" y="464.17" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w173" data-name="id w173" x="237.85" y="464.17" width="9.44" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w174" data-name="id w174" x="228.72" y="464.17" width="9.44" height="10.7"></rect>

                        <rect className="plot_block cls-21" x="368.47" y="447.8" width="6.3" height="3.78"></rect>
                        <rect className="plot_block cls-21" x="368.47" y="451.26" width="6.3" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="368.47" y="454.73" width="3.15" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="371.62" y="454.73" width="3.15" height="3.46"></rect>
                        <rect className="plot_block cls-21" x="367.53" y="432.06" width="5.98" height="5.67"></rect>
                        <rect className="plot_block cls-21" x="367.53" y="426.4" width="3.15" height="5.35"></rect>
                        <rect className="plot_block cls-21" x="370.68" y="426.4" width="3.15" height="5.35"></rect>
                        <rect className="plot_block cls-21" id="id_w012" data-name="id w012" x="354.94" y="410.66" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w011" data-name="id w011" x="345.18" y="410.98" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w010" data-name="id w010" x="335.11" y="410.98" width="9.76" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w009" data-name="id w009" x="325.67" y="411.29" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" x="316.22" y="411.29" width="9.44" height="11.02"></rect>
                        <rect className="plot_block cls-21" id="id_w007" data-name="id w007" x="306.47" y="411.61" width="9.44" height="11.02"></rect>
                        <polyline className="plot_block cls-21" points="219.28 464.48 228.09 464.48 228.09 475.19 221.8 475.19 218.97 472.98"></polyline>
                        <rect className="plot_block cls-21" id="id_w006" data-name="id w006" x="276.56" y="412.24" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w005" data-name="id w005" x="266.49" y="412.24" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w004" data-name="id w004" x="257.05" y="412.24" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w003" data-name="id w003" x="246.98" y="412.24" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w002" data-name="id w002" x="237.22" y="412.24" width="9.76" height="10.7"></rect>
                        <rect className="plot_block cls-21" id="id_w001" data-name="id w001" x="227.78" y="412.24" width="9.76" height="10.7"></rect>
                        <polyline className="plot_block cls-21" points="227.46 412.24 228.72 422.62 220.85 422.94 220.85 416.96 225.89 412.24"></polyline>
                        <path className="plot_block cls-22" d="M364.76,509.56h-3.46L359.72,508l-133.46,1.26-1.89,1.57H220s-2.52-.63-3.15-2.2h-6.3l.94-13.53-3.78-.31h0s-.63-8.18,4.72-13.53c3.15-3.15,7.55-4.72,13.53-4.72h0v3.15l48.79-.31V476.2h0s9.44,0,9.44,9.44v6.61h12.28v-6.61a10,10,0,0,1,9.13-9.76h.31v3.46l59.49-.63v-3.46h.31s5.67.63,5.67,5.35l.31,23.61a5.26,5.26,0,0,1-.94,2.83c-.94.63-2.52,2.52-6,2.52m-3.46-.31h3.46a6.35,6.35,0,0,0,5.67-2.83,7.28,7.28,0,0,0,.63-2.83L370.74,480c0-4.09-4.41-4.72-5.35-5v3.46l-60.12.63v-3.46c-8.5,1.26-8.5,9.44-8.5,9.44v6.61H284.18V485c0-8.81-7.87-9.13-9.13-9.13V479l-49.42.31V476.2q-8.5,0-13.22,4.72c-5,5-4.72,12.28-4.72,13.22l3.78.31L210.53,508h6.3a4.44,4.44,0,0,0,3.15,2.2h4.41l1.89-1.57L360,507.36Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M364.76,509.56h-3.46L359.72,508l-133.46,1.26-1.89,1.57H220s-2.52-.63-3.15-2.2h-6.3l.94-13.53-3.78-.31h0s-.63-8.18,4.72-13.53c3.15-3.15,7.55-4.72,13.53-4.72h0v3.15l48.79-.31V476.2h0s9.44,0,9.44,9.44v6.61h12.28v-6.61a10,10,0,0,1,9.13-9.76h.31v3.46l59.49-.63v-3.46h.31s5.67.63,5.67,5.35l.31,23.61a5.26,5.26,0,0,1-.94,2.83C369.79,507.67,368.22,509.56,364.76,509.56Zm-3.46-.31h3.46a6.35,6.35,0,0,0,5.67-2.83,7.28,7.28,0,0,0,.63-2.83L370.74,480c0-4.09-4.41-4.72-5.35-5v3.46l-60.12.63v-3.46c-8.5,1.26-8.5,9.44-8.5,9.44v6.61H284.18V485c0-8.81-7.87-9.13-9.13-9.13V479l-49.42.31V476.2q-8.5,0-13.22,4.72c-5,5-4.72,12.28-4.72,13.22l3.78.31L210.53,508h6.3a4.44,4.44,0,0,0,3.15,2.2h4.41l1.89-1.57L360,507.36Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M280.09,491.62l-67.36.63v-5.67l6.3-5.35,61.06-.63Zm-67,.31,66.73-.63V480.92l-60.75.63-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M280.09,491.62l-67.36.63v-5.67l6.3-5.35,61.06-.63Zm-67,.31,66.73-.63V480.92l-60.75.63-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M221.86,491.94h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M221.86,491.94h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M231.61,491.94c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M231.61,491.94c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M241.06,491.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M241.06,491.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M251.13,491.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M251.13,491.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M260.57,491.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M260.57,491.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M270.33,491.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M270.33,491.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M367.9,506.73,212.73,508V496.66L367.9,495.4ZM213,507.67l154.54-1.26v-10.7L213,497Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M367.9,506.73,212.73,508V496.66L367.9,495.4ZM213,507.67l154.54-1.26v-10.7L213,497Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M225.95,507.67h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M225.95,507.67h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M235.71,507.67h0l-.31-10.7h0l.31,10.7c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M235.71,507.67h0l-.31-10.7h0l.31,10.7C236,507.36,236,507.67,235.71,507.67Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M245.46,507.36h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M245.46,507.36h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M254.91,507.36h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M254.91,507.36h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M264.66,507.36h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M264.66,507.36h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M274.74,507.36c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M274.74,507.36c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M284.18,507h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M284.18,507h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M293.94,507h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M293.94,507h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M303.69,507h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M303.69,507h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M313.14,507h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M313.14,507h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M322.89,506.73h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M322.89,506.73h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M332.65,506.73h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M332.65,506.73h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M342.41,506.73h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M342.41,506.73h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M352.17,506.73c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M352.17,506.73c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M361.61,506.42h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M361.61,506.42h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M364.76,501.06h0l-.31-5.35h0l.31,5.35h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M364.76,501.06h0l-.31-5.35h0l.31,5.35Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M367.59,500.75h0c.31-.31.31,0,0,0h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M367.59,500.75h0c.31-.31.31,0,0,0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M365.7,548.59V544.5l-61.38.63v3.46H304s-6.3-2.2-7.24-7.87v-7.87h-12v8.5s-1.89,7.24-7.55,7.55h-.31v-3.78l-51.31.31v3.78h-5s-12.59-.63-15.42-13.53V530h4.09l.63-13.22h6.3a6.69,6.69,0,0,1,3.15-2.2h4.09l1.57,1.57,134.72-1.26,1.57-1.57h4.41s5.35.94,5.67,5.35l.31,24.24c0,.31-.31,5-6,5.67ZM304,544.82l62-.63v4.09a6,6,0,0,0,5.67-5.35l-.31-24.24c-.31-4.09-5.35-5-5.35-5h-4.41L360,515.23l-135,1.26-1.57-1.57h-4.09a5.6,5.6,0,0,0-2.83,2.2h-6.3l-.63,13.22h-4.09v5.35A16.31,16.31,0,0,0,214.3,547a23.45,23.45,0,0,0,6.61,1.89h4.72v-3.78l51.93-.31v3.78c5-.31,6.92-6.92,7.24-7.24v-8.81H297.4v8.18c.94,4.72,5.67,6.92,6.92,7.55Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M365.7,548.59V544.5l-61.38.63v3.46H304s-6.3-2.2-7.24-7.87v-7.87h-12v8.5s-1.89,7.24-7.55,7.55h-.31v-3.78l-51.31.31v3.78h-5s-12.59-.63-15.42-13.53V530h4.09l.63-13.22h6.3a6.69,6.69,0,0,1,3.15-2.2h4.09l1.57,1.57,134.72-1.26,1.57-1.57h4.41s5.35.94,5.67,5.35l.31,24.24c0,.31-.31,5-6,5.67ZM304,544.82l62-.63v4.09a6,6,0,0,0,5.67-5.35l-.31-24.24c-.31-4.09-5.35-5-5.35-5h-4.41L360,515.23l-135,1.26-1.57-1.57h-4.09a5.6,5.6,0,0,0-2.83,2.2h-6.3l-.63,13.22h-4.09v5.35A16.31,16.31,0,0,0,214.3,547a23.45,23.45,0,0,0,6.61,1.89h4.72v-3.78l51.93-.31v3.78c5-.31,6.92-6.92,7.24-7.24v-8.81H297.4v8.18c.94,4.72,5.67,6.92,6.92,7.55Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M368.85,527.5,213,528.76v-11l155.8-1.26Zm-155.49.94,155.17-1.26V516.8l-155.17,1.26Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M368.85,527.5,213,528.76v-11l155.8-1.26Zm-155.49.94,155.17-1.26V516.8l-155.17,1.26Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M226.58,528.45h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M226.58,528.45h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M236.34,528.45h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M236.34,528.45h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M246.09,528.45c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M246.09,528.45c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M255.54,528.13h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M255.54,528.13h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M265.61,528.13h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M265.61,528.13h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M275.05,528.13h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M275.05,528.13h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M284.81,528.13h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M284.81,528.13h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M294.57,527.82h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M294.57,527.82h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M304,527.82h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M304,527.82h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M313.77,527.82h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M313.77,527.82h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M323.52,527.82h0l-.31-10.7h0l.31,10.7c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M323.52,527.82h0l-.31-10.7h0l.31,10.7C323.84,527.5,323.84,527.82,323.52,527.82Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M333.6,527.5h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M333.6,527.5h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M343.35,527.5h0L343,516.8h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M343.35,527.5h0L343,516.8h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M352.8,527.5h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M352.8,527.5h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M362.55,527.5h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M362.55,527.5h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M368.53,520.58h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M368.53,520.58h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M368.53,523.73h0c0-.31.31,0,0,0h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M368.53,523.73h0c0-.31.31,0,0,0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M365.7,527.5h0l-.31-3.78h0l.31,3.78h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M365.7,527.5h0l-.31-3.78h0l.31,3.78Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M368.85,543.24l-68.62.63V532.54l68.62-.63Zm-68.3.31,68-.63v-10.7l-68,.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M368.85,543.24l-68.62.63V532.54l68.62-.63Zm-68.3.31,68-.63v-10.7l-68,.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M310.62,543.56h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M310.62,543.56h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M320.38,543.24h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M320.38,543.24h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M330.13,543.24h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M330.13,543.24h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M339.89,543.24h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M339.89,543.24h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M349.65,543.24c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M349.65,543.24c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M359.09,542.93h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M359.09,542.93h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M280.72,543.87l-64.84.63L213.36,542v-8.81l67.36-.63Zm-64.84.31,64.52-.63v-10.7l-66.73.63V542Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M280.72,543.87l-64.84.63L213.36,542v-8.81l67.36-.63Zm-64.84.31,64.52-.63v-10.7l-66.73.63V542Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M222.49,544.5h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M222.49,544.5h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M231.93,544.5h0l-.31-11h0l.31,11c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M231.93,544.5h0l-.31-11h0l.31,11C232.24,544.19,232.24,544.5,231.93,544.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M241.69,544.19h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M241.69,544.19h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M251.13,544.19h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M251.13,544.19h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M261.2,544.19h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M261.2,544.19h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M271,543.87h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M271,543.87h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M368.53,491.31l-68,.63V480.29l68-.63Zm-67.67,0,67.36-.63v-11l-67.36.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M368.53,491.31l-68,.63V480.29l68-.63Zm-67.67,0,67.36-.63v-11l-67.36.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M310.3,491.31h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M310.3,491.31h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M319.75,491.31h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M319.75,491.31h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M329.5,491.31h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M329.5,491.31h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M339.26,491h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M339.26,491h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M349,491h0l-.31-10.7h0L349,491h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M349,491h0l-.31-10.7h0L349,491Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M358.78,491h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M358.78,491h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M490,508.3h-3.78l-2.2-1.57-95.69.94-1.57,1.57h-4.72a7.76,7.76,0,0,1-6.3-5.67h0V479.66h0s1.57-4.72,6.3-5.35h.31v3.46l37.14-.31V474h0s8.81,0,10.39,7.87V490h12v-7.87A10.68,10.68,0,0,1,452.26,474h.31v3.46l37.14-.31V474h0a8.43,8.43,0,0,1,2.83.63,7.84,7.84,0,0,1,4.09,4.72h0l.31,23.92a7.86,7.86,0,0,1-1.26,2.52c-1.89,1.57-3.46,2.52-5.67,2.52m-113.94-4.72a7.5,7.5,0,0,0,6,5.35h4.72l1.57-1.57,96-.94,2.2,1.57h3.78a5.55,5.55,0,0,0,4.72-2.52,7.86,7.86,0,0,0,1.26-2.52L496,479a6,6,0,0,0-3.78-4.41c-1.26-.31-2.2-.63-2.52-.63v3.15l-37.46.31V474a10.27,10.27,0,0,0-9.76,7.87V490H429.91v-8.5c-1.57-6.92-8.81-7.55-10.07-7.55v3.46l-37.46.31v-3.46c-4.09.63-5.67,4.72-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M490,508.3h-3.78l-2.2-1.57-95.69.94-1.57,1.57h-4.72a7.76,7.76,0,0,1-6.3-5.67h0V479.66h0s1.57-4.72,6.3-5.35h.31v3.46l37.14-.31V474h0s8.81,0,10.39,7.87V490h12v-7.87A10.68,10.68,0,0,1,452.26,474h.31v3.46l37.14-.31V474h0a8.43,8.43,0,0,1,2.83.63,7.84,7.84,0,0,1,4.09,4.72h0l.31,23.92a7.86,7.86,0,0,1-1.26,2.52C493.81,507.36,492.23,508.3,490,508.3Zm-113.94-4.72a7.5,7.5,0,0,0,6,5.35h4.72l1.57-1.57,96-.94,2.2,1.57h3.78a5.55,5.55,0,0,0,4.72-2.52,7.86,7.86,0,0,0,1.26-2.52L496,479a6,6,0,0,0-3.78-4.41c-1.26-.31-2.2-.63-2.52-.63v3.15l-37.46.31V474a10.27,10.27,0,0,0-9.76,7.87V490H429.91v-8.5c-1.57-6.92-8.81-7.55-10.07-7.55v3.46l-37.46.31v-3.46c-4.09.63-5.67,4.72-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M614.36,507.36h-3.78l-2.2-1.57-95.06.63L511.75,508H507a7.76,7.76,0,0,1-6.3-5.67h0V478.4h0s1.57-4.72,6.3-5.35h.31v3.46l36.83-.31v-3.46h0s8.81,0,10.39,7.87v8.18H566.2v-7.87a10.68,10.68,0,0,1,10.39-8.18h.31v3.46l36.83-.31v-3.15h0a8.43,8.43,0,0,1,2.83.63,7.84,7.84,0,0,1,4.09,4.72h0L621,502a7.86,7.86,0,0,1-1.26,2.52,7,7,0,0,1-5.35,2.83M501,502.64a7.5,7.5,0,0,0,6,5.35h4.41l1.57-1.57,95.37-.94,2.2,1.57h3.78a6,6,0,0,0,4.72-2.52,7.86,7.86,0,0,0,1.26-2.52L620,478.09c-.94-2.83-2.52-4.09-3.78-4.41a14.16,14.16,0,0,0-2.52-.63v3.15l-37.46.31v-3.46a10.27,10.27,0,0,0-9.76,7.87v8.18H554.24v-8.5c-1.57-6.92-8.81-7.55-10.07-7.55v3.46l-37.46.31v-3.46c-4.09.63-5.67,4.72-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M614.36,507.36h-3.78l-2.2-1.57-95.06.63L511.75,508H507a7.76,7.76,0,0,1-6.3-5.67h0V478.4h0s1.57-4.72,6.3-5.35h.31v3.46l36.83-.31v-3.46h0s8.81,0,10.39,7.87v8.18H566.2v-7.87a10.68,10.68,0,0,1,10.39-8.18h.31v3.46l36.83-.31v-3.15h0a8.43,8.43,0,0,1,2.83.63,7.84,7.84,0,0,1,4.09,4.72h0L621,502a7.86,7.86,0,0,1-1.26,2.52A7,7,0,0,1,614.36,507.36ZM501,502.64a7.5,7.5,0,0,0,6,5.35h4.41l1.57-1.57,95.37-.94,2.2,1.57h3.78a6,6,0,0,0,4.72-2.52,7.86,7.86,0,0,0,1.26-2.52L620,478.09c-.94-2.83-2.52-4.09-3.78-4.41a14.16,14.16,0,0,0-2.52-.63v3.15l-37.46.31v-3.46a10.27,10.27,0,0,0-9.76,7.87v8.18H554.24v-8.5c-1.57-6.92-8.81-7.55-10.07-7.55v3.46l-37.46.31v-3.46c-4.09.63-5.67,4.72-6,5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M417.95,490.68l-39,.31V479.35l39-.31Zm-39,0,38.4-.31v-11l-38.4.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M417.95,490.68l-39,.31V479.35l39-.31Zm-39,0,38.4-.31v-11l-38.4.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M388.68,491h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M388.68,491h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M453.2,490.36h0L452.89,479h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M453.2,490.36h0L452.89,479h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M463,490.36h0L462.64,479h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M463,490.36h0L462.64,479h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M472.4,490.36h0L472.09,479h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M472.4,490.36h0L472.09,479h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M482.16,490.36h0L481.84,479h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M482.16,490.36h0L481.84,479h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M398.12,491h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M398.12,491h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M408.19,491h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M408.19,491h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M493.81,490l-49.1.31V478.72l49.1-.31Zm-48.79.31,48.47-.31V479l-48.47.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M493.81,490l-49.1.31V478.72l49.1-.31Zm-48.79.31,48.47-.31V479l-48.47.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M493.49,505.79l-114.57.94V495.08l114.57-.94Zm-114.26.63,113.94-.94v-11l-113.94.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M493.49,505.79l-114.57.94V495.08l114.57-.94Zm-114.26.63,113.94-.94v-11l-113.94.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M385.22,506.42h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M385.22,506.42h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M395,506.1h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M395,506.1h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M404.73,506.1h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M404.73,506.1h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M414.49,506.1h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M414.49,506.1h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M424.24,506.1c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M424.24,506.1c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M433.69,505.79h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M433.69,505.79h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M443.76,505.79h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M443.76,505.79h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M453.2,505.79h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M453.2,505.79h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M463,505.79h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M463,505.79h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M473,505.47h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M473,505.47h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M482.47,505.47h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M482.47,505.47h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M420.78,548.28h-.31v-4.09L383,544.5v3.78h-.31s-5.35-.63-6.3-5.35L375.77,519h0a7.29,7.29,0,0,1,6.3-5.67h4.41l1.89,1.57,96-.94,1.57-1.57h4.72a7.94,7.94,0,0,1,2.83,1.26,4.75,4.75,0,0,1,2.83,4.41l.31,23.92s0,5.35-6.61,5.67h-.31v-3.78l-37.14.31v3.46h0s-8.81.31-10.07-7.55v-8.5h-12l-.31,8.5c.31.31-1.26,8.18-9.44,8.18m-.31-.31h0a9.24,9.24,0,0,0,9.44-7.87l.31-8.81H442.5v8.81c1.26,6.92,8.5,7.24,9.76,7.24v-3.46l37.46-.31v3.78C496,547,496,542,496,542l-.31-23.92c0-4.09-5-5.35-5.35-5.35h-4.72L484,514.28l-96.32.94-1.89-1.57h-4.41a6.68,6.68,0,0,0-6,5.35l.63,23.92c.94,4.09,5,4.72,6,5v-3.78l37.77-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M420.78,548.28h-.31v-4.09L383,544.5v3.78h-.31s-5.35-.63-6.3-5.35L375.77,519h0a7.29,7.29,0,0,1,6.3-5.67h4.41l1.89,1.57,96-.94,1.57-1.57h4.72a7.94,7.94,0,0,1,2.83,1.26,4.75,4.75,0,0,1,2.83,4.41l.31,23.92s0,5.35-6.61,5.67h-.31v-3.78l-37.14.31v3.46h0s-8.81.31-10.07-7.55v-8.5h-12l-.31,8.5C430.54,540.41,429,548.28,420.78,548.28Zm-.31-.31h0a9.24,9.24,0,0,0,9.44-7.87l.31-8.81H442.5v8.81c1.26,6.92,8.5,7.24,9.76,7.24v-3.46l37.46-.31v3.78C496,547,496,542,496,542l-.31-23.92c0-4.09-5-5.35-5.35-5.35h-4.72L484,514.28l-96.32.94-1.89-1.57h-4.41a6.68,6.68,0,0,0-6,5.35l.63,23.92c.94,4.09,5,4.72,6,5v-3.78l37.77-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M545.43,547.33h-.31v-4.09l-36.83.31v3.78H508s-5.35-.63-6.3-5.35L501,518.06h0a7.29,7.29,0,0,1,6.3-5.67h4.41l1.89,1.57,95.06-.63,1.57-1.57H615a7.94,7.94,0,0,1,2.83,1.26,4.75,4.75,0,0,1,2.83,4.41l.31,23.92s0,5.35-6.61,5.67H614v-3.78l-36.51.31V547h0s-8.81.31-10.07-7.55V531H555.81l-.31,8.5c-.63,0-2.2,7.55-10.07,7.87m-.31-.31c7.87,0,9.44-7.55,9.44-7.87l.31-8.81h12.28v8.81c1.26,6.92,8.5,7.24,9.76,7.24v-3.46l37.14-.31v3.78c6-.31,6-5.35,6-5.35l-.31-23.92c0-4.09-5-5.35-5.35-5.35H610l-1.57,1.57-95.37.94-1.89-1.57h-4.41a6.68,6.68,0,0,0-6,5.35l.63,23.92c.94,4.09,5,4.72,6,5v-3.78l37.46-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M545.43,547.33h-.31v-4.09l-36.83.31v3.78H508s-5.35-.63-6.3-5.35L501,518.06h0a7.29,7.29,0,0,1,6.3-5.67h4.41l1.89,1.57,95.06-.63,1.57-1.57H615a7.94,7.94,0,0,1,2.83,1.26,4.75,4.75,0,0,1,2.83,4.41l.31,23.92s0,5.35-6.61,5.67H614v-3.78l-36.51.31V547h0s-8.81.31-10.07-7.55V531H555.81l-.31,8.5C554.87,539.46,553.29,547,545.43,547.33Zm-.31-.31c7.87,0,9.44-7.55,9.44-7.87l.31-8.81h12.28v8.81c1.26,6.92,8.5,7.24,9.76,7.24v-3.46l37.14-.31v3.78c6-.31,6-5.35,6-5.35l-.31-23.92c0-4.09-5-5.35-5.35-5.35H610l-1.57,1.57-95.37.94-1.89-1.57h-4.41a6.68,6.68,0,0,0-6,5.35l.63,23.92c.94,4.09,5,4.72,6,5v-3.78l37.46-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M385.22,499.81h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M385.22,499.81h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M385.22,503.27h0c0-.31.31-.31,0,0h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M385.22,503.27h0c0-.31.31-.31,0,0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M382.07,506.73h0l-.31-3.46h0l.31,3.46c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M382.07,506.73h0l-.31-3.46h0l.31,3.46C382.38,506.42,382.38,506.73,382.07,506.73Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M494.12,526.56l-114.89.94V515.86l114.89-.94Zm-114.89.63,114.26-.94v-11l-114.26.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M494.12,526.56l-114.89.94V515.86l114.89-.94Zm-114.89.63,114.26-.94v-11l-114.26.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M385.84,522.47h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M385.84,522.47h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M382.38,527.5h0l-.31-5.35h0l.31,5.35h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M382.38,527.5h0l-.31-5.35h0l.31,5.35Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M385.84,527.5h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M385.84,527.5h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M395.29,527.5h0L395,516.17h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M395.29,527.5h0L395,516.17h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M405,527.5h0l-.31-11.33h0L405,527.5h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M405,527.5h0l-.31-11.33h0L405,527.5Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M414.8,527.19h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M414.8,527.19h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M424.24,527.19h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M424.24,527.19h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M434,527.19h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M434,527.19h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M443.76,527.19h0l-.31-11.33h0l.31,11.33c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M443.76,527.19h0l-.31-11.33h0l.31,11.33C444.07,526.87,444.07,527.19,443.76,527.19Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M453.52,526.87h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M453.52,526.87h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M463.27,526.87h0L463,515.54h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M463.27,526.87h0L463,515.54h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M472.72,526.87h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M472.72,526.87h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M482.47,526.87h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M482.47,526.87h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M428,542.61l-48.79.31V531.28L428,531Zm-48.47.31,48.16-.31v-11l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M428,542.61l-48.79.31V531.28L428,531Zm-48.47.31,48.16-.31v-11l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M389,542.61h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M389,542.61h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M398.75,542.61h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M398.75,542.61h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M408.51,542.61h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M408.51,542.61h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M418.26,542.61h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M418.26,542.61h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M493.81,542l-49.1.31V531l49.1-.31ZM445,542l48.47-.31V531l-48.47.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M493.81,542l-49.1.31V531l49.1-.31ZM445,542l48.47-.31V531l-48.47.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M453.52,542.3h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M453.52,542.3h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M463.27,542.3h0L463,531.6h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M463.27,542.3h0L463,531.6h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M473,542h0l-.31-10.7h0L473,542h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M473,542h0l-.31-10.7h0L473,542Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M482.79,542h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M482.79,542h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M552.35,489.73l-48.79.31V478.4l48.79-.31Zm-48.79,0,48.16-.31V478.09l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M552.35,489.73l-48.79.31V478.4l48.79-.31Zm-48.79,0,48.16-.31V478.09l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M514.58,490h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M514.58,490h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M514.58,505.47h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M514.58,505.47h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M524.34,505.47h0L524,494.14h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M524.34,505.47h0L524,494.14h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M534.09,505.47h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M534.09,505.47h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M543.54,505.16h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M543.54,505.16h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M553.29,505.16h0L553,493.83h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M553.29,505.16h0L553,493.83h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M563.05,505.16h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M563.05,505.16h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M573.12,505.16c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M573.12,505.16c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M582.57,504.84h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M582.57,504.84h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M592.32,504.84h0L592,493.51h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M592.32,504.84h0L592,493.51h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M601.77,504.84h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M601.77,504.84h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M611.52,504.84h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M611.52,504.84h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M579.1,489.42h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M579.1,489.42h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M588.86,489.42h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M588.86,489.42h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M598.3,489.42h0L598,478.09h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M598.3,489.42h0L598,478.09h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M608.06,489.1h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M608.06,489.1h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M524.34,490h0L524,478.72h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M524.34,490h0L524,478.72h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M533.78,489.73h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M533.78,489.73h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M543.22,489.73h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M543.22,489.73h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M617.82,488.79l-49.1.31V477.77h49.1ZM569,489.1l48.47-.31v-10.7L569,478.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M617.82,488.79l-49.1.31V477.77h49.1ZM569,489.1l48.47-.31v-10.7L569,478.4Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M617.82,504.53l-114.26.94V493.83l114.26-.94Zm-113.94.63,113.63-.94v-11l-113.63.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M617.82,504.53l-114.26.94V493.83l114.26-.94Zm-113.94.63,113.63-.94v-11l-113.63.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M617.82,498.86h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M617.82,498.86h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M614.67,498.86h0l-.31-5.67h0l.31,5.67h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M614.67,498.86h0l-.31-5.67h0l.31,5.67Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M618.13,525.62l-114.26.94V514.91L618.13,514Zm-114.26.63,113.63-.94v-11l-113.63.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M618.13,525.62l-114.26.94V514.91L618.13,514Zm-114.26.63,113.63-.94v-11l-113.63.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M514.89,526.25h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M514.89,526.25h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M524.65,526.25h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M524.65,526.25h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M534.41,525.93h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M534.41,525.93h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M543.54,525.93h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M543.54,525.93h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M553.29,525.93h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M553.29,525.93h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M563.37,525.93c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M563.37,525.93c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M573.12,525.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M573.12,525.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M582.57,525.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M582.57,525.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M592.32,525.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M592.32,525.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M602.08,525.62h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M602.08,525.62h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M611.84,525.3h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M611.84,525.3h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M617.82,518.69h0c0-.31.31-.31,0,0h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M617.82,518.69h0c0-.31.31-.31,0,0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M617.82,521.84h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M617.82,521.84h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M614.67,525.62h0l-.31-3.78h0l.31,3.78h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M614.67,525.62h0l-.31-3.78h0l.31,3.78Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M553,542l-48.79.31V530.65l48.79-.31Zm-48.47,0,48.16-.31v-11l-48.16.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M553,542l-48.79.31V530.65l48.79-.31Zm-48.47,0,48.16-.31v-11l-48.16.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M514.26,542h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M514.26,542h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M524,542h0l-.31-10.7h0L524,542h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M524,542h0l-.31-10.7h0L524,542Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M533.46,542h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M533.46,542h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M542.91,541.67h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M542.91,541.67h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M618.13,541l-48.79.31v-11l48.79-.31Zm-48.47.31,48.16-.63V530.34l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M618.13,541l-48.79.31v-11l48.79-.31Zm-48.47.31,48.16-.63V530.34l-48.16.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M579.42,541.35h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M579.42,541.35h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M589.18,541.35h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M589.18,541.35h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M598.93,541.35h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M598.93,541.35h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M608.38,541.35h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M608.38,541.35h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M731.45,506.42h-4.72l-1.89-1.57-87.82.63L635.45,507h-4.72a7.23,7.23,0,0,1-6-5.35h0l-.31-24.24h0a8.45,8.45,0,0,1,7.87-5.35h0v3.46l31.79-.31v-3.46h0a11,11,0,0,1,10.07,7.87h0v8.18h11.65v-7.24a12.29,12.29,0,0,1,8.18-8.81h.31v3.46l32.1-.31v-3.46h.31s15.11.31,17,14.48v17.63h-9.44c-.31.63-1.57,2.83-2.83,2.83M727,506.1h4.41c.94.31,2.52-1.89,2.83-2.52h9.13V486.27c-1.89-12.9-15.11-14.16-16.68-14.16v3.46l-32.73.31v-3.46a11.31,11.31,0,0,0-7.55,8.5v7.55H674.16V480a10.5,10.5,0,0,0-9.76-7.55v3.46L632,476.2v-3.46c-5.35,0-7.24,4.72-7.55,5l.31,24.24a7,7,0,0,0,5.67,5h4.72l1.57-1.57,88.13-.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M731.45,506.42h-4.72l-1.89-1.57-87.82.63L635.45,507h-4.72a7.23,7.23,0,0,1-6-5.35h0l-.31-24.24h0a8.45,8.45,0,0,1,7.87-5.35h0v3.46l31.79-.31v-3.46h0a11,11,0,0,1,10.07,7.87h0v8.18h11.65v-7.24a12.29,12.29,0,0,1,8.18-8.81h.31v3.46l32.1-.31v-3.46h.31s15.11.31,17,14.48v17.63h-9.44C734,504.21,732.7,506.42,731.45,506.42ZM727,506.1h4.41c.94.31,2.52-1.89,2.83-2.52h9.13V486.27c-1.89-12.9-15.11-14.16-16.68-14.16v3.46l-32.73.31v-3.46a11.31,11.31,0,0,0-7.55,8.5v7.55H674.16V480a10.5,10.5,0,0,0-9.76-7.55v3.46L632,476.2v-3.46c-5.35,0-7.24,4.72-7.55,5l.31,24.24a7,7,0,0,0,5.67,5h4.72l1.57-1.57,88.13-.63Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M671.33,488.79l-43.75.31V477.46l43.75-.31Zm-43.44,0,43.12-.31v-11l-43.12.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M671.33,488.79l-43.75.31V477.46l43.75-.31Zm-43.44,0,43.12-.31v-11l-43.12.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M629.15,489.1h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M629.15,489.1h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M638.91,489.1c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M638.91,489.1c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M648.35,488.79h0L648,477.46h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M648.35,488.79h0L648,477.46h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M658.42,488.79h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M658.42,488.79h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M667.86,488.79h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M667.86,488.79h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M738.06,488.16l-48.79.31V477.14l43.44-.31,5.35,4.72Zm-48.47,0,48.16-.31v-6l-5.35-4.41-43.12.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M738.06,488.16l-48.79.31V477.14l43.44-.31,5.35,4.72Zm-48.47,0,48.16-.31v-6l-5.35-4.41-43.12.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M699,488.47h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M699,488.47h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M709.1,488.47h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M709.1,488.47h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M718.54,488.16h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M718.54,488.16h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M728.3,488.16h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M728.3,488.16h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M738.37,503.58l-110.79.94V492.88l110.79-.94Zm-110.48.63,110.16-.94v-11l-110.16.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M738.37,503.58l-110.79.94V492.88l110.79-.94Zm-110.48.63,110.16-.94v-11l-110.16.94Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M634.19,504.53h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M634.19,504.53h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M643.94,504.53h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M643.94,504.53h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M653.39,504.21h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M653.39,504.21h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M663.14,504.21h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M663.14,504.21h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M673.22,504.21h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M673.22,504.21h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M682.66,504.21h0l-.31-11.33h0l.31,11.33c.31-.31.31,0,0,0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M682.66,504.21h0l-.31-11.33h0l.31,11.33C683,503.9,683,504.21,682.66,504.21Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M692.73,503.9h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M692.73,503.9h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M702.17,503.9h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M702.17,503.9h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M711.93,503.9h0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M711.93,503.9h0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M721.69,503.9c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M721.69,503.9c-.31,0-.31-.31,0,0l-.31-11.33h0l.31,11.33Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M634.19,497.29h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M634.19,497.29h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M634.19,500.75h0c0-.31.31-.31,0,0h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M634.19,500.75h0c0-.31.31-.31,0,0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M630.72,504.53h0l-.31-3.78h0l.31,3.78h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M630.72,504.53h0l-.31-3.78h0l.31,3.78Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M667.55,546.07h-.63V542.3l-34.31.31v3.46h0s-6.3.31-6.92-5.35l-.63-23.92h0s1.26-5,5.67-5.67h5l1.57,1.57,88.13-.63,1.26-1.57h4.72s3.15,1.26,3.15,2.52h9.13a29.88,29.88,0,0,1-3.78,14.48c-3.46,6.92-11.65,15.42-28.64,17.63h-7.24v-3.46h-8.18v3.46h0s-3.78.63-6.3-1.26a5.85,5.85,0,0,1-2.2-4.09V529.39h-12v10.07c-.31,2.83-2.2,6.61-7.87,6.61m-.63,0c7.24,0,7.87-5.35,7.87-5.67V530h12.59v10.7a5.84,5.84,0,0,0,2.2,3.78c2.2,1.57,5.35,1.26,6,1.26V542.3h8.81v3.46h6.92c17-2.2,24.87-10.7,28.64-17.63A35.5,35.5,0,0,0,743.72,514h-9.13c0-1.26-2.83-2.2-3.15-2.52H727L725.78,513l-88.45.63-1.57-1.57h-5c-4.09.63-5.35,5-5.35,5.35l.63,23.92a5.15,5.15,0,0,0,3.46,4.41,6.43,6.43,0,0,0,2.83.63v-3.46l34.94-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M667.55,546.07h-.63V542.3l-34.31.31v3.46h0s-6.3.31-6.92-5.35l-.63-23.92h0s1.26-5,5.67-5.67h5l1.57,1.57,88.13-.63,1.26-1.57h4.72s3.15,1.26,3.15,2.52h9.13a29.88,29.88,0,0,1-3.78,14.48c-3.46,6.92-11.65,15.42-28.64,17.63h-7.24v-3.46h-8.18v3.46h0s-3.78.63-6.3-1.26a5.85,5.85,0,0,1-2.2-4.09V529.39h-12v10.07C675.1,542.3,673.22,546.07,667.55,546.07Zm-.63,0c7.24,0,7.87-5.35,7.87-5.67V530h12.59v10.7a5.84,5.84,0,0,0,2.2,3.78c2.2,1.57,5.35,1.26,6,1.26V542.3h8.81v3.46h6.92c17-2.2,24.87-10.7,28.64-17.63A35.5,35.5,0,0,0,743.72,514h-9.13c0-1.26-2.83-2.2-3.15-2.52H727L725.78,513l-88.45.63-1.57-1.57h-5c-4.09.63-5.35,5-5.35,5.35l.63,23.92a5.15,5.15,0,0,0,3.46,4.41,6.43,6.43,0,0,0,2.83.63v-3.46l34.94-.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M734.59,524.36l-107,.94V513.65l107-.94Zm-106.7.63L734.28,524V513L627.89,514Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M734.59,524.36l-107,.94V513.65l107-.94Zm-106.7.63L734.28,524V513L627.89,514Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M634.5,525.3h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M634.5,525.3h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M643.94,525h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M643.94,525h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M653.7,525h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M653.7,525h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M663.46,525h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M663.46,525h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M673.22,525h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M673.22,525h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M683,524.67h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M683,524.67h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M692.42,524.67h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M692.42,524.67h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M702.17,524.67h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M702.17,524.67h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M712.25,524.67c-.31,0-.31-.31,0,0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M712.25,524.67c-.31,0-.31-.31,0,0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M721.69,524.36h0l-.31-11h0l.31,11h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M721.69,524.36h0l-.31-11h0l.31,11Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M634.5,519.95h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M634.5,519.95h0Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M631,525.3h0l-.31-5.35h0l.31,5.35h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M631,525.3h0l-.31-5.35h0l.31,5.35Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M669.75,540.72l-41.23.31V530l41.23-.31Zm-40.92,0,40.6-.31V530l-40.6.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M669.75,540.72l-41.23.31V530l41.23-.31Zm-40.92,0,40.6-.31V530l-40.6.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M640.48,541h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M640.48,541h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M650.55,541c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M650.55,541c-.31,0-.31-.31,0,0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M660,540.72h0l-.31-10.7h0l.31,10.7h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M660,540.72h0l-.31-10.7h0l.31,10.7Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M704.69,540.41H690.53v-11l41.86-.31-.31.31s-5,6.3-11,8.18H704.69Zm-14.16,0h13.53v-2.52h16.68c5-1.57,9.44-6.61,10.39-7.87l-40.92.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M704.69,540.41H690.53v-11l41.86-.31-.31.31s-5,6.3-11,8.18H704.69Zm-14.16,0h13.53v-2.52h16.68c5-1.57,9.44-6.61,10.39-7.87l-40.92.31Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M704.38,537.89h0l-.31-8.18h0l.31,8.18h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M704.38,537.89h0l-.31-8.18h0l.31,8.18Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M712.87,537.89h0l-.31-8.18h0l.31,8.18h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M712.87,537.89h0l-.31-8.18h0l.31,8.18Z" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-22" d="M721.37,537.58h0l-.31-8.18h0l.31,8.18h0" transform="translate(5.92 -69)"></path>
                        <path className="plot_block cls-23" d="M721.37,537.58h0l-.31-8.18h0l.31,8.18Z" transform="translate(5.92 -69)"></path>
                    </g>
                </svg>
            </div>
            <div className="svg_container">
                <div className="filters">
                    <form onSubmit={handleSubmit}>
                        <h3 className="featureTitle">Types</h3>
                        <label className="featureValue">
                            <input
                                className="svgCheckBox"
                                type="checkbox"
                                name="types"
                                value="Land"
                                onChange={handleFilterChange}
                            />{" "}
                            Land
                        </label>
                        <label className="featureValue">
                            <input
                                className="svgCheckBox"
                                type="checkbox"
                                name="types"
                                value="Warehouse"
                                onChange={handleFilterChange}
                            />{" "}
                            Warehouse
                        </label>
                        <h3 className="featureTitle">Ownership</h3>
                        <label className="featureValue">
                            <input
                                className="svgCheckBox"
                                type="checkbox"
                                name="ownership"
                                value="Lease"
                                onChange={handleFilterChange}
                            />{" "}
                            Lease
                        </label>
                        <label className="featureValue">
                            <input
                                className="svgCheckBox"
                                type="checkbox"
                                name="ownership"
                                value="Sale"
                                onChange={handleFilterChange}
                            />{" "}
                            Sale
                        </label>
                        <br></br>
                        <button type="submit" className="planSubmit" disabled={isSubmitDisabled}>Submit</button>
                    </form>
                </div>
                <div className="fetch_data">
                    {clickedPlot && data && data.types ? (
                        <div className="data_display">
                            {/* Display plot details when data is available */}
                            <div className="text_data">
                                <ul className="text_details">
                                    <li>
                                        <span>Types </span>
                                        {data.types}
                                    </li>
                                    <li>
                                        <span>Ownership </span>
                                        {data.ownership}
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <div className="fetch_img">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets-2/svg_imgs/${data.imgPath}`}
                                    alt={data.types}
                                    className="plot_img"
                                />
                            </div>
                        </div>
                    ) : (
                        // Placeholder message when no data is available
                        <div className="placeholder">
                            <h3>Please choose a plot</h3>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default SitePlan;


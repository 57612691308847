// import logo from './logo.svg';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import SitePlan from './components/sitePlan';
import NewHome from './components/Home';
import Menu from './components/menuBar';

function App() {
  return (
    // <SitePlan></SitePlan>
    // <MyComponent></MyComponent>
    <React.StrictMode>
    <BrowserRouter>
        <Routes>
        <Route path='/' element={<Menu/>}>
        <Route path="/" element={<NewHome />}/>
          {/* <Route index element={<HomePage />} />  */} 
          <Route path="/sitePlan" element={<SitePlan />} />
          </Route>
        </Routes>
      {/* <Footer></Footer> */}
    {/* </Router> */}
    </BrowserRouter>
    </React.StrictMode>
    // <SitePlan></SitePlan>
  );
}

export default App;

// src/components/Slider.js
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';  // Import Autoplay module
import './css/swiper-bundle.min.css';

const Slider = () => {
    const slides = [
        {
            image: "https://www.asio.ae/public/uploads/15807967721554906022Warehouses.jpg",
            location: "Sharjah, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807965771554905868Modern_Infrastructure.jpg",
            location: "Sharjah, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807968561555247316inside_warehouss.jpg",
            location: "Sharjah, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807968981555249431Quality_finishing.jpg",
            location: "Sharjah, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807969361567493059SAJAA_7.jpg",
            location: "Dubai, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807969741567493320SAJAA_20.jpg",
            location: "Dubai, UAE",
            listings: "25 Listings",
        }, 
        {
            image: "https://www.asio.ae/public/uploads/15807968981555249431Quality_finishing.jpg",
            location: "Sharjah, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807969361567493059SAJAA_7.jpg",
            location: "Dubai, UAE",
            listings: "25 Listings",
        },
        {
            image: "https://www.asio.ae/public/uploads/15807969741567493320SAJAA_20.jpg",
            location: "Dubai, UAE",
            listings: "25 Listings",
        },
    ];

    return (
        <section className="position-relative overflow-hidden space" id="destination-sec">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-6">
                        <div className="title-area">
                            <span className="sub-title">Take A Look</span>
                            <h2 className="sec-title">ASIO</h2>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <h2 className="destination-title" style={{ display: 'none' }}>
                            <span className="counter-number">850</span>+ Destinations
                        </h2>
                        <p className="sec-text mb-30">
                            ASIO offers world-class standard infrastructure including unique firefighting and storm water networks, paved footpaths, and visitor car parking.
                        </p>
                    </div>
                    <br />
                </div>
                <div className="slider-area">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={24}
                        loop={true}
                        speed={1000}
                        watchSlidesProgress={true}
                        autoplay={{
                            delay: 3000,   // Set autoplay delay (in ms)
                            disableOnInteraction: false,  // Allow user interaction without stopping autoplay
                        }}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            576: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            992: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1300: { slidesPerView: 4 },
                        }}
                        modules={[Autoplay]}  // Add Autoplay module
                    >
                        <div className="swiper-wrapper">
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div className="destination-item th-ani">
                                        <div className="destination-item_img global-img">
                                            <img src={slide.image} style={{ height: '179px' }} alt="Gallery" />
                                        </div>
                                        <div className="destination-content">
                                            <h3 className="box-title"><a href="/">{slide.location}</a></h3>
                                            <p className="destination-text">{slide.listings}</p>
                                            <a href="/" className="th-btn style4 th-icon">Book Now</a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                </div>
                <div className="destination-btn text-center mt-60">
                    <a href="/" className="th-btn style3 th-icon">View Full Gallery</a>
                </div>
            </div>
        </section>
    );
};

export default Slider;
